import { AccessDeniedReason, EntitlementsFallback } from '../models';

export function mapGetEntitlementsFallback(entitlementsFallback?: EntitlementsFallback) {
  if (!entitlementsFallback) {
    return [];
  }

  const featureIds = Object.keys(entitlementsFallback);

  return featureIds.map((featureId) => ({
    isFallback: true,
    accessDeniedReason: AccessDeniedReason.Unknown,
    feature: {
      id: featureId,
    },
    ...entitlementsFallback[featureId],
  }));
}
