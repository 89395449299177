import React, { FC, forwardRef } from "react";
import styled from "styled-components";

import { toRem } from "@/utils/styled-components";

import { BaseButton, BaseButtonProps } from "./base-button";
import {
  getVariantCss,
  ColorPaletteType,
  VariantsType,
  StyledBaseButtonProps,
} from "./get-variant-css";

export type PrimaryButtonProps = BaseButtonProps & {
  variant?: VariantsType;
};

const colorPalette: ColorPaletteType = (props) => ({
  default: props.theme.palette.primary.main,
  hover: props.theme.palette.primary.dark,
  active: props.theme.palette.primary.dark,
  disabled: props.theme.palette.primary.light,
  textColor: props.theme.palette.primary.main,
  disabledTextColor: props.theme.palette.primary.light,
  hoverTextColor: props.theme.palette.primary.dark,
  hoverTextBackgroundColor: props.theme.palette.primary.light,
  activeTextBackgroundColor: props.theme.palette.primary.light,
});

const [getEnabledButtonCss, getDisabledButtonCss] = getVariantCss(colorPalette);

const StyledBaseButton = styled(BaseButton)<StyledBaseButtonProps>`
  border: ${toRem(1)} solid transparent !important;
  ${getEnabledButtonCss}
  ${getDisabledButtonCss}
`;

/**
 *
 * @param {Object} block Set to make button block and full width
 * @param {Object} size small | medium | large
 * @param {Object} variant contained | outlined | text
 *
 */

const PrimaryButton: FC<PrimaryButtonProps> = forwardRef(
  (props, ref): JSX.Element => (
    <StyledBaseButton
      $variant={props.variant}
      ariaLabel={props.ariaLabel}
      badgeConfig={props.badgeConfig}
      block={props.block}
      className={props.className}
      data-testid={props["data-testid"]}
      disabled={props.disabled}
      endComponent={props.endComponent}
      loading={props.loading}
      onClick={props.onClick}
      onSubmit={props.onSubmit}
      ref={ref}
      showBadge={props.showBadge}
      size={props.size}
      startIcon={props.startIcon}
      type={props.type}
    >
      {props.children}
    </StyledBaseButton>
  ),
);

export { PrimaryButton };
