import {
  addMinutes,
  format,
  formatISOWithOptions,
  startOfMonth,
} from "date-fns/fp";

import { zonedTimeToUtc } from "date-fns-tz";

export const dateForAsDateString = formatISOWithOptions({
  representation: "date",
});

export const stringDateToGqlFormat = (stringDate: string): string =>
  zonedTimeToUtc(
    startOfMonth(ignoreTZOffset(new Date(stringDate))),
    "Etc/UTC",
  ).toISOString();

export const dateForDisplay = format("MMM yyyy");

export const ignoreTZOffset = (date: Date): Date =>
  addMinutes(date.getTimezoneOffset(), date);
