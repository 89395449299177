import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client/core';
import fragments from '@stigg/api-client-js/src/generated/fragments.json';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { RetryLink } from '@apollo/client/link/retry';
import { SDK_NAME, SDK_SOURCE_TYPE } from '../configuration';
import { plainFetch } from '../utils/fetch';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageJson: { version: string } = require('../../package.json');

type ApolloClientConfiguration = {
  apiKey: string;
  baseUri: string;
  customerId?: string | null;
  customerToken?: string | null;
  clientName?: string;
  clientVersion?: string;
};

function getAuthLink(apiKey: string | undefined, dynamicData: { customerToken?: string | null }) {
  return new ApolloLink((operation, forward) => {
    const headers: Record<string, string | undefined> = {
      'X-API-KEY': apiKey,
      'x-graphql-operation-name': operation.operationName,
      source: SDK_SOURCE_TYPE,
    };

    // customer may be changed overtime, so fetching it before every query
    const { customerToken } = dynamicData;
    if (customerToken) {
      headers['X-CUSTOMER-KEY'] = customerToken;
    }

    operation.setContext({ headers });
    return forward(operation);
  });
}

function getApolloLink(baseUri: string | undefined) {
  return new HttpLink({
    uri: `${baseUri}/graphql`,
    fetch: plainFetch,
  });
}

const retryIf = (error: any) => {
  const doNotRetryCodes = [500, 400];
  return !!error && !doNotRetryCodes.includes(error.statusCode);
};

const initApolloClient = (config: Partial<ApolloClientConfiguration>) => {
  const { apiKey, baseUri, clientName = SDK_NAME, clientVersion = packageJson.version } = config;
  const authLink = getAuthLink(apiKey, config);

  const httpLink = getApolloLink(baseUri);

  const retryLink = new RetryLink({
    attempts: { max: 5, retryIf },
    delay: { initial: 500, max: 2000, jitter: true },
  });

  return new ApolloClient({
    link: authLink.concat(retryLink.concat(httpLink)),
    cache: new InMemoryCache({ possibleTypes: fragments.possibleTypes }),
    name: clientName,
    version: clientVersion,
  });
};

function getBatchedLink(baseUri: string) {
  return new BatchHttpLink({ uri: `${baseUri}/graphql`, batchMax: 25, batchInterval: 500, fetch: plainFetch });
}

export const initBatchedApolloClient = (config: ApolloClientConfiguration) => {
  const { apiKey, baseUri, clientName = SDK_NAME, clientVersion = packageJson.version } = config;
  const authLink = getAuthLink(apiKey, config);

  const batchLink = getBatchedLink(baseUri);

  return new ApolloClient({
    link: authLink.concat(batchLink),
    cache: new InMemoryCache({ possibleTypes: fragments.possibleTypes }),
    name: clientName,
    version: clientVersion,
  });
};

export default initApolloClient;
