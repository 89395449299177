import { Sentry } from '../services/sentryService';
import { ClientConfiguration } from '../configuration';
import { LoggerService } from '../services/loggerService';

type WithErrorHandlingProps = {
  loggerService: LoggerService;
  sdkConfiguration?: Required<ClientConfiguration>;
  errorMessage?: (err: any) => string;
  errorReportMetadata?: object;
  rethrowError: boolean;
};

function logToSentry(
  error: any,
  {
    sdkConfiguration,
    errorMessage,
    errorReportMetadata,
  }: Pick<WithErrorHandlingProps, 'sdkConfiguration' | 'errorReportMetadata'> & { errorMessage: string },
) {
  Sentry.withScope((scope: any) => {
    let extras: any = { errorMessage };

    if (sdkConfiguration) {
      const { customerId } = sdkConfiguration;
      const resolvedSdkConfiguration = { ...sdkConfiguration, apiKey: undefined };

      if (customerId) {
        scope.setTags({ customerId });
      }
      extras.sdkConfiguration = resolvedSdkConfiguration;
    }

    if (errorReportMetadata) {
      extras = { ...extras, ...errorReportMetadata };
    }

    scope.setExtras(extras);
    Sentry.captureException(error);
  });
}

export function withErrorHandlingAsync<T>(
  func: () => Promise<T>,
  params: WithErrorHandlingProps & { rethrowError: true },
): Promise<T>;
export async function withErrorHandlingAsync<T>(
  func: () => Promise<T>,
  params: WithErrorHandlingProps & { rethrowError: false },
): Promise<T | undefined>;
export async function withErrorHandlingAsync<T>(func: () => Promise<T>, params: WithErrorHandlingProps) {
  try {
    const result = await func();
    return result;
  } catch (err: any) {
    return onError(err, params);
  }
}

type NotPromise<T> = T extends Promise<unknown> ? never : T;

export function withErrorHandling<T>(
  func: () => NotPromise<T>,
  params: WithErrorHandlingProps & { rethrowError: true },
): T;
export function withErrorHandling<T>(
  func: () => NotPromise<T>,
  params: WithErrorHandlingProps & { rethrowError: false },
): T | undefined;
export function withErrorHandling<T>(func: () => NotPromise<T>, params: WithErrorHandlingProps) {
  try {
    const result = func();
    return result;
  } catch (err: any) {
    return onError(err, params);
  }
}

function onError(err: any, params: WithErrorHandlingProps & { rethrowError: boolean }): undefined | never {
  const { loggerService, sdkConfiguration, errorMessage, rethrowError, errorReportMetadata } = params;
  const formattedErrorMessage = errorMessage ? errorMessage(err) : `Operation failed. Error: ${err.message}`;
  loggerService.error(formattedErrorMessage, err);
  logToSentry(err, { sdkConfiguration, errorMessage: formattedErrorMessage, errorReportMetadata });

  if (!rethrowError) {
    return;
  }

  throw new Error(formattedErrorMessage);
}
