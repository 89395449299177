const routePaths = {
  admin: {
    base: "/admin/*",
    onboarding: "onboarding",
    companies: "companies",
    organizations: "organizations",
  },
  apiToken: "/api-token",
  billings: {
    base: "/companies/billings",
    list: "list",
    checkout: "checkout/:companyId",
  },
  integrationLanding: "/integration-landing",
  deprecatedCompaniesLanding: "/companies/:companyId/*",
  companiesLanding: "/companies/:companyId/scenario/:scenarioId/*",
  companiesSelect: "/companies/select",
  companyDashboard:
    "/companies/:companyId/scenario/:scenarioId/dashboard/:dashboardId",
  homepage: "/companies/:companyId/scenario/:scenarioId/home",
  companyIntegrationLanding:
    "/companies/:companyId/scenario/:scenarioId/integration/:integrationId/status/:status",
  noCompanies: "/no-companies",
  notFound: "/404",
  onboarding: "/onboarding/:slug",
  selfOnboarding: {
    base: "/onboarding",
    signUp: "sign-up",
    loggedIn: "logged-in",
    alreadyAUser: "already-a-user",
    createCompany: "create-company",
    companyReady: "company-ready",
    connectIntegration: "connect-integration",
    expired: "expired",
    integrations: {
      base: "integrations/:slug",
      connect: "connect",
      companyReady: "company-ready",
    },
  },
  outputs: "/companies/:companyId/scenario/:scenarioId/outputs/:outputTableId",
  settings: {
    base: "settings",
    integrations: "integrations",
    permissions: "permissions",
    plan: "plan",
    developmentPermissions: "development-permissions",
  },
  signIn: "/sign-in",
  systemError: "/500",
  units: "/companies/:companyId/scenario/:scenarioId/inputs/:unitTableId",
  workbooks: "/companies/:companyId/scenario/:scenarioId/workbooks/:workbookId",
} as const;

const defaultRoutePath = routePaths.companiesSelect;

export { defaultRoutePath, routePaths };
