import { ThemeProvider as MaterialThemeProvider } from "@mui/material/styles";
import React, { FC } from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";

import { theme, stiggTheme } from "./theme";

type ThemeProviderProps = {
  children: React.ReactNode;
};

export const ThemeProvider: FC<ThemeProviderProps> = (
  props: ThemeProviderProps,
) => (
  <MaterialThemeProvider theme={theme}>
    <EmotionThemeProvider theme={{ stigg: stiggTheme }}>
      <StyledThemeProvider theme={theme}>{props.children}</StyledThemeProvider>
    </EmotionThemeProvider>
  </MaterialThemeProvider>
);
