import { css } from "styled-components";

import { toRem } from "./toRem";

export const iconCss = css`
  color: rgb(149, 154, 163);
  stroke-width: ${toRem(2)};

  &:hover {
    color: rgb(108, 131, 253);
  }
`;
