import { memo, useCallback, useEffect, useState } from "react";

import {
  NavigateFunction,
  Outlet,
  generatePath,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";

import { useAuth } from "@/hooks/use-auth";
import { routePaths } from "@/route-path";
import * as withReactRouterLocation from "@/utils/react-router";

type BillingContext = {
  checkoutPlanId: string | null;
  handlePlanChange: (companyId: string, planId: string) => void;
};

const useCheckUserAuth = (navigate: NavigateFunction) => {
  const auth = useAuth();
  const location = useLocation();
  useEffect(() => {
    const redirectToSignIn = () => {
      const nextUrl = withReactRouterLocation.getRelativeUrl(location);
      navigate(routePaths.signIn, { state: { nextUrl } });
    };
    auth
      .authCheck()
      .then((isAuthed) => {
        if (!isAuthed) {
          redirectToSignIn();
        }
        return isAuthed;
      })
      .catch(redirectToSignIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
};

const BillingOutletWithContextInternal = (): JSX.Element => {
  const [checkoutPlanId, setCheckoutPlanId] = useState<string | null>(null);

  const navigate = useNavigate();

  useCheckUserAuth(navigate);

  const handlePlanChange = useCallback((companyId: string, planId: string) => {
    setCheckoutPlanId(planId);
    navigate(
      generatePath(routePaths.billings.checkout, {
        companyId,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Outlet context={{ checkoutPlanId, handlePlanChange }} />;
};

export const BillingOutletWithContext = memo(BillingOutletWithContextInternal);

export const useBillingContext = (): BillingContext =>
  useOutletContext<BillingContext>();
