/* eslint-disable max-lines-per-function */
import {
  ApolloClient,
  NormalizedCacheObject,
  split,
  from,
  ApolloLink,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { getAuth, IdTokenResult } from "firebase/auth";
import { createUploadLink } from "apollo-upload-client";
import { CloseCode, createClient } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";

import { SentryLink } from "apollo-link-sentry";
import { onError } from "@apollo/client/link/error";

import { nonNullable } from "@basis-org/shared";

import { reportError } from "@/utils/report-error";

import { config } from "@/utils/config";

import { timeout } from "../promise";

import { cache } from "./cache";

const isWebSocket = (socket: unknown): socket is WebSocket =>
  typeof socket === "object" && nonNullable(socket) && "readyState" in socket;

const getTokenTimeToExpirationMs = (expiresAtUTC: string) =>
  Math.round(new Date(expiresAtUTC).getTime() - Date.now());

const maybeGetAuthToken = async () => {
  const token = await getAuth().currentUser?.getIdToken();
  return token ? `Bearer ${token}` : "";
};

const authTokenFromIdTokenResult = (tokenResult: IdTokenResult) =>
  `Bearer ${tokenResult.token}`;

const waitForToken = async () => {
  let tokenResult: undefined | IdTokenResult;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    tokenResult = await getAuth().currentUser?.getIdTokenResult();
    if (tokenResult) break;
    // eslint-disable-next-line no-await-in-loop
    await timeout(5);
  }
  return tokenResult;
};

export const createApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const httpLink = createUploadLink({
    uri: config.graphqlHttp,
    headers: { "apollo-require-preflight": "1" },
  });

  const sentryLink = new SentryLink({
    attachBreadcrumbs: {
      includeError: true,
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message }) => reportError(message));
    }
    if (networkError) {
      reportError(networkError);
    }
  });

  const authLink = setContext(async () => ({
    headers: { authorization: await maybeGetAuthToken() },
  }));

  // FIXME: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/47369
  // as of 9/20/22, apollo-upload-link uses an old version of apollo/client
  // eslint-disable-next-line unicorn/prefer-spread
  const httpAuthLink = authLink.concat(httpLink as unknown as ApolloLink);

  let tokenExpiryTimeout: NodeJS.Timeout | undefined; // the socket close timeout due to token expiry
  let tokenExpirationMs = 0;
  let activeSocket: WebSocket | undefined;
  let timedOut: NodeJS.Timeout;
  const wsLink = new GraphQLWsLink(
    createClient({
      url: config.graphqlWs,
      connectionParams: async () => {
        // potentially refresh the token if it is no longer valid
        const tokenResult = await waitForToken();
        tokenExpirationMs = getTokenTimeToExpirationMs(
          tokenResult.expirationTime,
        );
        return {
          authorization: authTokenFromIdTokenResult(tokenResult),
        };
      },
      keepAlive: 10_000,
      on: {
        connected: (socket) => {
          activeSocket = isWebSocket(socket) ? socket : undefined;

          // clear timeout on every connect for debouncing the expiry
          clearTimeout(tokenExpiryTimeout);

          // set a token expiry timeout for closing the socket
          // with an `4403: Forbidden` close event indicating
          // that the token expired. the `closed` event listener below
          // will set the token refresh flag to true
          tokenExpiryTimeout = setTimeout(() => {
            if (activeSocket && activeSocket.readyState === WebSocket.OPEN) {
              activeSocket.close(CloseCode.Forbidden, "Forbidden");
            }
          }, tokenExpirationMs);
        },
        ping: (received) => {
          if (!received)
            timedOut = setTimeout(() => {
              if (activeSocket && activeSocket.readyState === WebSocket.OPEN)
                activeSocket.close(
                  CloseCode.ConnectionInitialisationTimeout,
                  "Request Timeout",
                );
            }, 30_000); // wait 30 seconds for the pong and then close the connection
        },
        pong: (received) => {
          if (received) clearTimeout(timedOut); // pong is received, clear connection close timeout
        },
      },
      retryAttempts: 20,
      shouldRetry: () => true,
    }),
  );

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpAuthLink,
  );

  return new ApolloClient({
    cache,
    connectToDevTools: config.appEnv !== "production",
    link: from([sentryLink, errorLink, link]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
      },
    },
  });
};
