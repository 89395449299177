import { useParams } from "react-router";

export const useCurrentCompany = (): {
  companyId: string;
  scenarioId: string;
} => {
  let companyId = "";
  let scenarioId = "";
  try {
    const parameters = useParams<{ companyId?: string; scenarioId?: string }>();
    companyId = parameters.companyId || "";
    scenarioId = parameters.scenarioId || "";
    // eslint-disable-next-line no-empty
  } catch {}

  return { companyId, scenarioId };
};
