/* eslint-disable react/no-array-index-key */
import { Box, Skeleton, Stack } from "@mui/material";
import { ComponentProps, FC, useCallback, useState } from "react";
import styled from "styled-components";
import { useResizeDetector } from "react-resize-detector";
import { Search as SearchIcon } from "@carbon/icons-react";

import { toRem } from "@/utils/styled-components";

import { IconButton } from "../form/buttons";

import { renderIcon } from "../custom-icon";

import { FullscreenContainer } from "./full-screen-container";

type FullScreenContainerProps = ComponentProps<typeof FullscreenContainer>;

type LoadingSkeletonProps = {
  as?: FullScreenContainerProps["as"];
  className?: string;
  standalone?: boolean;
  height?: number;
  numTablesShown?: number;
  numTableRows?: number;
  tableToolbarHeight?: number;
  hideToolbar?: boolean;
  compact?: boolean;
  ["data-testid"]?: string;
};

const CELL_WIDTH = 115; // This should be responsive as well
const HEADER_CELL_WIDTH = CELL_WIDTH * 2; // Assuming the header cell is double the standard cell width

const StyledTable = styled(Stack)`
  border: ${(props) => `1px solid ${props.theme.palette.divider}`};
  background-color: ${(props) => props.theme.palette.background.default};
  border-radius: ${toRem(4)};
`;

const RowContainer = styled(Box)<{
  justifyContent?: string;
  $dark?: boolean;
  $height?: number;
}>`
  display: flex;
  justify-content: ${(props) => props.justifyContent ?? "space-between"};
  border-bottom: ${(props) => `1px solid ${props.theme.palette.divider}`};
  height: ${(props) => toRem(props.$height ?? 41)};
  background-color: ${(props) =>
    props.$dark
      ? props.theme.palette.background.paper
      : props.theme.palette.background.default};
`;

const StyledCell = styled.div<{ $width?: number }>`
  width: ${(props) => toRem(props.$width ?? CELL_WIDTH)};
  height: 100%;
  display: flex;
  align-items: center;
  border-right: ${(props) => `1px solid ${props.theme.palette.divider}`};
  padding: 0 ${toRem(4)};
`;

const StyledHeaderCell = styled(StyledCell)`
  padding-left: ${toRem(16)};
`;

const TableSkeleton = (props: {
  numTablesShown: number;
  numTableRows: number;
  compact: boolean;
  hideToolbar: boolean;
  tableToolbarHeight: number;
}): JSX.Element => {
  const [gridSize, setGridSize] = useState({
    numRows: props.numTableRows,
    numCols: 3,
  });

  const onResize = useCallback(
    (parentWidth = 0) => {
      const numCols = Math.floor(parentWidth / (CELL_WIDTH + 2)); // We want to cover full width of the table. '+2' represents margin
      setGridSize({ numRows: gridSize.numRows, numCols });
    },
    [gridSize.numRows],
  );

  const { ref: skeletonContainerRef } = useResizeDetector({ onResize });

  return (
    <Stack
      alignSelf="start"
      flex={1}
      px={props.compact ? 0 : 2}
      py={props.compact ? 0 : 1}
      ref={skeletonContainerRef}
      spacing={1}
    >
      {Array.from({ length: props.numTablesShown }).map((_, index) => (
        <StyledTable flex={1} key={`loading-table-${index}`}>
          {!props.hideToolbar && (
            <RowContainer $height={props.tableToolbarHeight}>
              <StyledHeaderCell $width={HEADER_CELL_WIDTH}>
                <Skeleton height="40%" variant="rectangular" width="50%" />
              </StyledHeaderCell>
            </RowContainer>
          )}

          {/* Header Row */}
          <RowContainer $dark>
            <StyledHeaderCell $width={HEADER_CELL_WIDTH}>
              <IconButton $isDisabled disabled>
                {renderIcon(SearchIcon)}
              </IconButton>
            </StyledHeaderCell>
            {Array.from({ length: gridSize.numCols - 2 }).map(
              (_colVal, headerCellIndex) => (
                <StyledCell key={`header-cell-${headerCellIndex}`}>
                  <Skeleton height="40%" variant="rectangular" width="90%" />
                </StyledCell>
              ),
            )}
          </RowContainer>

          {/* Data Rows */}
          {Array.from({ length: gridSize.numRows }).map((_rowVal, rowIndex) => (
            <RowContainer key={`row-${rowIndex}`}>
              <StyledHeaderCell $width={HEADER_CELL_WIDTH}>
                <Skeleton height="40%" variant="rectangular" width="50%" />
              </StyledHeaderCell>
              {Array.from({ length: gridSize.numCols - 2 }).map(
                (_colVal, colIndex) => (
                  <StyledCell key={`data-cell-${rowIndex}-${colIndex}`}>
                    <Skeleton height="40%" variant="rectangular" width="90%" />
                  </StyledCell>
                ),
              )}
            </RowContainer>
          ))}
          <RowContainer />
        </StyledTable>
      ))}
    </Stack>
  );
};

export const LoadingTableSkeleton: FC<LoadingSkeletonProps> = (props) => (
  <FullscreenContainer
    as={props.as}
    className={props.className}
    data-testid={props["data-testid"]}
    height={props.height}
    standalone={props.standalone}
  >
    <TableSkeleton
      compact={props.compact ?? false}
      hideToolbar={props.hideToolbar || false}
      numTableRows={props.numTableRows ?? 2}
      numTablesShown={props.numTablesShown ?? 1}
      tableToolbarHeight={props.tableToolbarHeight ?? 41}
    />
  </FullscreenContainer>
);
