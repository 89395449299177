import { httpMethods, HttpMethod } from "@/utils/http/httpMethod";
import { requestJson as httpRequestJson } from "@/utils/http/httpRequest";

import { ObjectRequiredSome } from "@/utils/object";

import getEndpointUrl from "./getEndpointUrl";
import getRequestHeaders from "./getRequestHeaders";
import * as withApiResponse from "./apiResponse";

type AnyData = undefined | Record<string, unknown>;

type Parameters<TData extends AnyData> = {
  data?: TData;
  firebaseIdToken?: string;
  method?: HttpMethod;
  path: string;
};

type ParametersWithDefaults<TData extends AnyData> = ObjectRequiredSome<
  Parameters<TData>,
  "method"
>;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line unicorn/prefer-set-has
const httpMethodsWithDataInUrl: HttpMethod[] = [
  httpMethods.delete,
  httpMethods.get,
];

const getEndpointUrlFrom = <TData extends AnyData>({
  data,
  method,
  path,
}: ParametersWithDefaults<TData>) =>
  httpMethodsWithDataInUrl.includes(method)
    ? getEndpointUrl(path, data)
    : getEndpointUrl(path);

const defaultHttpMethod = httpMethods.get;

const requestJson = <TApiResult, TData extends AnyData = undefined>({
  method = defaultHttpMethod,
  ...rest
}: Parameters<TData>): Promise<TApiResult> =>
  withApiResponse.getResultFrom<TApiResult>(
    httpRequestJson(getEndpointUrlFrom({ method, ...rest }), {
      data: httpMethodsWithDataInUrl.includes(method) ? undefined : rest.data,
      headers: rest.firebaseIdToken
        ? getRequestHeaders(rest.firebaseIdToken)
        : undefined,
      method,
    }),
  );

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default requestJson;
