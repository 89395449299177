import { FC } from "react";
import styled, { DefaultTheme, StyledComponent } from "styled-components";
import { StyledIcon as StyledIconProps } from "@styled-icons/styled-icon";
import { CarbonIconType } from "@carbon/icons-react/lib/CarbonIcon";

import { squareCss, SquareCssProps, toRem } from "@/utils/styled-components";
import { ReactSvgComponent } from "@/utils/react";

import { IconPack, IconPackProps } from "./icons";

export type IconTypeProps =
  | StyledIconProps
  | IconPackProps
  | CarbonIconType
  | string;

type CustomIconProps = {
  className?: string;
  name: IconPackProps;
} & SquareCssProps;

type IconMapType = Record<
  IconPackProps,
  StyledComponent<ReactSvgComponent, DefaultTheme, SquareCssProps>
>;

const IconMap = Object.fromEntries(
  Object.entries(IconPack).map(([name, Component]) => [
    name,
    styled(Component)<SquareCssProps>`
      ${squareCss};
    `,
  ]),
) as IconMapType;

const defaultIconSize = 14;
const defaultIconOpacity = 0.75;

export const CustomIcon: FC<CustomIconProps> = (props): JSX.Element => {
  const StyledIcon = IconMap[props.name];

  return (
    <StyledIcon
      className={props.className}
      name={props.name}
      size={props.size}
    />
  );
};

const ImageIcon = styled.img<{
  $height: number;
  $width: number;
  $opacity?: number;
}>`
  display: flex;
  height: ${(props) => toRem(props.$height)};
  width: ${(props) => toRem(props.$width)};
  opacity: ${(props) => props.$opacity ?? 1};
`;

const isCustomIconType = (icon: string): icon is IconPackProps =>
  Object.keys(IconMap).includes(icon as IconPackProps);

export const renderIcon = (
  Icon: IconTypeProps,
  size = defaultIconSize,
  opacity = defaultIconOpacity,
): JSX.Element => {
  if (typeof Icon === "string") {
    if (isCustomIconType(Icon)) {
      return <CustomIcon name={Icon} size={size} />;
    }
    return (
      <ImageIcon $height={size} $opacity={opacity} $width={size} src={Icon} />
    );
  }
  return (
    <Icon
      height={size}
      size={size}
      strokeOpacity={opacity}
      strokeWidth={2}
      width={size}
    />
  );
};
