import { ReactComponent as Quickbooks } from "./integrations/quickbooks.svg";
import { ReactComponent as Variance } from "./variance.svg";
import { ReactComponent as CheckboxChecked } from "./checkbox-checked.svg";
import { ReactComponent as CheckboxUnchecked } from "./checkbox-unchecked.svg";
import { ReactComponent as CheckboxIndeterminate } from "./checkbox-indeterminate.svg";
import { ReactComponent as Grid } from "./grid.svg";
import { ReactComponent as RadioChecked } from "./radio-checked.svg";
import { ReactComponent as RadioUnchecked } from "./radio-unchecked.svg";
import { ReactComponent as TableCheckBoxChecked } from "./table-checkbox-checked.svg";
import { ReactComponent as XDiamond } from "./x-diamond.svg";
import { ReactComponent as Unpin } from "./unpin.svg";
import { ReactComponent as MouseClick } from "./mouse-click.svg";
import { ReactComponent as GroupObjects } from "./group-objects.svg";
import { ReactComponent as GroupObjectsWhite } from "./group-objects-white.svg";
import { ReactComponent as Cicsplex } from "./cicsplex.svg";
import { ReactComponent as Delta } from "./triangle.svg";
import { ReactComponent as ChevronsRight } from "./chevrons-right.svg";
import { ReactComponent as ChevronsLeft } from "./chevrons-left.svg";
import { ReactComponent as DatabaseDownload } from "./database-download.svg";

export const IconPack = {
  checkboxChecked: CheckboxChecked,
  checkboxUnchecked: CheckboxUnchecked,
  checkboxIndeterminate: CheckboxIndeterminate,
  grid: Grid,
  quickbooks: Quickbooks,
  radioChecked: RadioChecked,
  radioUnchecked: RadioUnchecked,
  tableCheckboxChecked: TableCheckBoxChecked,
  unpin: Unpin,
  variance: Variance,
  xDiamond: XDiamond,
  mouseClick: MouseClick,
  groupObjects: GroupObjects,
  groupObjectsWhite: GroupObjectsWhite,
  cicsplex: Cicsplex,
  delta: Delta,
  chevronsRight: ChevronsRight,
  chevronsLeft: ChevronsLeft,
  databaseDownload: DatabaseDownload,
};

export type IconPackProps = keyof typeof IconPack;
