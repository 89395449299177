import CachedEntitlement from './cachedEntitlement';
import { AccessDeniedReason, FeatureType, MeterType } from '../models';
import { isNil } from 'lodash';

export class EntitlementDecisionService {
  public static decideEntitlementPolicy(entitlement?: CachedEntitlement | null, requestUsage?: number): Decision {
    if (!entitlement || !entitlement.calculatedEntitlement.feature) {
      return { hasAccess: false, accessDeniedReason: AccessDeniedReason.NoFeatureEntitlementInSubscription };
    }

    const { featureType, meterType } = entitlement.calculatedEntitlement.feature;

    switch (featureType) {
      case FeatureType.Boolean:
        return { hasAccess: true };
      case FeatureType.Number: {
        if (entitlement.calculatedEntitlement.feature?.meterType === MeterType.None) {
          return { hasAccess: true };
        }
        if (entitlement.calculatedEntitlement.hasUnlimitedUsage || entitlement.calculatedEntitlement.hasSoftLimit) {
          return { hasAccess: true };
        }

        let currentUsage = entitlement.featureUsage.currentUsage;
        const nextResetDate = entitlement.featureUsage.nextResetDate;
        if (meterType === MeterType.Incremental && !isNil(nextResetDate) && nextResetDate < new Date().getTime()) {
          currentUsage = 0;
        }
        if (currentUsage + (requestUsage || 0) <= (entitlement.calculatedEntitlement?.usageLimit || 0)) {
          return { hasAccess: true };
        } else {
          return { hasAccess: false, accessDeniedReason: AccessDeniedReason.RequestedUsageExceedingLimit };
        }
      }
    }
  }
}

export interface Decision {
  readonly hasAccess: boolean;
  readonly accessDeniedReason?: AccessDeniedReason;
}
