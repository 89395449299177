import { TypedEmitter } from 'tiny-typed-emitter';
import CachedEntitlement from './cachedEntitlement';

export interface Events {
  initializeSuccessfully: () => void;
  initializeFailed: (err: any) => void;
  entitlementsUpdated: (data: Map<string, CachedEntitlement>) => void;
}

export type EventNames = keyof Events;

export class TypedEventEmitter extends TypedEmitter<Events> {
  constructor() {
    super();
  }
}
