import React, { useEffect, useMemo } from "react";

import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { nonNullable } from "@basis-org/shared";

import { IntercomProps, useIntercom } from "react-use-intercom";

import { routePaths } from "@/route-path";
import * as withReactRouterLocation from "@/utils/react-router";
import { useSegmentCommonEvents } from "@/utils/segment-io/segmentIo";
import { useAuth } from "@/hooks/use-auth";
import { useCurrentCompany } from "@/hooks/use-current-company";

type PrivateRouteProps = {
  children: React.ReactElement | null;
};

const PrivateRoute = (props: PrivateRouteProps): React.ReactElement | null => {
  const location = useLocation();
  const navigate = useNavigate();
  const { companyId } = useCurrentCompany();
  const auth = useAuth();
  const isCompaniesSelectRoute = useMatch(routePaths.companiesSelect);

  useSegmentCommonEvents();
  const intercom = useIntercom();

  useEffect(() => {
    if (!auth?.user) {
      return;
    }

    const intercomData: Partial<IntercomProps> = {
      email: auth.user.email,
      name: `${auth.user.firstName} ${auth.user.lastName}`,
      userId: auth.user.id,
      company: auth.activeCompany
        ? { companyId: auth.activeCompany.id, name: auth.activeCompany.name }
        : undefined,
    };

    intercom.update(intercomData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user, auth?.activeCompany]);

  useEffect(() => {
    const redirectToSignIn = () => {
      const nextUrl = withReactRouterLocation.getRelativeUrl(location);
      navigate(routePaths.signIn, { state: { nextUrl } });
    };
    auth
      .authCheck()
      .then((isAuthed) => {
        if (!isAuthed) {
          redirectToSignIn();
        }
        return isAuthed;
      })
      .catch(redirectToSignIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const nextUrl = withReactRouterLocation.getRelativeUrl(location);
    if (!auth.user) {
      navigate(routePaths.signIn, { state: { nextUrl } });
    }
    auth.updateActiveCompany(companyId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user, companyId]);

  const shouldRender = useMemo(
    () => isCompaniesSelectRoute || nonNullable(auth?.activeCompany),
    [isCompaniesSelectRoute, auth?.activeCompany],
  );

  if (!shouldRender) return null;

  return props.children;
};

export { PrivateRoute };
