import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import { PreviewSubscription, EstimateSubscription, EstimateSubscriptionUpdate } from '../models';
import {
  EstimateSubscriptionMutation,
  EstimateSubscriptionMutationVariables,
  EstimateSubscriptionUpdateMutation,
  EstimateSubscriptionUpdateMutationVariables,
  EstimateSubscriptionDocument,
  EstimateSubscriptionUpdateDocument,
  PreviewSubscriptionDocument,
  PreviewSubscriptionMutation,
  PreviewSubscriptionMutationVariables,
} from '@stigg/api-client-js/src/generated/sdk';

class SubscriptionEstimationsApi {
  constructor(private readonly client: ApolloClient<NormalizedCacheObject>) {}

  async estimateSubscription(input: EstimateSubscription) {
    return this.client.mutate<EstimateSubscriptionMutation, EstimateSubscriptionMutationVariables>({
      mutation: EstimateSubscriptionDocument,
      variables: {
        input: {
          resourceId: input.resourceId,
          planId: input.planId,
          customerId: input.customerId,
          startDate: input.startDate,
          addons: input.addons,
          billingPeriod: input.billingPeriod,
          priceUnitAmount: input.unitQuantity,
          billableFeatures: input.billableFeatures,
          billingInformation: {
            taxRateIds: input?.billingInformation?.taxRateIds,
            taxPercentage: input?.billingInformation?.taxPercentage,
          },
          promotionCode: input.promotionCode,
          billingCountryCode: input.billingCountryCode,
          skipTrial: input.skipTrial,
        },
      },
    });
  }

  async estimateSubscriptionUpdate(input: EstimateSubscriptionUpdate) {
    return this.client.mutate<EstimateSubscriptionUpdateMutation, EstimateSubscriptionUpdateMutationVariables>({
      mutation: EstimateSubscriptionUpdateDocument,
      variables: {
        input: {
          subscriptionId: input.subscriptionId,
          unitQuantity: input.unitQuantity,
          billableFeatures: input.billableFeatures,
          addons: input.addons,
          promotionCode: input.promotionCode,
        },
      },
    });
  }

  async previewSubscription(input: PreviewSubscription) {
    return this.client.mutate<PreviewSubscriptionMutation, PreviewSubscriptionMutationVariables>({
      mutation: PreviewSubscriptionDocument,
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          planId: input.planId,
          customerId: input.customerId,
          resourceId: input.resourceId,
          startDate: input.startDate,
          addons: input.addons,
          billingPeriod: input.billingPeriod,
          billableFeatures: input.billableFeatures,
          promotionCode: input.promotionCode,
          scheduleStrategy: input.scheduleStrategy,
          billingCountryCode: input.billingCountryCode,
          billingInformation: {
            taxRateIds: input?.billingInformation?.taxRateIds,
            taxPercentage: input?.billingInformation?.taxPercentage,
            billingAddress: input?.billingInformation?.billingAddress,
          },
        },
      },
    });
  }
}

export default SubscriptionEstimationsApi;
