import requestJson from "@/utils/api/requestJson";

import { ApiUser } from "../apiSchema";

type RequestData = {
  idToken: string;
};

type ApiAuthLoginResult = {
  user: ApiUser;
};

const login = (firebaseIdToken: string): Promise<ApiAuthLoginResult> =>
  requestJson<ApiAuthLoginResult, RequestData>({
    data: { idToken: firebaseIdToken },
    method: "POST",
    path: "/auth/login",
  });

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default login;
