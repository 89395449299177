import { Outlet, useNavigate, useOutletContext } from "react-router-dom";

import { useState, ChangeEvent, useCallback } from "react";

import { useSet } from "ahooks";

import { addYears, getYear, subYears } from "date-fns";

import { nonNullable } from "@basis-org/shared";

import { generatePath } from "react-router";

import { IntercomProps, useIntercom } from "react-use-intercom";

import { useSegment } from "@/utils/segment-io/segmentIo";

import { InputDropdownProps } from "@/components/form/input-dropdown";

import { stringDateToGqlFormat } from "@/utils/date-for-utils/date-for-utils";

import { routePaths } from "@/route-path";

import { hasFeature } from "@/utils/features";

import { useCreateSelfOnboardingFlowMutation } from "../hooks/create-self-onboarding-flow.generated";
import { buildSelfOnboardingRoute } from "../utils/build-onboarding-route";
import { OrganizationType, SelfOnboardingSteps } from "../types";
import { getCompanySizeDataFromType } from "../utils/company-size-from-type";

export type SelfOnboardingFormData = {
  companyName: string | undefined;
  companyType: OrganizationType | undefined;
  companyPlanLength: string | undefined;
  fiscalYearStartMonth: number | undefined;
};

export type FormFieldName = keyof SelfOnboardingFormData;

export type SelfOnboardingFlowContext = {
  totalPages: number;
  inputForm: SelfOnboardingFormData;
  loadingCreateCompany: boolean;
  formFieldErrors: Set<string>;
  newOnboardingData: {
    companyId: string;
    defaultScenarioId: string;
    slug: string;
  };
  updateOnboardingData: ({
    slug,
    companyId,
    defaultScenarioId,
  }: {
    slug: string;
    companyId: string;
    defaultScenarioId: string;
  }) => void;
  onChange: (
    fieldName: keyof SelfOnboardingFormData,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeWithValue: <T extends keyof SelfOnboardingFormData>(
    fieldName: T,
    value: SelfOnboardingFormData[T],
  ) => void;
  onCreateCompany: (userId: string) => void;
  onSelectFromDropdown: (
    fieldName: FormFieldName,
  ) => InputDropdownProps<string | number>["onChange"];
  validateFormFields: (requiredFields: string[]) => boolean;
};

const currentDate = new Date();

const initialFormData: SelfOnboardingFormData = {
  companyPlanLength: `${getYear(subYears(currentDate, 1))}-${getYear(
    addYears(currentDate, 1),
  )}`,
  companyName: "",
  companyType: OrganizationType.SmallAccountingFirm,
  fiscalYearStartMonth: 0,
};

const totalPages = 5;

const isEmptyString = (value: string | number | undefined): boolean =>
  typeof value === "string" && value.trim().length === 0;

// eslint-disable-next-line max-lines-per-function
export const SelfOnboardingOutletWithContext = (): JSX.Element => {
  const [selfOnboardingFormState, setSelfOnboardingFormState] =
    useState<SelfOnboardingFormData>(initialFormData);

  const { track } = useSegment();
  const intercom = useIntercom();

  const [loadingCreateCompany, setLoadingCreateCompany] = useState(false);

  const navigate = useNavigate();

  const [newOnboardingData, setNewOnboardingData] = useState<{
    companyId: string;
    defaultScenarioId: string;
    slug: string;
  } | null>(null);

  const [createSelfOnboardingFlow] = useCreateSelfOnboardingFlowMutation();

  const [
    formFieldErrors,
    { add: addFormFieldErrors, reset: resetFormFieldErrors },
  ] = useSet<string>();

  const validateFormFields = useCallback(
    (requiredFields: string[]) => {
      const invalidInputs = Object.entries(selfOnboardingFormState)
        .filter(
          ([field, value]) =>
            requiredFields.includes(field) &&
            (!nonNullable(value) || isEmptyString(value)),
        )
        .map((input) => input[0]);
      resetFormFieldErrors();
      invalidInputs.forEach((invalidInput) => addFormFieldErrors(invalidInput));

      return invalidInputs.length === 0;
    },
    [addFormFieldErrors, resetFormFieldErrors, selfOnboardingFormState],
  );

  const updateFormState = useCallback(
    <T extends keyof SelfOnboardingFormData>(
      fieldName: T,
      value: SelfOnboardingFormData[T],
    ) => {
      setSelfOnboardingFormState((previousState) => ({
        ...previousState,
        [fieldName]: value,
      }));
    },
    [],
  );

  const updateOnboardingData = useCallback(
    ({
      slug,
      companyId,
      defaultScenarioId,
    }: {
      slug: string;
      companyId: string;
      defaultScenarioId: string;
    }) => {
      setNewOnboardingData({
        slug,
        companyId,
        defaultScenarioId,
      });
    },
    [],
  );

  const onChange = useCallback(
    (fieldName: FormFieldName) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      updateFormState(fieldName, value);
    },
    [updateFormState],
  );

  const onChangeWithValue = useCallback(
    <T extends keyof SelfOnboardingFormData>(
      fieldName: T,
      value: SelfOnboardingFormData[T],
    ) => {
      updateFormState(fieldName, value);
    },
    [updateFormState],
  );

  const onSelectFromDropdown = useCallback(
    (
      fieldName: FormFieldName,
    ): InputDropdownProps<string | number>["onChange"] =>
      (event) => {
        const value = event.target.value;

        updateFormState(fieldName, value);
      },
    [updateFormState],
  );

  const onCreateCompany = useCallback(
    async (userId: string) => {
      const allValid = validateFormFields([
        "companyName",
        "companyPlanLength",
        "fiscalYearStartMonth",
      ]);

      if (allValid) {
        const [startDate, endDate] =
          selfOnboardingFormState.companyPlanLength?.split("-") || "";

        const gqlStartDate = stringDateToGqlFormat(startDate);
        const gqlEndDate = stringDateToGqlFormat(endDate);

        setLoadingCreateCompany(true);

        await createSelfOnboardingFlow({
          variables: {
            companyName: selfOnboardingFormState.companyName || "",
            industry: selfOnboardingFormState.companyType || "",
            startDate: gqlStartDate,
            endDate: gqlEndDate,
            fiscalYearStartMonth:
              selfOnboardingFormState.fiscalYearStartMonth || 0,
          },
          onCompleted: (data) => {
            if (data.createOnboardingV2.company) {
              const companySizeData = getCompanySizeDataFromType(
                selfOnboardingFormState.companyType || OrganizationType.Other,
              );
              track("Completed Onboarding Step - Company Created", {
                stepNum: 2,
                stepName: "Company Created",
                segment: companySizeData.segment,
                account_size: companySizeData.account_size,
              });

              const intercomData: Partial<IntercomProps> = {
                userId,
                customAttributes: {
                  completed_onboarding_step: SelfOnboardingSteps.CompanyCreated,
                },
              };

              intercom.update(intercomData);

              setLoadingCreateCompany(false);
              setNewOnboardingData({
                companyId: data.createOnboardingV2.company.id,
                defaultScenarioId:
                  data.createOnboardingV2.company.defaultScenarioId || "",
                slug: data.createOnboardingV2.slug || "",
              });
            }
            if (hasFeature("ENABLE_SELF_ONBOARDING_INTEGRATION_STEP")) {
              navigate(
                generatePath(
                  buildSelfOnboardingRoute(
                    routePaths.selfOnboarding.integrations.base,
                  ),
                  { slug: data.createOnboardingV2.slug },
                ),
              );
            } else {
              navigate(
                buildSelfOnboardingRoute(
                  routePaths.selfOnboarding.companyReady,
                ),
              );
            }
          },
        });
      } else {
        reportError("Required field validation failed");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selfOnboardingFormState, validateFormFields],
  );

  return (
    <Outlet
      context={{
        formFieldErrors,
        inputForm: selfOnboardingFormState,
        onChange,
        onChangeWithValue,
        onSelectFromDropdown,
        totalPages,
        validateFormFields,
        onCreateCompany,
        newOnboardingData,
        updateOnboardingData,
        loadingCreateCompany,
      }}
    />
  );
};

export const useSelfOnboardingFlow = (): SelfOnboardingFlowContext =>
  useOutletContext<SelfOnboardingFlowContext>();
