import "@fontsource/roboto-mono/latin-400.css";
import "@fontsource/roboto-mono/latin-700.css";

import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

import "./font.css";

import "./print.css";
import "@stigg/react-sdk/dist/styles.css";

const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
    box-sizing: border-box;
  }

  html {
    font-family: "NeueHaasUnicaPro-Regular", "Roboto", '"Helvetica Neue"';
  }

  #webpack-dev-server-client-overlay {
    display: none;
  }
`;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default GlobalStyle;
