export enum SelfOnboardingSteps {
  SignedUp = "account_signed_up",
  CompanyCreated = "company_created",
  Integrated = "financials_integrated",
}

export enum OrganizationType {
  SmallAccountingFirm = "Accounting/Fractional CFO Firm  (1 - 5 clients)",
  MiddleAccountingFirm = "Accounting/Fractional CFO Firm  (5 - 20 clients)",
  LargeAccountingFirm = "Accounting/Fractional CFO Firm  (20 - 100 clients)",
  ExtraLargeAccountingFirm = "Accounting/Fractional CFO Firm  (100+ clients)",
  SmallCompany = "Company (1-50 employees)",
  MiddleCompany = "Company (50+ employees)",
  Other = "Other",
}
