import * as Sentry from "@sentry/react";

import { ApiUser } from "./api/apiSchema";
import * as features from "./features";

type SetUserParameters = {
  user: ApiUser;
};

export const setUser = ({ user }: SetUserParameters): void => {
  Sentry.setUser({
    ...user,
    username: `${user.firstName} ${user.lastName}`,
    ip_address: "{{auto}}",
  });

  features.setUser(user.email);
};

export const clearUser = (): void => {
  features.clearUser();
};
