import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import { EdgeApiClient } from './EdgeApiClient';
import {
  GetSdkConfigurationDocument,
  GetSdkConfigurationQuery,
  GetSdkConfigurationQueryVariables,
} from '@stigg/api-client-js/src/generated/sdk';

class SdkConfigurationApi {
  constructor(
    private readonly client: ApolloClient<NormalizedCacheObject>,
    private readonly edgeApiClient: EdgeApiClient | null,
  ) {}

  getSdkConfiguration() {
    if (this.edgeApiClient) {
      return this.edgeApiClient.getSdkConfiguration();
    }

    return this.client.query<GetSdkConfigurationQuery, GetSdkConfigurationQueryVariables>({
      query: GetSdkConfigurationDocument,
    });
  }
}

export default SdkConfigurationApi;
