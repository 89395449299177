// fetch API polyfill
import 'cross-fetch/polyfill';

import fetchRetry from 'fetch-retry';

export const plainFetch = fetch;

// No retries in tests
const RETRY_ATTEMPTS_COUNT = process.env.NODE_ENV !== 'test' ? 5 : 0;

export const fetchWithRetry = fetchRetry(fetch, {
  retryOn: (attempt, error, response) => {
    if (attempt > RETRY_ATTEMPTS_COUNT) {
      return false;
    }
    // retry on any network error or 5xx status codes
    return error !== null || !response?.status || response.status >= 500;
  },
  retryDelay: (attempt) => {
    return Math.pow(2, attempt) * 1000; // 1000, 2000, 4000, ...
  },
});
