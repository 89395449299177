import { css } from "styled-components";

import { toRem } from "./toRem";

export type SquareCssProps = { size: number };

const getSize = ({ size }: SquareCssProps) => toRem(size);

export const squareCss = css`
  height: ${getSize};
  width: ${getSize};
`;
