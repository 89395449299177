import { CacheService } from './cacheService';
import CachedEntitlement from './cachedEntitlement';

export class InMemoryCacheService implements CacheService {
  private entitlements: Map<string, CachedEntitlement>;
  private lastUpdate: Date | null;
  private features: Set<string>;

  constructor() {
    this.entitlements = new Map<string, CachedEntitlement>();
    this.features = new Set<string>();
    this.lastUpdate = null;
  }

  getEntitlement(featureId: string): CachedEntitlement | null {
    return this.entitlements.get(featureId) || null;
  }

  validateFeature(featureId: string): boolean {
    return this.features.has(featureId);
  }

  setFeatures = (features: string[]) => (this.features = new Set<string>(features));

  setEntitlements(entitlements: Map<string, CachedEntitlement>, lastUpdate: Date) {
    this.entitlements = entitlements;
    this.lastUpdate = lastUpdate;
  }

  getEntitlements(): Map<string, CachedEntitlement> {
    return this.entitlements;
  }

  getLastUpdate(): Date | null {
    return this.lastUpdate;
  }

  isLoaded(): boolean {
    return !!this.lastUpdate;
  }
}
