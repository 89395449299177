import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import {
  GetMockPaywallDocument,
  GetMockPaywallQuery,
  GetMockPaywallQueryVariables,
} from '@stigg/api-client-js/src/generated/sdk';
import { GetPaywallProps } from './PaywallApi';

type GetMockPaywallProps = GetPaywallProps;

class MockPaywallApi {
  constructor(private readonly client: ApolloClient<NormalizedCacheObject>) {}

  getMockPaywall({ productId, customerId, context }: GetMockPaywallProps) {
    return this.client.query<GetMockPaywallQuery, GetMockPaywallQueryVariables>({
      query: GetMockPaywallDocument,
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          ...(productId ? { productId } : {}),
          ...(customerId ? { customerId } : {}),
          ...(context ? { context } : {}),
        },
      },
    });
  }
}

export default MockPaywallApi;
