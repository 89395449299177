import { ElementType, FC, ReactNode } from "react";
import styled from "styled-components";

import { toRem } from "@/utils/styled-components";

type FullScreenContainerProps = {
  as?: ElementType;
  children?: ReactNode;
  className?: string;
  standalone?: boolean;
  height?: number;
  ["data-testid"]?: string;
};

const StyledFullscreenContainer = styled.div<{
  $standalone?: boolean;
  $height?: number;
}>`
  align-items: center;
  display: flex;
  height: ${(props) =>
    props.$standalone
      ? "100vh"
      : props.$height
      ? toRem(props.$height)
      : "100%"};
  justify-content: center;
  width: 100%;
  animation: 100ms ease-in forwards
    ${() => `@keyframes { from { opacity: 0; } to { opacity: 1; } }`};
`;

export const FullscreenContainer: FC<FullScreenContainerProps> = (props) => (
  <StyledFullscreenContainer
    $height={props.height}
    $standalone={props.standalone}
    className={props.className}
    data-testid={props["data-testid"]}
  >
    {props.children}
  </StyledFullscreenContainer>
);
