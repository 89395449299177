"use strict";
function __export(m) {
    for (var p in m) if (!exports.hasOwnProperty(p)) exports[p] = m[p];
}
Object.defineProperty(exports, "__esModule", { value: true });
const styled_components_1 = require("styled-components");
const utils_1 = require("./utils");
exports.GlobalTypeStyles = styled_components_1.createGlobalStyle `
	body {
		color: ${utils_1.getFontColor(utils_1.TextType.Body)};
		font-family: ${utils_1.getFontFamily(utils_1.TextType.Body)};
		font-size: ${utils_1.getFontSize(utils_1.TextType.Body)};
		font-weight: ${utils_1.getFontWeight(utils_1.TextType.Body)};
		font-style: ${utils_1.getFontStyle(utils_1.TextType.Body)};
		line-height: ${utils_1.getLineHeight(utils_1.TextType.Body)};
	}
`;
/* istanbul ignore next */
exports.Heading = styled_components_1.default("div").attrs(({ level }) => ({
    role: "heading",
    "aria-level": level || 1
})) `
	color: ${utils_1.getFontColor(utils_1.TextType.Heading)};
	font-family: ${utils_1.getFontFamily(utils_1.TextType.Heading)};
	font-size: ${utils_1.getFontSize(utils_1.TextType.Heading)};
	font-weight: ${utils_1.getFontWeight(utils_1.TextType.Heading)};
	font-style: ${utils_1.getFontStyle(utils_1.TextType.Heading)};
	line-height: ${utils_1.getLineHeight(utils_1.TextType.Heading)};
	${utils_1.getExtras(utils_1.TextType.Heading)}
`;
/* istanbul ignore next */
exports.Text = styled_components_1.default("p") `
	color: ${utils_1.getFontColor(utils_1.TextType.Body)};
	font-family: ${utils_1.getFontFamily(utils_1.TextType.Body)};
	font-size: ${utils_1.getFontSize(utils_1.TextType.Body)};
	font-weight: ${utils_1.getFontWeight(utils_1.TextType.Body)};
	font-style: ${utils_1.getFontStyle(utils_1.TextType.Body)};
	line-height: ${utils_1.getLineHeight(utils_1.TextType.Body)};
	${utils_1.getExtras(utils_1.TextType.Body)}
`;
/* istanbul ignore next */
exports.Span = styled_components_1.default(exports.Text).attrs(() => ({ as: "span" })) `
	color: ${utils_1.getFontColor(utils_1.TextType.Span)};
	font-family: ${utils_1.getFontFamily(utils_1.TextType.Span)};
	font-size: ${utils_1.getFontSize(utils_1.TextType.Span)};
	font-weight: ${utils_1.getFontWeight(utils_1.TextType.Span)};
	font-style: ${utils_1.getFontStyle(utils_1.TextType.Span)};
	line-height: ${utils_1.getLineHeight(utils_1.TextType.Span)};
	${utils_1.getExtras(utils_1.TextType.Span)}
`;
/* istanbul ignore next */
exports.Link = styled_components_1.default("a") `
	display: inline-block;
	color: ${utils_1.getFontColor(utils_1.TextType.Link)};
	font-family: ${utils_1.getFontFamily(utils_1.TextType.Link)};
	font-size: ${utils_1.getFontSize(utils_1.TextType.Link)};
	font-weight: ${utils_1.getFontWeight(utils_1.TextType.Link)};
	font-style: ${utils_1.getFontStyle(utils_1.TextType.Link)};
	line-height: ${utils_1.getLineHeight(utils_1.TextType.Link)};
	${utils_1.getExtras(utils_1.TextType.Link)}
`;
var utils_2 = require("./utils");
exports.defaultTheme = utils_2.defaultTheme;
__export(require("./types"));
