import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import { EdgeApiClient } from './EdgeApiClient';
import {
  GetEntitlementsDocument,
  GetEntitlementsQuery,
  GetEntitlementsQueryVariables,
  EntitlementCheckResult,
  ReportEntitlementCheckRequestedMutation,
  ReportEntitlementCheckRequestedMutationVariables,
  ReportEntitlementCheckRequestedDocument,
} from '@stigg/api-client-js/src/generated/sdk';

class EntitlementsApi {
  constructor(
    private readonly client: ApolloClient<NormalizedCacheObject>,
    private readonly edgeApiClient: EdgeApiClient | null,
  ) {}

  async getEntitlements(customerId: string, skipEdge = false, resourceId?: string) {
    // fetching entitlements from edge-api instead of graphql-api
    if (this.edgeApiClient && !skipEdge) {
      return this.edgeApiClient.getEntitlements(customerId, resourceId);
    }

    return this.client.query<GetEntitlementsQuery, GetEntitlementsQueryVariables>({
      query: GetEntitlementsDocument,
      variables: { query: { customerId, resourceId } },
      fetchPolicy: 'no-cache',
    });
  }

  pollEntitlements(customerId: string, interval: number, resourceId?: string) {
    // fetching entitlements from edge-api instead of graphql-api
    if (this.edgeApiClient) {
      return this.edgeApiClient.watchEntitlements(customerId, interval, resourceId);
    }

    return this.client.watchQuery<GetEntitlementsQuery, GetEntitlementsQueryVariables>({
      query: GetEntitlementsDocument,
      variables: { query: { customerId, resourceId } },
      fetchPolicy: 'no-cache',
      pollInterval: interval,
      errorPolicy: 'none',
    });
  }

  async reportEntitlementRequested(
    featureId: string,
    customerId: string,
    entitlementCheckResult: EntitlementCheckResult,
    resourceId?: string,
    requestedUsage?: number,
  ): Promise<void> {
    await this.client.mutate<ReportEntitlementCheckRequestedMutation, ReportEntitlementCheckRequestedMutationVariables>(
      {
        mutation: ReportEntitlementCheckRequestedDocument,
        variables: {
          entitlementCheckRequested: {
            entitlementCheckResult,
            customerId,
            featureId,
            resourceId,
            requestedUsage,
          },
        },
        fetchPolicy: 'no-cache',
      },
    );
  }
}

export default EntitlementsApi;
