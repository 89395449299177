import HttpResponseError from "./HttpResponseError";

import { HttpMethod } from "./httpMethod";

type RequestJsonParameters = Omit<RequestInit, "body" | "method"> & {
  data?: unknown;
  method?: HttpMethod;
};

const responseToJson = (response: Response) => response.json();

const ensureResponseIsSucceeded = (response: Response) =>
  response.ok ? response : Promise.reject(new HttpResponseError(response));

const request = (url: RequestInfo, parameters?: RequestInit) =>
  fetch(url, parameters).then(ensureResponseIsSucceeded);

const requestJsonHeaders = { "Content-Type": "application/json" };

const getFetchParametersWithData = ({
  data,
  headers,
  ...rest
}: RequestJsonParameters) => ({
  ...rest,
  body: JSON.stringify(data),
  headers: { ...headers, ...requestJsonHeaders },
});

const getFetchParameters = (parameters: RequestJsonParameters) =>
  parameters.data === undefined
    ? parameters
    : getFetchParametersWithData(parameters);

const requestJson = <TResponse>(
  url: RequestInfo,
  parameters: RequestJsonParameters = {},
): Promise<TResponse> =>
  request(url, getFetchParameters(parameters)).then(responseToJson);

export { requestJson };
