import { TypedEventEmitter } from './eventEmitter';

export enum InitializationState {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'READY',
  FAILED = 'FAILED',
}

export class InitializationStateTracker {
  initializationPromise: Promise<void> | null = null;
  initializationStatus = InitializationState.IN_PROGRESS;
  initFailureError: Error | null = null;

  constructor(private readonly eventEmitter: TypedEventEmitter) {}

  getInitializationPromise() {
    if (this.initializationPromise) {
      return this.initializationPromise;
    }

    if (this.initializationStatus === InitializationState.SUCCESS) {
      return Promise.resolve();
    }

    if (this.initializationStatus === InitializationState.FAILED) {
      return Promise.reject(this.initFailureError);
    }

    this.initializationPromise = new Promise((resolve, reject) => {
      const onSuccess = () => {
        resolve();
      };
      const onFailure = (err: Error) => {
        reject(err);
      };
      this.eventEmitter.once('initializeSuccessfully', onSuccess);
      this.eventEmitter.once('initializeFailed', onFailure);
    });

    return this.initializationPromise;
  }

  signalSuccessInit() {
    this.initializationStatus = InitializationState.SUCCESS;
    this.eventEmitter.emit('initializeSuccessfully');
  }

  signalFailedInit(err: any) {
    this.initializationStatus = InitializationState.FAILED;
    this.initFailureError = err;
    this.eventEmitter.emit('initializeFailed', err);
  }
}
