import styled from "styled-components";

import { DangerButton } from "@/components/form/buttons";

import { toRem } from "@/utils/styled-components";

const SecondaryActionButton = styled(DangerButton)`
  border-radius: ${toRem(4)} !important;
  color: ${(props) => props.theme.palette.text.secondary} !important;
  font-family: ${(props) => props.theme.typography.fontFamilies.neuehaas};
  padding: ${toRem(6)} !important;
  text-transform: inherit !important;

  :hover,
  :focus {
    color: ${(props) => props.theme.palette.error.main} !important;
  }
`;

export { SecondaryActionButton };
