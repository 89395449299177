import CustomError from "@/utils/error/CustomError";

import * as withHttpStatusCode from "./httpStatusCode";

import { HttpStatusCode } from "./httpStatusCode";

const getResponseStatusCode = (response: Response) =>
  response.status as HttpStatusCode;

const getMessage = (response: Response) =>
  response.statusText ||
  withHttpStatusCode.getText(getResponseStatusCode(response));

class HttpResponseError extends CustomError {
  constructor(response: Response) {
    super(getMessage(response));

    this.response = response;
  }

  response: Response;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default HttpResponseError;
