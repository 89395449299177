import React, { useMemo } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { hasFeature } from "@/utils/features";

import { PrivateRoute } from "@/components/private-route";

import { FullScreenLoadingIndicator } from "./components/loading-indicator";
import { useAuth } from "./hooks/use-auth";

import { defaultRoutePath, routePaths } from "./route-path";
import { AdminPage } from "./routes/admin";
import {
  SelfOnboardingOutletWithContext,
  SelfOnboardingIntegrationOutletWithContext,
} from "./routes/self-onboarding/context";
import {
  SelfOnboardingAlreadyUserPage,
  SelfOnboardingConnectIntegrationPage,
  SelfOnboardingCreateCompanyPage,
  SelfOnboardingExpiredPage,
  SelfOnboardingIntegrationCompanyReadyPage,
  SelfOnboardingLoggedInPage,
  SelfOnboardingSignInPage,
} from "./routes/self-onboarding";
import { BillingOutletWithContext } from "./routes/billing/context/billing-outlet-with-context";

const NotFoundPage = React.lazy(() => import("./routes/errors/404/404Page"));
const SystemErrorPage = React.lazy(() => import("./routes/errors/500/500Page"));
const SignInPage = React.lazy(() => import("./routes/sign-in/SignInPage"));
const NoCompaniesPage = React.lazy(
  () => import("./routes/errors/no-companies/NoCompaniesPage"),
);
const ApiTokenPage = React.lazy(
  () => import("./routes/api-token/api-token-page"),
);
const SelectCompanyPage = React.lazy(
  () => import("./routes/select-company/SelectCompanyPage"),
);

const BillingsPage = React.lazy(() => import("./routes/billing/billings-page"));

const BillingCheckoutPage = React.lazy(
  () => import("./routes/billing/billing-checkout-page"),
);

const CompanySelectedPage = React.lazy(
  () => import("./routes/company-selected/company-selected-page"),
);

const IntegrationLanding = React.lazy(
  () => import("./routes/integration-landing/integration-landing"),
);

export const Init = (): JSX.Element | null => {
  const auth = useAuth();

  const isPageLoading = useMemo(() => auth.loading, [auth.loading]);

  if (isPageLoading) {
    return <FullScreenLoadingIndicator standalone />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <PrivateRoute>
              <SelectCompanyPage />
            </PrivateRoute>
          }
          path={routePaths.companiesSelect}
        />
        <Route
          element={<BillingOutletWithContext />}
          path={routePaths.billings.base}
        >
          <Route element={<Navigate to={routePaths.billings.list} />} path="" />
          <Route element={<BillingsPage />} path={routePaths.billings.list} />
          <Route
            element={<BillingCheckoutPage />}
            path={routePaths.billings.checkout}
          />
        </Route>
        <Route element={<SignInPage />} path={routePaths.signIn} />
        <Route element={<NoCompaniesPage />} path={routePaths.noCompanies} />
        <Route element={<SystemErrorPage />} path={routePaths.systemError} />
        <Route
          element={
            hasFeature("SELF_SERVE_API_KEY") ? (
              <ApiTokenPage />
            ) : (
              <Navigate to={routePaths.notFound} />
            )
          }
          path={routePaths.apiToken}
        />
        <Route element={<NotFoundPage />} path={routePaths.notFound} />s
        <Route element={<Navigate to={defaultRoutePath} />} path="/" />
        <Route
          element={<CompanySelectedPage />}
          path={routePaths.companiesLanding}
        />
        <Route
          element={<CompanySelectedPage />}
          path={routePaths.deprecatedCompaniesLanding}
        />
        <Route
          element={<IntegrationLanding />}
          path={routePaths.integrationLanding}
        />
        <Route element={<AdminPage />} path={routePaths.admin.base} />
        <Route element={<Navigate to={routePaths.notFound} />} path="*" />
        <Route
          element={<SelfOnboardingOutletWithContext />}
          path={routePaths.selfOnboarding.base}
        >
          <Route
            element={<Navigate to={routePaths.selfOnboarding.signUp} />}
            path=""
          />
          <Route
            element={<SelfOnboardingSignInPage />}
            path={routePaths.selfOnboarding.signUp}
          />
          <Route
            element={<SelfOnboardingLoggedInPage />}
            path={routePaths.selfOnboarding.loggedIn}
          />
          <Route
            element={<SelfOnboardingAlreadyUserPage />}
            path={routePaths.selfOnboarding.alreadyAUser}
          />
          <Route
            element={<SelfOnboardingCreateCompanyPage />}
            path={routePaths.selfOnboarding.createCompany}
          />
          <Route
            element={<SelfOnboardingExpiredPage />}
            path={routePaths.selfOnboarding.expired}
          />
          <Route
            element={<SelfOnboardingIntegrationOutletWithContext />}
            path={routePaths.selfOnboarding.integrations.base}
          >
            <Route
              element={
                <Navigate to={routePaths.selfOnboarding.integrations.connect} />
              }
              path=""
            />
            <Route
              element={<SelfOnboardingConnectIntegrationPage />}
              path={routePaths.selfOnboarding.integrations.connect}
            />
            <Route
              element={<SelfOnboardingIntegrationCompanyReadyPage />}
              path={routePaths.selfOnboarding.integrations.companyReady}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
