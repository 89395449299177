import { useApolloClient } from "@apollo/client";

import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";

import React, { useCallback, useEffect, useMemo, useState } from "react";

import styled from "styled-components";

import { validate } from "uuid";

import { routePaths } from "@/route-path";
import { ApiUser } from "@/utils/api/apiSchema";
import { config } from "@/utils/config";
import { createTypedContext } from "@/utils/create-typed-context";
import { toRem } from "@/utils/styled-components";

import { GetCurrentCompanyDocument } from "./get-current-company.generated";
import { authExisting, ensureAuthed, signOut } from "./utils";

type AuthProviderProps = {
  children: React.ReactNode;
};

export enum AuthErrorMessage {
  NoMessage = "",
  NotAuthorized = "Unable to log in. Try again or contact support@basis.so",
  UserNotInBasisDb = "No known user conforms to the provided identity token - please ensure you are a registered user of the Basis platform.",
  SessionExpired = "Your session expired, please log in again",
}

type AuthContextProps = {
  user: ApiUser | undefined;
  loading: boolean;
  activeCompany:
    | {
        id: string;
        name: string;
        createdAt: string;
        organization: { id: string | undefined; name: string | undefined };
      }
    | undefined;
  authErrorMessage: string;
  setUser: React.Dispatch<React.SetStateAction<ApiUser | undefined>>;
  logout: () => void;
  authCheck: () => Promise<boolean>;
  updateActiveCompany: (companyId: string | undefined) => void;
};

const [useAuthInternal, AuthContextProvider] =
  createTypedContext<AuthContextProps>();

export const useAuth = useAuthInternal;

// eslint-disable-next-line max-lines-per-function
export const AuthProvider = (props: AuthProviderProps): JSX.Element => {
  const [user, setUser] = useState<AuthContextProps["user"]>(undefined);
  const [activeCompany, setCompany] =
    useState<AuthContextProps["activeCompany"]>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [authErrorMessage, setAuthErrorMessage] = useState<
    AuthContextProps["authErrorMessage"]
  >(AuthErrorMessage.NoMessage);
  const [showRetryModal, setShowRetryModal] = useState<boolean>(false);
  const client = useApolloClient();

  const setActiveCompany = useCallback(
    async (company: {
      id: string;
      name: string;
      createdAt: string;
      organization: { id: string; name: string };
    }) => {
      setCompany(company);
    },
    [],
  );

  const removeActiveCompany = useCallback(async () => {
    setCompany(undefined);
  }, []);

  const updateActiveCompany = useCallback(
    async (companyId: string | undefined) => {
      if (companyId === activeCompany?.id) return;

      if (companyId && validate(companyId)) {
        setLoading(true);
        try {
          const currCompany = await client.query({
            query: GetCurrentCompanyDocument,
            variables: {
              companyId,
            },
          });
          setActiveCompany({
            id: currCompany.data?.companyById.id,
            name: currCompany.data?.companyById.name,
            createdAt: currCompany.data?.companyById.createdAt,
            organization: {
              id: currCompany?.data?.companyById.organization?.id,
              name: currCompany?.data?.companyById.organization?.name,
            },
          });
        } catch {
          removeActiveCompany();
        }
      } else {
        removeActiveCompany();
      }
      setLoading(false);
    },
    [activeCompany?.id, client, setActiveCompany, removeActiveCompany],
  );

  const logout = useCallback(async () => {
    await signOut();
    setUser(undefined);
    updateActiveCompany(undefined);
    setLoading(false);
  }, [updateActiveCompany]);

  const authCheck = useCallback(async (): Promise<boolean> => {
    try {
      await ensureAuthed();
      setAuthErrorMessage(AuthErrorMessage.NoMessage);
      return true;
    } catch {
      setAuthErrorMessage(AuthErrorMessage.SessionExpired);
      setUser(undefined);
    }
    return false;
  }, []);

  const checkUserAuth = async () => {
    setAuthErrorMessage(AuthErrorMessage.NoMessage);
    const activeUser = await authExisting();

    if (activeUser) {
      setUser(activeUser);
    } else {
      setUser(undefined);
    }
    setLoading(false);
    setShowRetryModal(false);
  };
  // Runs once when the component first mounts
  useEffect(() => {
    // Disable initial auth check on sign-in page, so the user can sign in with a different account
    if (window.location.pathname.startsWith(routePaths.signIn)) {
      setLoading(false);
      return;
    }

    checkUserAuth().catch((error) => {
      if (error?.message === "Unauthorized") {
        setAuthErrorMessage(AuthErrorMessage.UserNotInBasisDb);
        setUser(undefined);
        setLoading(false);
        return;
      }

      if (error?.message === "Failed to fetch") {
        setShowRetryModal(true);
      } else {
        setAuthErrorMessage(AuthErrorMessage.NotAuthorized);
        setUser(undefined);
        setLoading(false);
      }
    });
  }, []);

  const value = useMemo(
    () => ({
      user,
      activeCompany,
      loading,
      authErrorMessage,
      logout,
      authCheck,
      setUser,
      updateActiveCompany,
    }),
    [
      user,
      activeCompany,
      loading,
      authErrorMessage,
      logout,
      authCheck,
      updateActiveCompany,
    ],
  );

  return (
    <AuthContextProvider value={value}>
      {showRetryModal && config.appEnv === "development" ? (
        <RetryModal onRetry={checkUserAuth} open />
      ) : null}

      {props.children}
    </AuthContextProvider>
  );
};

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.background.paper};
  padding: ${(props) => props.theme.spacing(4)};
  box-shadow: 24px;
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const RetryModal = (props: { open: boolean; onRetry: () => Promise<void> }) => {
  const retryInterval = 5;
  const [seconds, setSeconds] = useState(retryInterval);

  useEffect(() => {
    if (props.open) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds <= 0) {
            props.onRetry();
            return retryInterval;
          }
          return prevSeconds - 0.1;
        });
      }, 100);
      return () => clearInterval(interval);
    }
  });

  return (
    <Modal open={props.open}>
      <StyledBox>
        <Typography color="text.secondary" variant="subtitle1">
          Unable to reach server.
        </Typography>
        <Typography variant="h1">Retrying in</Typography>

        <Box display="flex" justifyContent="center" width="100%">
          <CircularProgressWithLabel
            label={Math.round(seconds)}
            progress={Math.abs((seconds - retryInterval) / retryInterval) * 100}
          />
        </Box>

        <Button onClick={props.onRetry} variant="contained">
          Retry now
        </Button>
      </StyledBox>
    </Modal>
  );
};

type CircularProgressWithLabelProps = {
  progress: number;
  label: number;
};

const CircularProgressWithLabel = (props: CircularProgressWithLabelProps) => (
  <Box
    alignItems="flex-end"
    display="flex"
    height={250}
    justifyContent="center"
    m={4}
    position="relative"
    width={250}
  >
    <CircularProgress size={250} value={props.progress} variant="determinate" />
    <Box
      alignItems="center"
      bottom={0}
      display="flex"
      justifyContent="center"
      left={0}
      position="absolute"
      right={0}
      top={0}
    >
      <Typography color="text.secondary" component="div" fontSize={toRem(160)}>
        {props.label}
      </Typography>
    </Box>
  </Box>
);
