import { Observable } from 'zen-observable-ts';
import { ApolloQueryResult } from '@apollo/client/core';

export class ObservablePoller<T> extends Observable<ApolloQueryResult<T>> {
  private stopped = false;

  constructor(getData: () => Promise<ApolloQueryResult<T>>, pollInterval: number) {
    super((subscriber) => {
      const tick = async () => {
        if (this.stopped) {
          subscriber.complete();
          return;
        }

        try {
          subscriber.next(await getData());
        } catch (e) {
          // ignore
        }

        setTimeout(() => tick(), pollInterval);
      };

      void tick();
    });
  }

  public stopPolling() {
    this.stopped = true;
  }
}
