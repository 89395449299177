import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAuth } from "@/hooks/use-auth";
import { useCurrentCompany } from "@/hooks/use-current-company";

type SegmentAnalytics = {
  identify: (id: string, data: Record<string, string>) => unknown;
  page: (data: Record<string, string>) => unknown;
  track: (event: string, data?: Record<string, unknown>) => unknown;
};

declare global {
  interface Window {
    analytics?: SegmentAnalytics;
  }
}

const mockFunc = () => {};

const getPageName = (url: string): string => {
  // Regular expression to extract the page name
  // Checks for a segment that is followed optionally by a UUID.
  // If it finds a match, it returns the page name; otherwise, it returns "Unknown Page"
  // Example without the UUID, return select: /companies/select
  // Example with the UUID, return workbooks: /companies/1e5ebb40-d3c2-4c01-9806-afb505655af2/scenario/da5d1778-0b7e-419f-b6e1-fc937de94ad2/workbooks/ec6093e2-51e2-4fac-8b28-333af57aec23
  const regex =
    /\/([a-z]+)(?:\/[\da-f]{8}-[\da-f]{4}-4[\da-f]{3}-[89ab][\da-f]{3}-[\da-f]{12})?\/?$/i;
  const match = url.match(regex);

  return match ? match[1].toLowerCase() : "unknown-page";
};

export const useSegmentCommonEvents = (): void => {
  const auth = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (!auth?.user) return;

    const identify = window.analytics ? window.analytics.identify : mockFunc;

    let identifyData: Record<string, string> = {
      email: auth.user.email,
      name: `${auth.user.firstName} ${auth.user.lastName}`,
      displayName: `${auth.user.firstName} ${auth.user.lastName}`, // Trait for Fullstory
    };
    if (auth?.activeCompany) {
      identifyData = {
        ...identifyData,
        companyId: auth.activeCompany.id,
        companyName: auth.activeCompany.name,
        organizationId:
          auth.activeCompany.organization?.id || "no-organization-id-found",
        organizationName:
          auth.activeCompany.organization?.name || "no-organization-name-found",
      };
    }

    identify(auth.user.id, identifyData);
  }, [auth?.user, auth?.activeCompany]);

  useEffect(() => {
    if (!auth?.user) return;

    const page = window.analytics ? window.analytics.page : mockFunc;

    const pageData: Record<string, string> = {
      userId: auth.user.id,
      name: getPageName(location.pathname),
    };

    page(pageData);
  }, [auth.user, location.pathname]);
};

export const useSegment = (): {
  track: SegmentAnalytics["track"];
} => {
  const { companyId, scenarioId } = useCurrentCompany();

  const track = (event: string, data?: Record<string, unknown>): void => {
    const trackFunc = window.analytics ? window.analytics.track : mockFunc;

    const trackData = {
      ...data,
      companyId: companyId ?? "no-company-id-found",
      scenarioId: scenarioId ?? "no-scenario-id-found",
    };
    try {
      trackFunc(event, trackData);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      reportError(error.toString());
    }
  };

  return { track };
};
