import React, {
  FC,
  ReactNode,
  MouseEventHandler,
  RefObject,
  forwardRef,
} from "react";
import styled, { css } from "styled-components";

import LoadingButton from "@mui/lab/LoadingButton";

import { Badge, BadgeProps } from "@mui/material";

import { toRem } from "@/utils/styled-components";

import { renderIcon, IconTypeProps } from "@/components/custom-icon";

type SizesType = keyof typeof sizes;

export type BaseButtonProps = {
  ref?:
    | ((instance: HTMLButtonElement | null) => void)
    | RefObject<HTMLButtonElement>
    | null;
  ariaLabel?: string;
  block?: boolean;
  disabled?: boolean;
  children: ReactNode;
  className?: string;
  startIcon?: IconTypeProps;
  endComponent?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onSubmit?: MouseEventHandler<HTMLButtonElement>;
  size?: SizesType;
  type?: "button" | "reset" | "submit";
  href?: string;
  target?: string;
  loading?: boolean;
  showBadge?: boolean;
  badgeConfig?: {
    location?: "icon" | "button";
    variant?: BadgeProps["variant"];
    color?: BadgeProps["color"];
    badgeContent?: BadgeProps["badgeContent"];
  };
  "data-cy"?: string;
  ["data-testid"]?: string;
};

type StyledButtonProps = {
  $size: BaseButtonProps["size"];
  $block: BaseButtonProps["block"];
  target?: string;
};

const iconSizes = {
  xSmall: 12,
  small: 12,
  medium: 14,
  large: 18,
} as const;

const sizes = {
  xSmall: "xSmall",
  small: "small",
  medium: "medium",
  large: "large",
} as const;

const defaultIconSize = sizes.medium;
const defaultButtonSize = sizes.medium;

const largeButtonCss = css`
  font-size: ${toRem(18)} !important;
  padding: ${toRem(12)} ${toRem(18)} !important;
`;

const mediumButtonCss = css`
  font-size: ${toRem(14)} !important;
  padding: ${toRem(9)} ${toRem(12)} !important;
`;

const smallButtonCss = css`
  font-size: ${toRem(12)} !important;
  padding: ${toRem(6)} !important;
`;

const xSmallButtonCss = css`
  font-size: ${toRem(12)} !important;
  padding: ${toRem(2)} ${toRem(6)} !important;
`;

const buttonSizes = {
  large: largeButtonCss,
  medium: mediumButtonCss,
  small: smallButtonCss,
  xSmall: xSmallButtonCss,
};

const getButtonSizeCss = (props: StyledButtonProps) =>
  buttonSizes[props.$size || defaultButtonSize];

const getButtonBlockCss = (props: StyledButtonProps) =>
  props.$block &&
  css`
    display: block !important;
    min-width: 100% !important;
  `;

const StyledButton = styled(LoadingButton)<StyledButtonProps>`
  border-radius: ${toRem(4)} !important;
  font-family: inherit !important;
  line-height: 100% !important;
  text-transform: inherit !important;

  ${getButtonSizeCss};
  ${getButtonBlockCss};
`;

const StyledBadge = styled(Badge)`
  &.MuiBadge-root {
    display: flex;
    flex: 0 1 auto;
    min-width: inherit;
  }
`;

const renderIconIfExists = ({
  icon,
  size,
  showBadge,
  badgeConfig,
}: {
  icon: BaseButtonProps["startIcon"];
  size: BaseButtonProps["size"];
  showBadge: BaseButtonProps["showBadge"];
  badgeConfig: BaseButtonProps["badgeConfig"];
}) =>
  icon ? (
    <Badge
      badgeContent={badgeConfig?.badgeContent}
      color={badgeConfig?.color ?? "warning"}
      invisible={badgeConfig?.location !== "icon" || !showBadge}
      variant={badgeConfig?.variant ?? "dot"}
    >
      {renderIcon(icon, iconSizes[size || defaultIconSize])}
    </Badge>
  ) : undefined;

const BaseButton: FC<BaseButtonProps> = forwardRef(
  (props, ref): JSX.Element => (
    <StyledBadge
      badgeContent={props.badgeConfig?.badgeContent}
      color={props.badgeConfig?.color ?? "warning"}
      invisible={
        (props.badgeConfig?.location ?? "button") !== "button" ||
        !props.showBadge
      }
      variant={props.badgeConfig?.variant ?? "dot"}
    >
      <StyledButton
        $block={props.block}
        $size={props.size}
        aria-label={props.ariaLabel}
        className={props.className}
        data-cy={props["data-cy"]}
        data-testid={props["data-testid"]}
        disabled={props.disabled}
        endIcon={props.endComponent}
        href={props.href}
        loading={props.loading}
        loadingPosition={props.startIcon ? "start" : undefined}
        onClick={props.onClick}
        onSubmit={props.onSubmit}
        ref={ref}
        startIcon={renderIconIfExists({
          icon: props.startIcon,
          size: props.size,
          showBadge: props.showBadge,
          badgeConfig: props.badgeConfig,
        })}
        target={props.target}
        type={props.type}
      >
        {props.children}
      </StyledButton>
    </StyledBadge>
  ),
);

export { BaseButton };
