import { ComponentProps, FC } from "react";
import styled from "styled-components";
import { Pulse } from "styled-spinkit";

import { theme } from "../theme-provider";

import { FullscreenContainer } from "./full-screen-container";

type FullScreenContainerProps = ComponentProps<typeof FullscreenContainer>;

type FullScreenLoadingIndicatorProps = {
  as?: FullScreenContainerProps["as"];
  className?: string;
  indicatorSize?: number;
  standalone?: boolean;
  height?: number;
  ["data-testid"]?: string;
};

const StyledLoadingIndicator = styled(Pulse)`
  margin: 0;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
`;

type LoadingIndicatorProps = {
  indicatorSize?: number;
};

export const LoadingIndicator: FC<LoadingIndicatorProps> = (props) => (
  <StyledLoadingIndicator
    color={theme.palette.info.mediumLight}
    size={props.indicatorSize || 40}
  />
);

export const FullScreenLoadingIndicator: FC<FullScreenLoadingIndicatorProps> = (
  props,
) => (
  <FullscreenContainer
    as={props.as}
    className={props.className}
    data-testid={props["data-testid"]}
    height={props.height}
    standalone={props.standalone}
  >
    <LoadingIndicator indicatorSize={props.indicatorSize} />
  </FullscreenContainer>
);
