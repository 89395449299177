"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FontStyle;
(function (FontStyle) {
    FontStyle["Italic"] = "italic";
    FontStyle["Oblique"] = "oblique";
    FontStyle["Normal"] = "normal";
    FontStyle["Inherit"] = "inherit";
})(FontStyle = exports.FontStyle || (exports.FontStyle = {}));
var FontWeight;
(function (FontWeight) {
    FontWeight["Hairline"] = "100";
    FontWeight["ExtraLight"] = "200";
    FontWeight["Light"] = "300";
    FontWeight["Normal"] = "400";
    FontWeight["Medium"] = "500";
    FontWeight["SemiBold"] = "600";
    FontWeight["Bold"] = "700";
    FontWeight["ExtraBold"] = "800";
    FontWeight["Heavy"] = "900";
    FontWeight["Inherit"] = "inherit";
})(FontWeight = exports.FontWeight || (exports.FontWeight = {}));
