import URLSearchParams from "@ungap/url-search-params";

import { config } from "@/utils/config";

const appendSearchParameters = (url: URL, data: Record<string, unknown>) =>
  Object.assign(url, {
    search: new URLSearchParams(data as Record<string, string>).toString(),
  });

const appendSearchParametersIfNeeded = (
  url: URL,
  data?: Record<string, unknown>,
) => (data ? appendSearchParameters(url, data) : url);

const createUrl = (path: string) => new URL(`${config.apiUrl}${path}`);

const getEndpointUrl = (path: string, data?: Record<string, unknown>): string =>
  appendSearchParametersIfNeeded(createUrl(path), data).toString();

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default getEndpointUrl;
