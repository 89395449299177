import { Params, useParams } from "react-router-dom";

// react-router-dom useParams returns "string | undefined" as possible types
// however, as the route has loaded, we're certain that the key must exist.
// Discussion: https://github.com/remix-run/react-router/issues/8200
type NonNullableKeys<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
};

// Transform the path to params as a string literals list
// example: /root/:id/:car => "id | car"
type PathParamsLiteral<S extends string | Record<string, string | undefined>> =
  S extends `:${infer T}/${infer U}` | `/:${infer T}/${infer U}`
    ? T | PathParamsLiteral<U>
    : // eslint-disable-next-line @typescript-eslint/no-unused-vars
    S extends `${infer _T}/${infer U}`
    ? PathParamsLiteral<U>
    : S extends `:${infer T}` | `/:${infer T}`
    ? T
    : S extends Record<string, string | undefined>
    ? Readonly<Params<string>>
    : never;

export const useRouteParams = <
  RoutePath extends string | Record<string, string | undefined>,
>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _path?: RoutePath,
): NonNullableKeys<typeof params> => {
  const params = useParams<PathParamsLiteral<RoutePath>>();
  return params as NonNullableKeys<typeof params>;
};
