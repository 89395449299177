import EntitlementsApi from '../api/EntitlementsApi';
import { LoggerService } from './loggerService';
import { EntitlementCheckResult } from '@stigg/api-client-js/src/generated/sdk';

export class EntitlementCheckReportingService {
  constructor(
    private readonly entitlementsApi: EntitlementsApi,
    private readonly customerId: string,
    private readonly loggerService: LoggerService,
    private readonly resourceId?: string,
  ) {}

  reportEntitlementCheckRequested(featureId: string, entitlement: EntitlementCheckResult, requestedUsage?: number) {
    this.entitlementsApi
      .reportEntitlementRequested(featureId, this.customerId, entitlement, this.resourceId, requestedUsage)
      .then(
        () => {
          this.loggerService.debug('Successfully reported entitlement check requested');
        },
        // Swallow errors for this mutation
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
      );
  }
}
