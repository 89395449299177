import { FC } from "react";
import { Navigate, Route, Routes } from "react-router";
import styled from "styled-components";

import { routePaths } from "@/route-path";
import { FullScreenLoadingIndicator } from "@/components/loading-indicator";

import { useAdminPageCurrentUserQuery } from "./hooks/admin-page-current-user.generated";

import { Companies } from "./routes/companies";
import { Organizations } from "./routes/organizations";

const StyledFullScreenLoadingIndicator = styled(FullScreenLoadingIndicator)`
  height: 100vh;
`;

const useIsAdmin = ():
  | { loading: true }
  | { loading: false; value: boolean } => {
  const query = useAdminPageCurrentUserQuery({ fetchPolicy: "cache-first" });

  if (query.loading) {
    return { loading: true };
  }

  return { loading: false, value: Boolean(query.data?.currentUser?.isAdmin) };
};

export const AdminPage: FC = () => {
  const isAdmin = useIsAdmin();

  if (isAdmin.loading) {
    return <StyledFullScreenLoadingIndicator indicatorSize={120} />;
  }

  if (!isAdmin.value) {
    return <Navigate to={routePaths.notFound} />;
  }

  return (
    <Routes>
      <Route
        element={<Organizations />}
        path={routePaths.admin.organizations}
      />
      <Route element={<Companies />} path={routePaths.admin.companies} />
      <Route element={<Navigate to={routePaths.admin.companies} />} path="/" />
    </Routes>
  );
};
