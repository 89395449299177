import { createTheme } from "@mui/material/styles";
import { DefaultTheme } from "styled-components";

import {
  FontVariant,
  HorizontalAlignment,
  StiggTheme,
  TextAlignment,
} from "@stigg/react-sdk";

import { toRem } from "@/utils/styled-components";

export const theme: DefaultTheme = createTheme({
  components: {
    MuiPaper: {
      defaultProps: {
        style: { background: "#FFFFFF" },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: "#FFFFFFB3",
          color: "#8E8E92",
        },
      },
    },
    MuiList: {
      defaultProps: {
        style: { background: "#FFFFFF" },
      },
    },
  },
  zIndex: {
    modal: 2000,
    tooltip: 3000,
    snackbar: 4000,
  },
  palette: {
    primary: {
      light: "#E8E9FF",
      main: "#2652CD",
      dark: "#0036AB",
      ultraLight: "#F8F8FF",
      ultraDark: "#0F1D30",
    },
    secondary: {
      light: "#E4F8ED",
      main: "#0ACF83",
      dark: "#009147",
    },
    neutral: {
      light: "#FAFAFA",
      mediumLight: "#F0F0F0",
      main: "#EBE7E6",
      dark: "#8E8E92",
    },
    error: {
      light: "#FFEAEC",
      main: "#D81818",
      dark: "#BD0000",
    },
    warning: {
      light: "#FEF2E0",
      main: "#F79401",
      dark: "#E66900",
    },
    info: {
      main: "#3F5372",
      mediumLight: "#657696",
    },
    alert: {
      light: "#FEF2E0",
      main: "#F69401",
      dark: "#E66900",
    },
    success: {
      light: "#E4F8ED",
      main: "#0ACF83",
      dark: "#009147",
    },
    text: {
      primary: "#0F1D30",
      secondary: "#8E8E92",
      disabled: "#CCCCCC",
    },
    divider: "#EBE7E6",
    background: {
      default: "#FFFFFF",
      paper: "#F8F9FA",
    },
  },
  spacing: (factor: number) => toRem(factor * 6),
  typography: {
    fontFamilies: {
      neuehaas: "NeueHaasUnicaPro-Regular",
      neuehaasBold: "NeueHaasUnicaPro-Bold",
      helvetica: "Helvetica Neue",
      roboto: "Roboto Mono",
    },
    fontFamily: [
      "NeueHaasUnicaPro-Regular",
      "Roboto",
      '"Helvetica Neue"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      "NeueHaasUnicaPro-Bold",
    ].join(","),
    h1: {
      fontSize: "2.25rem",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "1.125rem",
      fontWeight: "bold",
    },
    h4: {
      fontSize: "1.063rem",
    },
    h5: {
      fontSize: "1rem",
    },
    h6: {
      fontSize: "0.938rem",
    },
    subtitle1: {
      fontSize: "1.25rem",
    },
    body1: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: "bold",
    },
    caption: {
      fontSize: "0.75rem",
    },
    overline: {
      fontSize: "0.75rem",
    },
    code: {
      fontSize: "0.75rem",
      fontWeight: "bold",
    },
  },
  shape: {
    borderRadius: 3,
  },
});

export const stiggTheme: StiggTheme = {
  palette: {
    primary: theme.palette.primary.main,
    primaryDark: theme.palette.primary.dark,
    primaryLight: theme.palette.primary.light,
    backgroundPaper: theme.palette.background.default,
    backgroundHighlight: theme.palette.background.paper,
    backgroundSection: theme.palette.background.paper,
    backgroundButton: theme.palette.background.default,
    outlinedBorder: theme.palette.divider,
    outlinedRestingBorder: theme.palette.primary.main,
    outlinedHoverBackground: theme.palette.primary.light,
    switchBorder: theme.palette.primary.main,
    switchFill: theme.palette.primary.light,
    switchHandle: theme.palette.primary.main,
    warning: theme.palette.warning.main,
    error: theme.palette.error.main,
    errorDark: theme.palette.error.dark,
    white: theme.palette.background.default,
    success: theme.palette.success.main,
    successDark: theme.palette.success.dark,
    text: {
      ...theme.palette.text,
      tertiary: "red",
    },
  },
  layout: {
    planMinWidth: toRem(300),
    planMaxWidth: toRem(300),
    planMargin: toRem(24),
    planPadding: toRem(24),
    ctaAlignment: "center" as HorizontalAlignment,
    headerAlignment: "center" as HorizontalAlignment,
    descriptionAlignment: "center" as TextAlignment,
    descriptionMinHeight: toRem(24),
    switchBottomSpacing: toRem(24),
  },
  shadows: {
    light: `0px 3px 9px 0px ${theme.palette.primary.light}`,
    popover: `0 0 ${toRem(12)} rgba(0, 0, 0, 0.1)`,
  },
  border: {
    radius: "3px",
  },
  typography: {
    fontFamily: "NeueHaasUnicaPro-Regular",
    h1: theme.typography.h1 as FontVariant,
    h2: theme.typography.h2 as FontVariant,
    h3: theme.typography.h3 as FontVariant,
    body: theme.typography.body1 as FontVariant,
    caption: {
      fontSize: theme.typography.caption.fontSize as string,
      fontWeight: "normal",
    },
  },
};
