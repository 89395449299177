"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("./types");
var TextType;
(function (TextType) {
    TextType[TextType["Body"] = 0] = "Body";
    TextType[TextType["Heading"] = 1] = "Heading";
    TextType[TextType["Span"] = 2] = "Span";
    TextType[TextType["Link"] = 3] = "Link";
})(TextType = exports.TextType || (exports.TextType = {}));
exports.defaultTheme = {
    fontSizes: ["2.369rem", "1.777rem", "1.333rem", "1rem", "0.750rem", "10px"],
    bodyFontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    bodySize: 4,
    bodyWeight: types_1.FontWeight.Normal,
    bodyStyle: types_1.FontStyle.Normal,
    bodyColor: "#000000",
    bodyLineHeight: 1.4,
    headingFontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    headingSize: 1,
    headingWeight: types_1.FontWeight.Bold,
    headingStyle: types_1.FontStyle.Normal,
    headingColor: "#000000",
    headingLineHeight: 1.2,
    extra: {}
};
exports.getTheme = (props) => (Object.assign(Object.assign({}, exports.defaultTheme), props.theme.typography));
exports.getDefault = (type, body, heading, span, link = span) => {
    switch (type) {
        case TextType.Body:
            return body;
        case TextType.Heading:
            return heading;
        case TextType.Span:
            return span;
        case TextType.Link:
            return link;
    }
};
exports.getFontFamily = (type) => props => {
    const { bodyFontFamily, headingFontFamily } = exports.getTheme(props);
    const defaultFontFamily = exports.getDefault(type, bodyFontFamily, headingFontFamily, "inherit");
    return defaultFontFamily;
};
exports.getFontSize = (type) => props => {
    const { fontSizes, bodySize, headingSize } = exports.getTheme(props);
    const defaultSize = exports.getDefault(type, bodySize, headingSize, "inherit");
    if (props.level === "inherit" || (!props.level && defaultSize === "inherit"))
        return "inherit";
    if (type === TextType.Heading && props.displayLevel === "inherit")
        return "inherit";
    if (type === TextType.Heading && !!props.displayLevel)
        return fontSizes[props.displayLevel - 1];
    return fontSizes[(props.level || defaultSize) - 1];
};
exports.getFontWeight = (type) => props => {
    const { bodyWeight, headingWeight } = exports.getTheme(props);
    const defaultWeight = exports.getDefault(type, bodyWeight, headingWeight, types_1.FontWeight.Inherit);
    return props.fontWeight || defaultWeight;
};
exports.getFontStyle = (type) => props => {
    const { bodyStyle, headingStyle } = exports.getTheme(props);
    const defaultStyle = exports.getDefault(type, bodyStyle, headingStyle, types_1.FontStyle.Inherit);
    return props.fontStyle || defaultStyle;
};
exports.getFontColor = (type) => props => {
    const { bodyColor, headingColor } = exports.getTheme(props);
    const defaultColor = exports.getDefault(type, bodyColor, headingColor, "currentcolor");
    return props.color || defaultColor;
};
exports.getLineHeight = (type) => props => {
    const { bodyLineHeight, headingLineHeight } = exports.getTheme(props);
    const defaultLineHeight = exports.getDefault(type, bodyLineHeight, headingLineHeight, "inherit");
    return props.lineHeight || defaultLineHeight;
};
exports.getExtras = (type) => props => {
    const { extra } = exports.getTheme(props);
    return (exports.getDefault(type, extra.body, extra.heading, extra.span, extra.link) || ``);
};
