type ApiSucceedResponse<ApiResult> = {
  data: ApiResult;
};

type ApiErrorResponse = {
  error: string;
};

type ApiResponse<ApiResult> = ApiErrorResponse | ApiSucceedResponse<ApiResult>;

const getResult = <TApiResult>(apiResponse: ApiResponse<TApiResult>) => {
  if ("error" in apiResponse) {
    throw new Error(apiResponse.error);
  }

  return apiResponse.data;
};

const getResultFrom = <TApiResult>(
  apiResponsePromise: Promise<ApiResponse<TApiResult>>,
): Promise<TApiResult> => apiResponsePromise.then(getResult);

export { getResultFrom };
