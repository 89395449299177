import { FC, forwardRef } from "react";
import styled from "styled-components";

import { toRem } from "@/utils/styled-components";

import { BaseButton, BaseButtonProps } from "./base-button";
import {
  getVariantCss,
  ColorPaletteType,
  StyledBaseButtonProps,
} from "./get-variant-css";

export type SecondaryButtonProps = BaseButtonProps & {
  variant?: "text" | "outlined";
};

const colorPalette: ColorPaletteType = (props) => ({
  default: props.theme.palette.primary.main,
  hover: props.theme.palette.primary.dark,
  active: props.theme.palette.primary.dark,
  disabled: props.theme.palette.primary.light,
  textColor: props.theme.palette.text.secondary,
  disabledTextColor: props.theme.palette.text.disabled,
  hoverTextColor: props.theme.palette.text.primary,
  hoverTextBackgroundColor: props.theme.palette.primary.light,
  activeTextBackgroundColor: props.theme.palette.primary.light,
});

const [getEnabledButtonCss, getDisabledButtonCss] = getVariantCss(colorPalette);

const StyledBaseButton = styled(BaseButton)<StyledBaseButtonProps>`
  border: ${toRem(1)} solid transparent !important;
  ${getEnabledButtonCss}
  ${getDisabledButtonCss}
`;

/**
 *
 * @param {Object} block Set to make button block and full width
 * @param {Object} size small | medium | large
 * @param {Object} variant outlined | text
 *
 */

const SecondaryButton: FC<SecondaryButtonProps> = forwardRef(
  (props, ref): JSX.Element => (
    <StyledBaseButton
      $variant={props.variant}
      ariaLabel={props.ariaLabel}
      block={props.block}
      className={props.className}
      data-cy={props["data-cy"]}
      disabled={props.disabled}
      href={props.href}
      onClick={props.onClick}
      onSubmit={props.onSubmit}
      ref={ref}
      size={props.size}
      startIcon={props.startIcon}
      target={props.target}
      type={props.type}
    >
      {props.children}
    </StyledBaseButton>
  ),
);

export { SecondaryButton };
