import { Outlet, useNavigate, useOutletContext } from "react-router-dom";

import { useCallback, useState } from "react";

import { nonNullable } from "@basis-org/shared";

import { useSegment } from "@/utils/segment-io/segmentIo";

import {
  IntegrationProviderName,
  OnboardingIntegrationStatus,
} from "@/graphql-types.generated";

import { useRouteParams } from "@/hooks/use-route-params";

import { routePaths } from "@/route-path";

import {
  OnboardingBySlugQuery,
  useOnboardingBySlugQuery,
} from "../hooks/onboarding-record-by-slug.generated";

import { useCreateOnboardingIntegrationMutation } from "../hooks/create-onboarding-integration.generated";

import { buildSelfOnboardingRoute } from "../utils/build-onboarding-route";

import { useSelfOnboardingFlow } from "./self-onboarding-outlet-with-context";

export type SelfOnboardingFlowIntegrationsContext = {
  handleCreateIntegration: () => Promise<void>;
  onSelectIntegrationProvider: (
    value: IntegrationProviderName | undefined,
  ) => void;
  isLoadingOnboardingRecord: boolean;
  isLoadingIntegrationsLink: boolean;
  onboardingRecord: OnboardingBySlugQuery["onboardingBySlug"];
  selectedIntegrationProvider: IntegrationProviderName | undefined;
  totalPages: number;
};

const requireRetryStatus = new Set([
  OnboardingIntegrationStatus.PendingAuth,
  OnboardingIntegrationStatus.Deauthorized,
]);

const totalPages = 5;

export const SelfOnboardingIntegrationOutletWithContext = (): JSX.Element => {
  const { track } = useSegment();

  const assignLocation = window.location.assign.bind(window.location);

  const navigate = useNavigate();

  const { slug } = useRouteParams(routePaths.selfOnboarding.integrations.base);

  const onboardingFlowContext = useSelfOnboardingFlow();

  const [integrationProvider, setIntegrationsProvider] =
    useState<IntegrationProviderName>();

  const [loadingIntegrationsLink, setLoadingIntegrationsLink] = useState(false);

  const [createOnboardingIntegration] =
    useCreateOnboardingIntegrationMutation();

  const { data, loading } = useOnboardingBySlugQuery({
    variables: {
      slug,
    },
    onCompleted: (onboardingBySlugQueryData) => {
      if (!onboardingBySlugQueryData.onboardingBySlug) return;

      const onboardingData = onboardingBySlugQueryData.onboardingBySlug;

      if (onboardingData.integrationProviderName && onboardingData.company) {
        const provider = onboardingBySlugQueryData.onboardingBySlug
          .integrationProviderName as IntegrationProviderName;

        setIntegrationsProvider(provider);

        onboardingFlowContext.updateOnboardingData({
          slug,
          companyId: onboardingData.company.id,
          defaultScenarioId: onboardingData.company.defaultScenarioId || "",
        });
      }
    },
    onError: () => {
      reportError("Expired link or invalid URL");

      navigate(buildSelfOnboardingRoute(routePaths.selfOnboarding.expired));
    },
  });

  const getIntegrationAuthLink = useCallback(
    async (integrationProviderName: IntegrationProviderName) => {
      const result = await createOnboardingIntegration({
        variables: { slug, integrationProviderName },
      });

      const redirectTo =
        result.data?.createOnboardingIntegration.integrationAuthLink ||
        undefined;

      return { redirectTo };
    },
    [createOnboardingIntegration, slug],
  );

  const handleCreateIntegration = useCallback(async () => {
    if (!data) return;
    const integrationStatus =
      data.onboardingBySlug.integrationStatus ||
      OnboardingIntegrationStatus.Unlinked;

    const retryRequired = requireRetryStatus.has(integrationStatus);

    if (retryRequired) {
      if (!data.onboardingBySlug.integrationAuthLink) {
        return navigate(routePaths.systemError);
      }

      return assignLocation(data.onboardingBySlug.integrationAuthLink);
    }

    if (nonNullable(integrationProvider)) {
      setLoadingIntegrationsLink(true);

      const { redirectTo } = await getIntegrationAuthLink(integrationProvider);

      if (!redirectTo) {
        return navigate(routePaths.systemError);
      }

      track("Completed Onboarding Step - Integration process started", {
        stepNum: 3,
        stepName: "Integration process started",
      });

      assignLocation(redirectTo);
    }
  }, [
    data,
    integrationProvider,
    assignLocation,
    navigate,
    getIntegrationAuthLink,
    track,
  ]);

  const onSelectIntegrationProvider = (provider: IntegrationProviderName) => {
    setIntegrationsProvider(provider);
  };

  return (
    <Outlet
      context={{
        handleCreateIntegration,
        onSelectIntegrationProvider,
        selectedIntegrationProvider: integrationProvider,
        isLoadingOnboardingRecord: loading || !data,
        isLoadingIntegrationsLink: loadingIntegrationsLink,
        onboardingRecord: data?.onboardingBySlug,
        totalPages,
      }}
    />
  );
};

export const useSelfOnboardingIntegrationsFlow =
  (): SelfOnboardingFlowIntegrationsContext =>
    useOutletContext<SelfOnboardingFlowIntegrationsContext>();
