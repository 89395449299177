// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference types="@welldone-software/why-did-you-render" />

// eslint-disable-next-line import/no-extraneous-dependencies
import { WhyDidYouRenderComponentMember } from "@welldone-software/why-did-you-render";
import React from "react";

import { config } from "./utils/config";

if (config.whyDidYouRender) {
  // eslint-disable-next-line import/no-extraneous-dependencies, @typescript-eslint/no-var-requires, global-require, unicorn/prefer-module
  const whyDidYouRender = require("@welldone-software/why-did-you-render");

  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackHooks: true,
    exclude: [/Comp$/, /^TabGuardCompRef$/],
  });
}

declare module "react" {
  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-unused-vars
  interface VoidFunctionComponent<P = {}> {
    whyDidYouRender?: WhyDidYouRenderComponentMember;
  }
}
