import "./wdyr";
import "shim-keyboard-event-key";
import "draft-js/dist/Draft.css";
import "@/utils/init-ag-grid";

import { createRoot } from "react-dom/client";

import { App } from "./app";

const container = document.querySelector("#root");
const root = createRoot(container!);
root.render(<App />);
