export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateFor: { input: string; output: string };
  DateTime: { input: string; output: string };
  Decimal: { input: string; output: string };
  JSON: { input: never; output: never };
  Upload: { input: File; output: File };
  Void: { input: never; output: never };
};

/** A unit table line item. */
export type Account = {
  __typename?: "Account";
  companyId: Scalars["String"]["output"];
  desiredVarianceDirection: VarianceDirection;
  directAncestorLineItems?: Maybe<Array<Account>>;
  displayName: Scalars["String"]["output"];
  figureType: FigureType;
  figures: Array<Figure>;
  id: Scalars["ID"]["output"];
  integrationAccount?: Maybe<IntegrationAccount>;
  integrationAccountMappings: Array<UnitTableLineItemIntegrationAccount>;
  invalidFigures: Array<Figure>;
  isMetric: Scalars["Boolean"]["output"];
  order: Scalars["String"]["output"];
  periodValueType: AccountPeriodValueType;
  permissions: AccountPermissions;
  references?: Maybe<Array<Account>>;
  scenarioId: Scalars["String"]["output"];
  unitTable: UnitTable;
  unitTableId: Scalars["String"]["output"];
  unitTableLineItemDimensions: Array<UnitTableLineItemDimension>;
};

/** A unit table line item. */
export type AccountFiguresArgs = {
  where?: InputMaybe<FigureDateRangeFilterArgs>;
};

export enum AccountPeriodValueType {
  AverageOfPeriod = "AverageOfPeriod",
  EndOfPeriod = "EndOfPeriod",
  None = "None",
  PeriodToDate = "PeriodToDate",
  StartOfPeriod = "StartOfPeriod",
  SumOfPeriod = "SumOfPeriod",
}

/** Permissions on Accounts. */
export type AccountPermissions = {
  __typename?: "AccountPermissions";
  canRead: Scalars["Boolean"]["output"];
  /** User can reference line item in formulas */
  canReference: Scalars["Boolean"]["output"];
  canReorder: Scalars["Boolean"]["output"];
  canWrite: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
};

/** Account updated message types to subscribe to */
export enum AccountUpdatedChangedField {
  Figures = "figures",
  General = "general",
}

/** Configuration information for accounting hierarchy */
export type AccountingHierarchyConfiguration = {
  __typename?: "AccountingHierarchyConfiguration";
  codatAccountingConfigs: Array<CodatAccountingConfig>;
  id: Scalars["ID"]["output"];
  trackingCategories: Array<TrackingCategory>;
};

export type BasisUser = {
  __typename?: "BasisUser";
  basisUserCompanies: Array<BasisUserCompany>;
  basisUserCompany?: Maybe<BasisUserCompany>;
  basisUserState?: Maybe<BasisUserState>;
  email: Scalars["String"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isAdmin?: Maybe<Scalars["Boolean"]["output"]>;
  lastLoggedIn?: Maybe<Scalars["DateTime"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
};

export type BasisUserBasisUserCompanyArgs = {
  companyId: Scalars["ID"]["input"];
};

export type BasisUserBasisUserStateArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

/** Basis user access rights */
export enum BasisUserAccessRightType {
  Edit = "EDIT",
  None = "NONE",
  View = "VIEW",
}

/** Aggregate user access rights for entities */
export type BasisUserAccessRights = {
  __typename?: "BasisUserAccessRights";
  dashboardAccessRights: Array<DashboardAccessRight>;
  id: Scalars["ID"]["output"];
  unitTableAccessRights: Array<UnitTableAccessRight>;
  workbookAccessRights: Array<WorkbookAccessRight>;
};

export type BasisUserCompany = {
  __typename?: "BasisUserCompany";
  basisUser: BasisUser;
  basisUserDashboardPermissions: Array<BasisUserDashboardPermission>;
  basisUserId: Scalars["String"]["output"];
  basisUserScenarios: Array<BasisUserScenario>;
  company: Company;
  companyAccessCount: Scalars["Int"]["output"];
  companyId: Scalars["String"]["output"];
  companyRole: BasisUserCompanyRoleType;
  countAccessScenarios: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  pending?: Maybe<Scalars["Boolean"]["output"]>;
};

export type BasisUserCompanyBasisUserScenariosArgs = {
  where?: InputMaybe<BasisUserCompanyBasisUserScenariosFilter>;
};

export type BasisUserCompanyBasisUserScenariosFilter = {
  scenarioId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** The Company Role set on the Basis user. */
export enum BasisUserCompanyRoleType {
  BasisCompanyAdmin = "BASIS_COMPANY_ADMIN",
  CompanyAdmin = "COMPANY_ADMIN",
  CompanyMember = "COMPANY_MEMBER",
  NoAccess = "NO_ACCESS",
}

export type BasisUserDashboardPermission = {
  __typename?: "BasisUserDashboardPermission";
  basisUserCompany: BasisUserCompany;
  dashboard: Dashboard;
  id: Scalars["ID"]["output"];
  permissions: DashboardPermission;
};

export type BasisUserScenario = {
  __typename?: "BasisUserScenario";
  accessRights: BasisUserAccessRights;
  companyId: Scalars["String"]["output"];
  countScenarioAccessRights: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  lastAccessedAt?: Maybe<Scalars["DateTime"]["output"]>;
  role: BasisUserScenarioRoleType;
  scenario: Scenario;
  scenarioId: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
};

/** The Scenario role set for a Basis user. */
export enum BasisUserScenarioRoleType {
  Editor = "EDITOR",
  Guest = "GUEST",
  Member = "MEMBER",
  Viewer = "VIEWER",
}

export type BasisUserState = {
  __typename?: "BasisUserState";
  basisUserId: Scalars["String"]["output"];
  companyId: Scalars["String"]["output"];
  dashboardEndDate?: Maybe<Scalars["DateTime"]["output"]>;
  dashboardFocusMonth?: Maybe<Scalars["String"]["output"]>;
  dashboardIsFocusMonthRelative?: Maybe<Scalars["Boolean"]["output"]>;
  dashboardStartDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  scenarioId: Scalars["String"]["output"];
};

export type BudgetMonthStatus = {
  __typename?: "BudgetMonthStatus";
  dateFor: Scalars["DateTime"]["output"];
  dateForAsDateString: Scalars["String"]["output"];
  dateForDisplay: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isRolledOver: Scalars["Boolean"]["output"];
};

export type Chart = {
  __typename?: "Chart";
  chartDoc?: Maybe<ChartDoc>;
  chartDocId?: Maybe<Scalars["ID"]["output"]>;
  chartSize?: Maybe<ChartSize>;
  chartTopics: Array<ChartTopic>;
  chartType: ChartType;
  companyId: Scalars["String"]["output"];
  comparisonType?: Maybe<ComparisonType>;
  dashboard: Dashboard;
  dashboardId: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  outputTable?: Maybe<OutputTable>;
  outputTableId?: Maybe<Scalars["String"]["output"]>;
  rank: Scalars["Int"]["output"];
  scenarioId: Scalars["String"]["output"];
  shouldDisplayActualsIfAvailable?: Maybe<Scalars["Boolean"]["output"]>;
  shouldDisplayHistoricalForecasts?: Maybe<Scalars["Boolean"]["output"]>;
  showDescription: Scalars["Boolean"]["output"];
  timeSeriesGroupingType?: Maybe<TimeSeriesGroupingType>;
};

export type ChartDoc = {
  __typename?: "ChartDoc";
  chartId: Scalars["String"]["output"];
  companyId: Scalars["String"]["output"];
  content?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastEditorId?: Maybe<Scalars["String"]["output"]>;
  lastEditorName?: Maybe<Scalars["String"]["output"]>;
  lastModifiedAt?: Maybe<Scalars["DateTime"]["output"]>;
  scenarioId: Scalars["String"]["output"];
};

export type ChartDocFileUpload = {
  __typename?: "ChartDocFileUpload";
  path: Scalars["String"]["output"];
};

export enum ChartSize {
  Full = "full",
  Half = "half",
  Quarter = "quarter",
  Third = "third",
}

export type ChartTopic = {
  __typename?: "ChartTopic";
  /** @deprecated Use `accounts` instead */
  account: Account;
  /** @deprecated Use `accounts` instead */
  accountId: Scalars["String"]["output"];
  accounts: Array<Account>;
  companyId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  periodValue?: Maybe<ChartTopicPeriodValue>;
  scenarioId: Scalars["String"]["output"];
};

export type ChartTopicAccountsArgs = {
  includeScenarioLinks: Array<Scalars["ID"]["input"]>;
};

/** Configuration options for Chart Topics */
export type ChartTopicOptions = {
  lineItemId: Scalars["ID"]["input"];
  periodValue: ChartTopicPeriodValue;
};

export enum ChartTopicPeriodValue {
  AverageOfPeriod = "AverageOfPeriod",
  EndOfPeriod = "EndOfPeriod",
  PeriodToDate = "PeriodToDate",
  StartOfPeriod = "StartOfPeriod",
  SumOfPeriod = "SumOfPeriod",
}

export enum ChartType {
  Bar = "bar",
  Callout = "callout",
  ChartDoc = "chartDoc",
  Line = "line",
  OutputTable = "output_table",
  Pie = "pie",
  Scatter = "scatter",
  Section = "section",
  StackedBar = "stackedBar",
}

export type CloudModelingJob = {
  __typename?: "CloudModelingJob";
  companyId: Scalars["String"]["output"];
  erroredAt?: Maybe<Scalars["DateTime"]["output"]>;
  finishedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  queuedAt: Scalars["DateTime"]["output"];
  ranAt?: Maybe<Scalars["DateTime"]["output"]>;
  retriedAt?: Maybe<Scalars["DateTime"]["output"]>;
  scenarioId: Scalars["String"]["output"];
  status: CloudModelingJobStatus;
};

export enum CloudModelingJobStatus {
  Errored = "ERRORED",
  Finished = "FINISHED",
  Queued = "QUEUED",
  RetryQueued = "RETRY_QUEUED",
  Running = "RUNNING",
}

/** Get cloud modeling jobs */
export type CloudModelingJobsInput = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

/** Codat accounting config group */
export type CodatAccountingConfig = {
  __typename?: "CodatAccountingConfig";
  codatConfigClassAccountingHierarchies: Array<CodatAccountingHierarchy>;
  group: CodatAccountingGroupType;
  id: Scalars["ID"]["output"];
  isClassBased: Scalars["Boolean"]["output"];
};

/** The type of codat accounting group */
export enum CodatAccountingGroupType {
  CostOfSales = "Cost_Of_Sales",
  NonOperatingExpenses = "Non_Operating_Expenses",
  NonOperatingIncome = "Non_Operating_Income",
  OperatingExpenses = "Operating_Expenses",
  OperatingIncome = "Operating_Income",
}

/** Accounting Config Hierarchy */
export type CodatAccountingHierarchy = {
  __typename?: "CodatAccountingHierarchy";
  depth: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  trackingCategoryId: Scalars["ID"]["output"];
};

export type Company = {
  __typename?: "Company";
  accountingIntegrationCount: Scalars["Int"]["output"];
  accountingIntegrationLimit: Scalars["Int"]["output"];
  aiCopilotRateLimitExceeded: Scalars["Boolean"]["output"];
  aiCopilotStatus: BasisAiCopilotStatus;
  companyIntegrations: Array<CompanyIntegration>;
  createdAt: Scalars["DateTime"]["output"];
  crmIntegrationCount: Scalars["Int"]["output"];
  crmIntegrationLimit: Scalars["Int"]["output"];
  defaultScenarioId?: Maybe<Scalars["String"]["output"]>;
  fiscalYearStartMonth: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  isRolloverPaused: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  onboarding?: Maybe<Onboarding>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars["String"]["output"]>;
  payrollIntegrationCount: Scalars["Int"]["output"];
  payrollIntegrationLimit: Scalars["Int"]["output"];
  permissions: CompanyPermission;
  planDateRange: PlanDateRange;
  rolloverDay: Scalars["Int"]["output"];
  rolloverMonthDelay: Scalars["Int"]["output"];
  scenarios: Array<Scenario>;
  scheduledForDeletion: Scalars["Boolean"]["output"];
  users: Array<BasisUser>;
};

export type CompanyCompanyIntegrationsArgs = {
  where?: InputMaybe<CompanyCompanyIntegrationsFilter>;
};

export type CompanyCompanyIntegrationsFilter = {
  mapsToUnitTable: Scalars["ID"]["input"];
};

export type CompanyIntegration = {
  __typename?: "CompanyIntegration";
  accountingHierarchyConfiguration?: Maybe<AccountingHierarchyConfiguration>;
  authorizationStatus: CompanyIntegrationAuthorizationStatus;
  codatCompanyId?: Maybe<Scalars["String"]["output"]>;
  company: Company;
  companyId: Scalars["String"]["output"];
  crmConnectionId?: Maybe<Scalars["String"]["output"]>;
  hasClassSupport: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  integrationAccounts: Array<IntegrationAccount>;
  integrationProvider: IntegrationProvider;
  integrationProviderName: Scalars["String"]["output"];
  isPending: Scalars["Boolean"]["output"];
  lastSyncedAt?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  payrollConnectionId?: Maybe<Scalars["Int"]["output"]>;
  permissions: CompanyIntegrationPermission;
  redirectUrl: Scalars["String"]["output"];
  syncErrors: Array<SyncError>;
  unitTableCompanyIntegrations: Array<UnitTableCompanyIntegration>;
  variants: Array<IntegrationAccountVariant>;
};

export type CompanyIntegrationIntegrationAccountsArgs = {
  where?: InputMaybe<CompanyIntegrationIntegrationAccountsFilter>;
};

export type CompanyIntegrationUnitTableCompanyIntegrationsArgs = {
  scenarioId: Scalars["ID"]["input"];
};

export enum CompanyIntegrationAuthorizationStatus {
  Authorized = "Authorized",
  Deauthorized = "Deauthorized",
  Pending = "Pending",
}

export type CompanyIntegrationIntegrationAccountsFilter = {
  mapsToUnitTable: Scalars["ID"]["input"];
};

/** Permissions on Company Integration. */
export type CompanyIntegrationPermission = {
  __typename?: "CompanyIntegrationPermission";
  canDelete: Scalars["Boolean"]["output"];
  canRead: Scalars["Boolean"]["output"];
  canUpdate: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
};

/** Permissions on Company. */
export type CompanyPermission = {
  __typename?: "CompanyPermission";
  canCreateCompanyAccountingIntegration: Scalars["Boolean"]["output"];
  canCreateCompanyCrmIntegration: Scalars["Boolean"]["output"];
  canCreateCompanyPayrollIntegration: Scalars["Boolean"]["output"];
  canCreateScenarios: Scalars["Boolean"]["output"];
  canJoinAICopilotWaitlist: Scalars["Boolean"]["output"];
  canManageMembers: Scalars["Boolean"]["output"];
  canManagePlanConfiguration: Scalars["Boolean"]["output"];
  canRead: Scalars["Boolean"]["output"];
  canViewCompanySettingsPage: Scalars["Boolean"]["output"];
  canViewShareModal: Scalars["Boolean"]["output"];
  canWrite: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
};

export enum ComparisonType {
  ForecastVsActual = "ForecastVsActual",
  MonthOverMonth = "MonthOverMonth",
  NoComparison = "NoComparison",
  QuarterOverQuarter = "QuarterOverQuarter",
  YearOverYear = "YearOverYear",
  YearToDate = "YearToDate",
}

export type CsvUpload = {
  __typename?: "CsvUpload";
  isValid: Scalars["Boolean"]["output"];
  uploadWarnings: Array<Scalars["String"]["output"]>;
};

export type CsvValidationModel = {
  __typename?: "CsvValidationModel";
  isValid: Scalars["Boolean"]["output"];
  newLineItemCount: Scalars["Int"]["output"];
  updatedLineItemCount: Scalars["Int"]["output"];
  validationErrors: Array<ValidationError>;
};

export type Dashboard = {
  __typename?: "Dashboard";
  charts: Array<Chart>;
  company: Company;
  companyId: Scalars["String"]["output"];
  dashboardDoc?: Maybe<DashboardDoc>;
  dashboardScenarioComparisons: Array<DashboardScenarioComparison>;
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  permissions: DashboardPermission;
  scenarioId: Scalars["String"]["output"];
};

/** Dashboard access rights */
export type DashboardAccessRight = {
  __typename?: "DashboardAccessRight";
  accessRights: BasisUserAccessRightType;
  dashboardId: Scalars["ID"]["output"];
};

export type DashboardDoc = {
  __typename?: "DashboardDoc";
  companyId: Scalars["String"]["output"];
  content?: Maybe<Scalars["String"]["output"]>;
  dashboardId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastEditorId?: Maybe<Scalars["String"]["output"]>;
  lastEditorName?: Maybe<Scalars["String"]["output"]>;
  lastModifiedAt?: Maybe<Scalars["DateTime"]["output"]>;
  scenarioId: Scalars["String"]["output"];
};

export type DashboardDocFileUpload = {
  __typename?: "DashboardDocFileUpload";
  path: Scalars["String"]["output"];
};

/** Permissions on Dashboards. */
export type DashboardPermission = {
  __typename?: "DashboardPermission";
  canDelete: Scalars["Boolean"]["output"];
  canRead: Scalars["Boolean"]["output"];
  canUpdate: Scalars["Boolean"]["output"];
  canWriteCharts: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
};

export type DashboardScenarioComparison = {
  __typename?: "DashboardScenarioComparison";
  companyId: Scalars["String"]["output"];
  dashboardId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  position: Scalars["Int"]["output"];
  scenarioId: Scalars["String"]["output"];
  targetScenario: Scenario;
};

export type DateTimeFilterArgs = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  not?: InputMaybe<Scalars["DateTime"]["input"]>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
};

export type Dimension = {
  __typename?: "Dimension";
  companyId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isRolledUp: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  permissions: DimensionPermission;
  scenarioId: Scalars["String"]["output"];
  singleSelectValues: Array<Scalars["String"]["output"]>;
  type: DimensionType;
  unitTable: UnitTable;
  unitTableId: Scalars["String"]["output"];
};

export type DimensionCandidateLink = {
  __typename?: "DimensionCandidateLink";
  candidate: Dimension;
  link?: Maybe<Dimension>;
};

/** An entire output tables grouping rules */
export type DimensionGroupingInput = {
  dimensionId: Scalars["ID"]["input"];
  groupByOrder: Scalars["Int"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type DimensionLinkInput = {
  isHidden: Scalars["Boolean"]["input"];
  srcId: Scalars["ID"]["input"];
  srcUnitTableId: Scalars["ID"]["input"];
  targetIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

/** Permissions on Dimensions. */
export type DimensionPermission = {
  __typename?: "DimensionPermission";
  canRead: Scalars["Boolean"]["output"];
  canWrite: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
};

export type DimensionScenarioLink = {
  __typename?: "DimensionScenarioLink";
  id: Scalars["ID"]["output"];
  isHidden: Scalars["Boolean"]["output"];
  src: Dimension;
  targets: Array<Maybe<Dimension>>;
};

export type DimensionScenarioLinksForUnitTable = {
  __typename?: "DimensionScenarioLinksForUnitTable";
  id: Scalars["ID"]["output"];
  links: Array<DimensionScenarioLink>;
  unitTable: UnitTable;
};

export enum DimensionType {
  Boolean = "boolean",
  Date = "date",
  Monetary = "monetary",
  Number = "number",
  Percent = "percent",
  SingleSelect = "single_select",
  Text = "text",
}

export type Figure = {
  __typename?: "Figure";
  actual?: Maybe<Scalars["Decimal"]["output"]>;
  actualFormula: Scalars["String"]["output"];
  dateFor: Scalars["DateTime"]["output"];
  dateForAsDateString: Scalars["String"]["output"];
  /** @deprecated Use formulaErrorSymbol */
  formulaError?: Maybe<Scalars["String"]["output"]>;
  formulaErrorMessage?: Maybe<Scalars["String"]["output"]>;
  formulaErrorSymbol?: Maybe<Scalars["String"]["output"]>;
  formulaKey: FigureFormulaType;
  id: Scalars["ID"]["output"];
  isFresh: Scalars["Boolean"]["output"];
  isRolledOver: Scalars["Boolean"]["output"];
  isValid: Scalars["Boolean"]["output"];
  permissions: FigurePermissions;
  projected?: Maybe<Scalars["Decimal"]["output"]>;
  projectedFormula: Scalars["String"]["output"];
  valueKey: FigureValueType;
  variance?: Maybe<Scalars["Decimal"]["output"]>;
  variancePercent?: Maybe<Scalars["Decimal"]["output"]>;
};

export type FigureDateRangeFilterArgs = {
  /** ISO date string for the end of the end range */
  dateRangeEnd?: InputMaybe<Scalars["String"]["input"]>;
  /** ISO date string for the start of the date range */
  dateRangeStart?: InputMaybe<Scalars["String"]["input"]>;
};

/** Formula to be updated from a given figure */
export enum FigureFormulaToUpdate {
  Actual = "actual",
  Metric = "metric",
  Projected = "projected",
}

/** The figure field that contains the "active" formula */
export enum FigureFormulaType {
  ActualFormula = "actualFormula",
  ProjectedFormula = "projectedFormula",
}

export type FigureFormulaUpdate = {
  figureId: Scalars["String"]["input"];
  formulaKey: FigureFormulaToUpdate;
  newFormula: Scalars["String"]["input"];
};

/** Permissions on Figures. */
export type FigurePermissions = {
  __typename?: "FigurePermissions";
  canWrite: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
};

export enum FigureType {
  Monetary = "monetary",
  Number = "number",
  Percentage = "percentage",
}

/** The figure field that contains the "active" value */
export enum FigureValueType {
  Actual = "actual",
  Projected = "projected",
}

export type GetLinkCandidatesInput = {
  companyId: Scalars["ID"]["input"];
  srcScenarioId: Scalars["ID"]["input"];
  srcUnitTableId: Scalars["ID"]["input"];
  targetScenarioId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type GetLinksInput = {
  companyId: Scalars["ID"]["input"];
  pageId: Scalars["ID"]["input"];
  pageType: GetLinksInputPageType;
  showHidden: Scalars["Boolean"]["input"];
  srcScenarioId: Scalars["ID"]["input"];
  targetScenarioIds: Array<Scalars["ID"]["input"]>;
};

export enum GetLinksInputPageType {
  Dashboard = "dashboard",
  Workbook = "workbook",
}

export type GetUnitTableLinkCandidatesInput = {
  companyId: Scalars["ID"]["input"];
  srcScenarioId: Scalars["ID"]["input"];
  targetScenarioId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type IntegrationAccount = {
  __typename?: "IntegrationAccount";
  companyIntegration: CompanyIntegration;
  companyIntegrationId: Scalars["String"]["output"];
  groupPaths: Array<IntegrationAccountGroupPath>;
  hasTransactions: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  integrationDimensionValues: Array<IntegrationDimensionValue>;
  integrationTransactions: Array<IntegrationTransaction>;
  isCalculatedMetric: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  variantDisplayName: Scalars["String"]["output"];
};

export type IntegrationAccountIntegrationTransactionsArgs = {
  where?: InputMaybe<IntegrationAccountIntegrationTransactionsFilter>;
};

/** Integration Account nested grouping path for display purposes. */
export type IntegrationAccountGroupPath = {
  __typename?: "IntegrationAccountGroupPath";
  groupRef: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  value: Scalars["String"]["output"];
};

export type IntegrationAccountIntegrationTransactionsFilter = {
  date?: InputMaybe<DateTimeFilterArgs>;
};

/** A description of a line item variant associated with the company integration type. */
export type IntegrationAccountVariant = {
  __typename?: "IntegrationAccountVariant";
  displayName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
};

export type IntegrationDimensionValue = {
  __typename?: "IntegrationDimensionValue";
  companyId: Scalars["String"]["output"];
  dimensionName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  integrationAccountId: Scalars["String"]["output"];
  lastTransformedAt?: Maybe<Scalars["DateTime"]["output"]>;
  value: Scalars["String"]["output"];
};

export type IntegrationFigure = {
  __typename?: "IntegrationFigure";
  dateFor: Scalars["DateTime"]["output"];
  dateForAsDateString: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
};

/** An integration provider */
export type IntegrationProvider = {
  __typename?: "IntegrationProvider";
  color?: Maybe<Scalars["String"]["output"]>;
  displayName: Scalars["String"]["output"];
  iconUrl: Scalars["String"]["output"];
  id: IntegrationProviderName;
  imageUrl: Scalars["String"]["output"];
  integrationType: IntegrationType;
  /** @deprecated Use id instead */
  name: IntegrationProviderName;
};

/** The list of integration providers */
export enum IntegrationProviderName {
  "7Shifts" = "_7Shifts",
  Accelo = "accelo",
  Activecampaign = "activecampaign",
  AdpWorkforceNow = "adpWorkforceNow",
  Affinity = "affinity",
  Alexishr = "alexishr",
  AlteraPayroll = "alteraPayroll",
  AzureActiveDirectory = "azureActiveDirectory",
  Bamboohr = "bamboohr",
  Bizmerlinhr = "bizmerlinhr",
  Breathe = "breathe",
  Capsule = "capsule",
  CeridianDayforce = "ceridianDayforce",
  Charlie = "charlie",
  Charthop = "charthop",
  Clearbooks = "clearbooks",
  Close = "close",
  Copper = "copper",
  Deel = "deel",
  Exactnl = "exactnl",
  Factorial = "factorial",
  Freshbooks = "freshbooks",
  Freshteam = "freshteam",
  GoogleWorkspace = "googleWorkspace",
  Gusto = "gusto",
  Hibob = "hibob",
  HrCloud = "hrCloud",
  HrPartner = "hrPartner",
  Hubspot = "hubspot",
  Humaans = "humaans",
  Insightly = "insightly",
  Insperity = "insperity",
  Intellihr = "intellihr",
  Jumpcloud = "jumpcloud",
  Justworks = "justworks",
  Kashflow = "kashflow",
  Keap = "keap",
  Keka = "keka",
  Lano = "lano",
  Lucca = "lucca",
  MicrosoftDynamics365Sales = "microsoftDynamics365Sales",
  Microsoftdynamics365 = "microsoftdynamics365",
  Namely = "namely",
  Netsuite = "netsuite",
  Nmbrs = "nmbrs",
  Nutshell = "nutshell",
  Officient = "officient",
  Okta = "okta",
  Onelogin = "onelogin",
  Paycaptain = "paycaptain",
  Paychex = "paychex",
  Paycor = "paycor",
  Paylocity = "paylocity",
  Peoplehr = "peoplehr",
  Personio = "personio",
  Pingone = "pingone",
  Pipedrive = "pipedrive",
  Pipeliner = "pipeliner",
  Proliant = "proliant",
  Quickbooksdesktop = "quickbooksdesktop",
  Quickbooksonline = "quickbooksonline",
  Quickbooksonlinesandbox = "quickbooksonlinesandbox",
  Rippling = "rippling",
  Sage50 = "sage50",
  SageHr = "sageHr",
  Sageintacct = "sageintacct",
  Salesflare = "salesflare",
  Salesforce = "salesforce",
  Sandbox = "sandbox",
  SapSuccessfactors = "sapSuccessfactors",
  Sapling = "sapling",
  Sesame = "sesame",
  SquarePayroll = "squarePayroll",
  Sugarcrm = "sugarcrm",
  Teamleader = "teamleader",
  TeamworkCrm = "teamworkCrm",
  Trinet = "trinet",
  UkgDimensions = "ukgDimensions",
  UkgPro = "ukgPro",
  UkgReady = "ukgReady",
  Vtiger = "vtiger",
  Workday = "workday",
  Xero = "xero",
  ZendeskSell = "zendeskSell",
  Zenefits = "zenefits",
  ZohoBooks = "zohoBooks",
  ZohoCrm = "zohoCrm",
}

export type IntegrationTransaction = {
  __typename?: "IntegrationTransaction";
  date: Scalars["DateTime"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  value: Scalars["Decimal"]["output"];
};

/** The type of integration */
export enum IntegrationType {
  Accounting = "Accounting",
  Crm = "Crm",
  Payroll = "Payroll",
}

/** Article from Intercom */
export type IntercomArticle = {
  __typename?: "IntercomArticle";
  body?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
};

/** Article from Intercom */
export type IntercomHomepageArticles = {
  __typename?: "IntercomHomepageArticles";
  guideArticle?: Maybe<IntercomArticle>;
  id?: Maybe<Scalars["String"]["output"]>;
  newsArticle?: Maybe<IntercomArticle>;
};

export type LineItemCandidateLink = {
  __typename?: "LineItemCandidateLink";
  candidate: Account;
  link?: Maybe<Account>;
};

export type LineItemLinkInput = {
  isHidden: Scalars["Boolean"]["input"];
  srcId: Scalars["ID"]["input"];
  srcUnitTableId: Scalars["ID"]["input"];
  targetIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

export type LineItemScenarioLink = {
  __typename?: "LineItemScenarioLink";
  id: Scalars["ID"]["output"];
  isHidden: Scalars["Boolean"]["output"];
  src: Account;
  targets: Array<Maybe<Account>>;
};

export type LineItemScenarioLinksForUnitTable = {
  __typename?: "LineItemScenarioLinksForUnitTable";
  id: Scalars["ID"]["output"];
  links: Array<LineItemScenarioLink>;
  unitTable: UnitTable;
};

export type MergeConnection = {
  __typename?: "MergeConnection";
  companyIntegration: CompanyIntegration;
  linkToken: Scalars["String"]["output"];
};

export type MissingLinkCounts = {
  __typename?: "MissingLinkCounts";
  dimensions: Scalars["Int"]["output"];
  lineItems: Scalars["Int"]["output"];
  unitTables: Scalars["Int"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  addDimensionGrouping: UnitTableDimensionUserState;
  adminCreateCompany: Company;
  adminCreateOrganization: Organization;
  adminDeleteOrganization: Organization;
  adminScheduleCompanyForDeletion: Company;
  adminUpdateAICopilotStatus: Company;
  adminUpdateCompany: Company;
  adminUpdateIntegrationLimits: Company;
  adminUpdateOrganization: Organization;
  authorizeMergeConnection: CompanyIntegration;
  changeAllDimensionsVisibility: Array<UnitTableDimensionUserState>;
  changeDimensionVisibility: UnitTableDimensionUserState;
  changeUnitTableWorkbook: UnitTable;
  createAccount: Account;
  createChart: Chart;
  createCompanyIntegration: CompanyIntegration;
  createDashboard: Dashboard;
  createDimension: Dimension;
  createMergeConnection: MergeConnection;
  /**
   * Begin a new client driven onboarding session
   * @deprecated Use PLG createOnboardingV2 instead
   */
  createOnboarding: Onboarding;
  createOnboardingIntegration: Onboarding;
  /** Create a new PLG onboarding record */
  createOnboardingV2: Onboarding;
  createOrUpdateManyBasisUserPermissions: BasisUserCompany;
  /** @deprecated Use updateUnitTableLineItemDimension */
  createOrUpdateUnitTableLineItemDimension: UnitTableLineItemDimension;
  createOutputTableLineItem: OutputTableLineItem;
  createRollupAccount: Account;
  createScenario?: Maybe<Scalars["Void"]["output"]>;
  createUnitTable: UnitTable;
  createUnitTableCompanyIntegration: UnitTableCompanyIntegration;
  createWorkbook: Workbook;
  deleteAccount: Account;
  deleteChart: Chart;
  deleteDashboard: Dashboard;
  deleteDimension: Dimension;
  deleteManyAccounts: Array<Account>;
  deleteOutputTableLineItem: OutputTableLineItem;
  deleteOutputTableLineItemV2: OutputTableLineItem;
  deleteUnitTable: UnitTable;
  deleteUnitTableCompanyIntegration: UnitTableCompanyIntegration;
  deleteWorkbook: Workbook;
  demoteChart: Array<Chart>;
  developmentOnlyUpdateUserRoles: BasisUserCompany;
  editDashboard: Dashboard;
  fillManyFigureFormulas?: Maybe<Scalars["Void"]["output"]>;
  inviteUserToCompanyV2: BasisUserCompany;
  joinPremiumAICopilotWaitlist: Company;
  onboardingStarted?: Maybe<Scalars["Void"]["output"]>;
  postOpenAIMessage: OpenAiThreadMessages;
  promoteChart: Array<Chart>;
  reInviteUserToCompany: BasisUserCompany;
  removeCompanyIntegration: CompanyIntegration;
  removeDimensionGrouping: UnitTableDimensionUserState;
  removeMergeConnection: CompanyIntegration;
  removeUserFromCompany: BasisUserCompany;
  reorderAccount: Account;
  reorderOutputTableLineItem: OutputTableLineItem;
  reorderUnitTable: UnitTable;
  retryMergeConnection: MergeConnection;
  saveIntegrationAccountMapping?: Maybe<Scalars["Void"]["output"]>;
  saveIntegrationModalConfig: CompanyIntegration;
  scheduleScenarioForDeletion: Scenario;
  setOperatingAccount: Onboarding;
  setScenarioLinks: Query;
  sharePageWithUserV2: BasisUserCompany;
  updateAccount: Account;
  updateAccountMetric: Account;
  updateBasisUserDashboardState: BasisUserState;
  updateChart: Chart;
  updateChartDoc: ChartDoc;
  /** @deprecated Use updateChart instead */
  updateChartDocChart: Chart;
  updateCompany: Company;
  updateCompanyPlanSettings: Company;
  updateDashboardDoc: DashboardDoc;
  updateDashboardScenarioComparisons: Array<DashboardScenarioComparison>;
  updateDimension: Dimension;
  updateManyCompanyOutputTableDimensionGroupings: Array<OutputTableUnitTableDimensionCompanyState>;
  updateManyDifferentFigureFormulas?: Maybe<Scalars["Void"]["output"]>;
  updateManyUnitTableLineItemDimension: Array<UnitTableLineItemDimension>;
  /** Update the client new company request options */
  updateOnboarding: Onboarding;
  updateOnboardingCurrentPage: Onboarding;
  updateOutputTable: OutputTable;
  /** @deprecated Use updateChart instead */
  updateOutputTableChart: Chart;
  updateOutputTableScenarioComparisons: Array<OutputTableViewOptionScenarioComparison>;
  updateOutputTableViewOptions: OutputTableViewOption;
  updateScenario: Scenario;
  updateUnitTable: UnitTable;
  updateUnitTableLineItemDimension: UnitTableLineItemDimension;
  updateUserCompanyRole: BasisUserCompany;
  updateUserLoggedInStatus: BasisUserCompany;
  updateUserScenarioRole: BasisUserCompany;
  updateWorkbook: Workbook;
  uploadChartDocFiles: Array<ChartDocFileUpload>;
  uploadCsvIntegrationFile: CsvUpload;
  uploadDashboardDocFiles: Array<DashboardDocFileUpload>;
  validateCsvIntegrationFile: CsvValidationModel;
};

export type MutationAddDimensionGroupingArgs = {
  companyId: Scalars["ID"]["input"];
  dimensionId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type MutationAdminCreateCompanyArgs = {
  fiscalYearStartMonth: Scalars["Int"]["input"];
  name: Scalars["String"]["input"];
  organizationId?: InputMaybe<Scalars["ID"]["input"]>;
  organizationName?: InputMaybe<Scalars["String"]["input"]>;
  planEnd: Scalars["Int"]["input"];
  planStart: Scalars["Int"]["input"];
  rolloverDate?: InputMaybe<Scalars["String"]["input"]>;
  rolloverDay: Scalars["Int"]["input"];
  rolloverMonthDelay: Scalars["Int"]["input"];
};

export type MutationAdminCreateOrganizationArgs = {
  name: Scalars["String"]["input"];
};

export type MutationAdminDeleteOrganizationArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationAdminScheduleCompanyForDeletionArgs = {
  companyId: Scalars["ID"]["input"];
};

export type MutationAdminUpdateAiCopilotStatusArgs = {
  companyId: Scalars["String"]["input"];
  status: BasisAiCopilotStatus;
};

export type MutationAdminUpdateCompanyArgs = {
  companyId: Scalars["ID"]["input"];
  defaultScenarioId?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  organizationId?: InputMaybe<Scalars["ID"]["input"]>;
  organizationName?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationAdminUpdateIntegrationLimitsArgs = {
  accountingIntegrationLimit: Scalars["Int"]["input"];
  companyId: Scalars["ID"]["input"];
  crmIntegrationLimit: Scalars["Int"]["input"];
  payrollIntegrationLimit: Scalars["Int"]["input"];
};

export type MutationAdminUpdateOrganizationArgs = {
  id: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationAuthorizeMergeConnectionArgs = {
  companyId: Scalars["ID"]["input"];
  companyIntegrationId: Scalars["ID"]["input"];
  integrationType: IntegrationType;
  linkToken: Scalars["String"]["input"];
};

export type MutationChangeAllDimensionsVisibilityArgs = {
  companyId: Scalars["ID"]["input"];
  isVisible: Scalars["Boolean"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type MutationChangeDimensionVisibilityArgs = {
  companyId: Scalars["ID"]["input"];
  dimensionId: Scalars["ID"]["input"];
  isVisible: Scalars["Boolean"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type MutationChangeUnitTableWorkbookArgs = {
  companyId: Scalars["ID"]["input"];
  newWorkbookId: Scalars["ID"]["input"];
  nextId?: InputMaybe<Scalars["ID"]["input"]>;
  previousId?: InputMaybe<Scalars["ID"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type MutationCreateAccountArgs = {
  companyId: Scalars["ID"]["input"];
  displayName: Scalars["String"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type MutationCreateChartArgs = {
  chartSize?: InputMaybe<ChartSize>;
  chartType: ChartType;
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  rank?: InputMaybe<Scalars["Int"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
  topicIds: Array<Scalars["ID"]["input"]>;
};

export type MutationCreateCompanyIntegrationArgs = {
  companyId: Scalars["ID"]["input"];
  integrationProviderName: Scalars["String"]["input"];
};

export type MutationCreateDashboardArgs = {
  companyId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type MutationCreateDimensionArgs = {
  companyId: Scalars["ID"]["input"];
  isRolledUp: Scalars["Boolean"]["input"];
  name: Scalars["String"]["input"];
  scenarioId: Scalars["ID"]["input"];
  type: DimensionType;
  unitTableId: Scalars["ID"]["input"];
};

export type MutationCreateMergeConnectionArgs = {
  companyId: Scalars["ID"]["input"];
  integrationName: Scalars["String"]["input"];
  integrationType: IntegrationType;
  userId: Scalars["ID"]["input"];
};

export type MutationCreateOnboardingArgs = {
  companyName: Scalars["String"]["input"];
  userId: Scalars["ID"]["input"];
};

export type MutationCreateOnboardingIntegrationArgs = {
  integrationProviderName: IntegrationProviderName;
  slug: Scalars["String"]["input"];
};

export type MutationCreateOnboardingV2Args = {
  companyName: Scalars["String"]["input"];
  endDate?: InputMaybe<Scalars["DateFor"]["input"]>;
  fiscalYearStartMonth: Scalars["Int"]["input"];
  industry: Scalars["String"]["input"];
  startDate?: InputMaybe<Scalars["DateFor"]["input"]>;
};

export type MutationCreateOrUpdateManyBasisUserPermissionsArgs = {
  companyId: Scalars["ID"]["input"];
  dashboardAccessRights: Array<UpdateDashboardAccessRightsArgs>;
  scenarioId: Scalars["ID"]["input"];
  unitTableAccessRights?: InputMaybe<Array<UpdateUnitTableAccessRightsArgs>>;
  userId: Scalars["ID"]["input"];
  workbookAccessRights?: InputMaybe<Array<UpdateWorkbookAccessRightsArgs>>;
};

export type MutationCreateOrUpdateUnitTableLineItemDimensionArgs = {
  companyId: Scalars["ID"]["input"];
  dimensionId: Scalars["ID"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
  unitTableLineItemId: Scalars["ID"]["input"];
  value: Scalars["String"]["input"];
};

export type MutationCreateOutputTableLineItemArgs = {
  companyId: Scalars["ID"]["input"];
  outputTableId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId?: InputMaybe<Scalars["ID"]["input"]>;
  unitTableLineItemId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationCreateRollupAccountArgs = {
  companyId: Scalars["ID"]["input"];
  rollupId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type MutationCreateScenarioArgs = {
  color: Scalars["String"]["input"];
  companyId: Scalars["ID"]["input"];
  description: Scalars["String"]["input"];
  endDate?: InputMaybe<Scalars["DateFor"]["input"]>;
  lastActual?: InputMaybe<Scalars["DateFor"]["input"]>;
  name: Scalars["String"]["input"];
  sourceScenarioId: Scalars["ID"]["input"];
  startDate?: InputMaybe<Scalars["DateFor"]["input"]>;
  variant?: InputMaybe<ScenarioVariant>;
};

export type MutationCreateUnitTableArgs = {
  companyId: Scalars["ID"]["input"];
  defaultPeriodValueType?: InputMaybe<AccountPeriodValueType>;
  defaultVarianceDirection?: InputMaybe<VarianceDirection>;
  name: Scalars["String"]["input"];
  parentUnitTableId?: InputMaybe<Scalars["ID"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
  templateName?: InputMaybe<UnitTableTemplateName>;
  workbookId: Scalars["ID"]["input"];
};

export type MutationCreateUnitTableCompanyIntegrationArgs = {
  companyId: Scalars["ID"]["input"];
  companyIntegrationId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type MutationCreateWorkbookArgs = {
  companyId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  scenarioId: Scalars["ID"]["input"];
  template?: InputMaybe<WorkbookTemplate>;
};

export type MutationDeleteAccountArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type MutationDeleteChartArgs = {
  chartId: Scalars["ID"]["input"];
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type MutationDeleteDashboardArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type MutationDeleteDimensionArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationDeleteManyAccountsArgs = {
  companyId: Scalars["ID"]["input"];
  ids: Array<Scalars["ID"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
};

export type MutationDeleteOutputTableLineItemArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type MutationDeleteOutputTableLineItemV2Args = {
  companyId: Scalars["ID"]["input"];
  outputTableId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId?: InputMaybe<Scalars["ID"]["input"]>;
  unitTableLineItemId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationDeleteUnitTableArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type MutationDeleteUnitTableCompanyIntegrationArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type MutationDeleteWorkbookArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  workbookId: Scalars["ID"]["input"];
};

export type MutationDemoteChartArgs = {
  chartId: Scalars["ID"]["input"];
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type MutationDevelopmentOnlyUpdateUserRolesArgs = {
  companyId: Scalars["ID"]["input"];
  companyRole?: InputMaybe<BasisUserCompanyRoleType>;
  scenarioId?: InputMaybe<Scalars["ID"]["input"]>;
  scenarioRole?: InputMaybe<BasisUserScenarioRoleType>;
  userId: Scalars["ID"]["input"];
};

export type MutationEditDashboardArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type MutationFillManyFigureFormulasArgs = {
  companyId: Scalars["ID"]["input"];
  direction: Direction;
  figures: Array<V2_FigureFormulaUpdate>;
  scenarioId: Scalars["ID"]["input"];
};

export type MutationInviteUserToCompanyV2Args = {
  companyId: Scalars["ID"]["input"];
  email: Scalars["String"]["input"];
  role: BasisUserCompanyRoleType;
};

export type MutationJoinPremiumAiCopilotWaitlistArgs = {
  companyId: Scalars["String"]["input"];
};

export type MutationOnboardingStartedArgs = {
  slug: Scalars["String"]["input"];
};

export type MutationPostOpenAiMessageArgs = {
  companyId: Scalars["String"]["input"];
  message: Scalars["String"]["input"];
  scenarioId: Scalars["String"]["input"];
  threadId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationPromoteChartArgs = {
  chartId: Scalars["ID"]["input"];
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type MutationReInviteUserToCompanyArgs = {
  companyId: Scalars["ID"]["input"];
  email: Scalars["String"]["input"];
};

export type MutationRemoveCompanyIntegrationArgs = {
  companyId: Scalars["ID"]["input"];
  integrationId: Scalars["ID"]["input"];
};

export type MutationRemoveDimensionGroupingArgs = {
  companyId: Scalars["ID"]["input"];
  dimensionId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type MutationRemoveMergeConnectionArgs = {
  companyId: Scalars["ID"]["input"];
  companyIntegrationId: Scalars["ID"]["input"];
  integrationType: IntegrationType;
};

export type MutationRemoveUserFromCompanyArgs = {
  companyId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type MutationReorderAccountArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  nextId?: InputMaybe<Scalars["ID"]["input"]>;
  previousId?: InputMaybe<Scalars["ID"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
};

export type MutationReorderOutputTableLineItemArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  nextId?: InputMaybe<Scalars["ID"]["input"]>;
  previousId?: InputMaybe<Scalars["ID"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
};

export type MutationReorderUnitTableArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  nextId?: InputMaybe<Scalars["ID"]["input"]>;
  previousId?: InputMaybe<Scalars["ID"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
};

export type MutationRetryMergeConnectionArgs = {
  companyId: Scalars["ID"]["input"];
  companyIntegrationId: Scalars["ID"]["input"];
  integrationType: IntegrationType;
  userId: Scalars["ID"]["input"];
};

export type MutationSaveIntegrationAccountMappingArgs = {
  companyId: Scalars["ID"]["input"];
  companyIntegrationId: Scalars["ID"]["input"];
  mappings: Array<UnitTableLineItemIntegrationAccountMapping>;
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type MutationSaveIntegrationModalConfigArgs = {
  companyId: Scalars["ID"]["input"];
  config: Array<SaveIntegrationModalConfigInput>;
  integrationId: Scalars["ID"]["input"];
};

export type MutationScheduleScenarioForDeletionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type MutationSetOperatingAccountArgs = {
  onboardingId: Scalars["ID"]["input"];
  operatingAccountId?: InputMaybe<Scalars["ID"]["input"]>;
  skipConfig?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationSetScenarioLinksArgs = {
  input: SetScenarioLinksInput;
};

export type MutationSharePageWithUserV2Args = {
  companyId: Scalars["ID"]["input"];
  dashboardAccessRights?: InputMaybe<UpdateDashboardAccessRightsArgs>;
  email: Scalars["String"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableAccessRights?: InputMaybe<UpdateUnitTableAccessRightsArgs>;
  workbookAccessRights?: InputMaybe<UpdateWorkbookAccessRightsArgs>;
};

export type MutationUpdateAccountArgs = {
  accountId: Scalars["ID"]["input"];
  companyId: Scalars["ID"]["input"];
  displayName: Scalars["String"]["input"];
  figureType?: InputMaybe<FigureType>;
  periodValueType?: InputMaybe<AccountPeriodValueType>;
  scenarioId: Scalars["ID"]["input"];
  varianceDirection?: InputMaybe<VarianceDirection>;
};

export type MutationUpdateAccountMetricArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  isMetric: Scalars["Boolean"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type MutationUpdateBasisUserDashboardStateArgs = {
  companyId: Scalars["ID"]["input"];
  dashboardState: UpdateBasisUserDashboardStateArgs;
  scenarioId: Scalars["ID"]["input"];
};

export type MutationUpdateChartArgs = {
  chartId: Scalars["ID"]["input"];
  chartSize?: InputMaybe<ChartSize>;
  chartType?: InputMaybe<ChartType>;
  companyId: Scalars["ID"]["input"];
  comparisonType?: InputMaybe<ComparisonType>;
  dashboardId: Scalars["ID"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  lineItems?: InputMaybe<Array<ChartTopicOptions>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
  shouldDisplayActualsIfAvailable?: InputMaybe<Scalars["Boolean"]["input"]>;
  shouldDisplayHistoricalForecasts?: InputMaybe<Scalars["Boolean"]["input"]>;
  showDescription?: InputMaybe<Scalars["Boolean"]["input"]>;
  timeSeriesGroupingType?: InputMaybe<TimeSeriesGroupingType>;
};

export type MutationUpdateChartDocArgs = {
  companyId: Scalars["ID"]["input"];
  content: Scalars["String"]["input"];
  dashboardId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  lastEditorId?: InputMaybe<Scalars["ID"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
};

export type MutationUpdateChartDocChartArgs = {
  chartId: Scalars["ID"]["input"];
  chartSize?: InputMaybe<ChartSize>;
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
};

export type MutationUpdateCompanyArgs = {
  companyId: Scalars["ID"]["input"];
  defaultScenarioId?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
};

export type MutationUpdateCompanyPlanSettingsArgs = {
  companyId: Scalars["ID"]["input"];
  fiscalYearStartMonth?: InputMaybe<Scalars["Int"]["input"]>;
  isRolloverPaused?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastActual?: InputMaybe<Scalars["DateFor"]["input"]>;
  rolloverDay?: InputMaybe<Scalars["Int"]["input"]>;
  rolloverMonthDelay?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationUpdateDashboardDocArgs = {
  companyId: Scalars["ID"]["input"];
  content: Scalars["String"]["input"];
  dashboardId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  lastEditorId?: InputMaybe<Scalars["ID"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
};

export type MutationUpdateDashboardScenarioComparisonsArgs = {
  input: UpdateDashboardScenarioComparisonInputObject;
};

export type MutationUpdateDimensionArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  isRolledUp: Scalars["Boolean"]["input"];
  name: Scalars["String"]["input"];
  scenarioId: Scalars["ID"]["input"];
  type: DimensionType;
  unitTableId: Scalars["ID"]["input"];
};

export type MutationUpdateManyCompanyOutputTableDimensionGroupingsArgs = {
  companyId: Scalars["ID"]["input"];
  outputTableGroupings: Array<OutputTableGrouping>;
  scenarioId: Scalars["ID"]["input"];
};

export type MutationUpdateManyDifferentFigureFormulasArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId?: InputMaybe<Scalars["ID"]["input"]>;
  updates: Array<FigureFormulaUpdate>;
};

export type MutationUpdateManyUnitTableLineItemDimensionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  updates: Array<UnitTableDimensionValueUpdate>;
};

export type MutationUpdateOnboardingArgs = {
  companyName: Scalars["String"]["input"];
  companyPlanLength: Scalars["String"]["input"];
  companyType?: InputMaybe<Scalars["String"]["input"]>;
  fiscalYearStartMonth: Scalars["Int"]["input"];
  slug: Scalars["String"]["input"];
  userEmail?: InputMaybe<Scalars["String"]["input"]>;
  userRole?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateOnboardingCurrentPageArgs = {
  currentPage: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
};

export type MutationUpdateOutputTableArgs = {
  companyId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  outputTableId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type MutationUpdateOutputTableChartArgs = {
  chartId: Scalars["ID"]["input"];
  chartSize?: InputMaybe<ChartSize>;
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
};

export type MutationUpdateOutputTableScenarioComparisonsArgs = {
  input: UpdateOutputTableScenarioComparisonInputObject;
};

export type MutationUpdateOutputTableViewOptionsArgs = {
  input: OutputTableViewOptionInput;
};

export type MutationUpdateScenarioArgs = {
  color?: InputMaybe<Scalars["String"]["input"]>;
  companyId: Scalars["ID"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["DateFor"]["input"]>;
  isStarred?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastActual?: InputMaybe<Scalars["DateFor"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
  startDate?: InputMaybe<Scalars["DateFor"]["input"]>;
};

export type MutationUpdateUnitTableArgs = {
  companyId: Scalars["ID"]["input"];
  defaultFormatType?: InputMaybe<FigureType>;
  defaultPeriodValueType?: InputMaybe<AccountPeriodValueType>;
  defaultVarianceDirection?: InputMaybe<VarianceDirection>;
  name: Scalars["String"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type MutationUpdateUnitTableLineItemDimensionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  update: UnitTableDimensionValueUpdate;
};

export type MutationUpdateUserCompanyRoleArgs = {
  companyId: Scalars["ID"]["input"];
  companyRole: BasisUserCompanyRoleType;
  userId: Scalars["ID"]["input"];
};

export type MutationUpdateUserLoggedInStatusArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type MutationUpdateUserScenarioRoleArgs = {
  companyId: Scalars["ID"]["input"];
  role?: InputMaybe<BasisUserScenarioRoleType>;
  scenarioId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type MutationUpdateWorkbookArgs = {
  companyId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  scenarioId: Scalars["ID"]["input"];
  workbookId: Scalars["ID"]["input"];
};

export type MutationUploadChartDocFilesArgs = {
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
  files: Array<Scalars["Upload"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
};

export type MutationUploadCsvIntegrationFileArgs = {
  companyId: Scalars["ID"]["input"];
  file: Scalars["Upload"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type MutationUploadDashboardDocFilesArgs = {
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
  files: Array<Scalars["Upload"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
};

export type MutationValidateCsvIntegrationFileArgs = {
  companyId: Scalars["ID"]["input"];
  file: Scalars["Upload"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type Onboarding = {
  __typename?: "Onboarding";
  codatCompanyId?: Maybe<Scalars["String"]["output"]>;
  company?: Maybe<Company>;
  companyName: Scalars["String"]["output"];
  companyPlanLength?: Maybe<Scalars["String"]["output"]>;
  companyType?: Maybe<Scalars["String"]["output"]>;
  completedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  currentPage?: Maybe<Scalars["String"]["output"]>;
  endDate: Scalars["DateTime"]["output"];
  expiredAt: Scalars["DateTime"]["output"];
  fiscalYearStartMonth: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  integratedAt?: Maybe<Scalars["DateTime"]["output"]>;
  integrationAuthLink?: Maybe<Scalars["String"]["output"]>;
  integrationProviderName?: Maybe<Scalars["String"]["output"]>;
  integrationStatus?: Maybe<OnboardingIntegrationStatus>;
  link: Scalars["String"]["output"];
  needsOperatingAccountConfig: Scalars["Boolean"]["output"];
  openedAt?: Maybe<Scalars["DateTime"]["output"]>;
  slug: Scalars["String"]["output"];
  startDate: Scalars["DateTime"]["output"];
  status: OnboardingStatus;
  tasksCompleted: Array<OnboardingTasks>;
  updatedAt: Scalars["DateTime"]["output"];
  userEmail?: Maybe<Scalars["String"]["output"]>;
  userRole?: Maybe<Scalars["String"]["output"]>;
};

export enum OnboardingIntegrationStatus {
  Deauthorized = "Deauthorized",
  Linked = "Linked",
  PendingAuth = "PendingAuth",
  Unlinked = "Unlinked",
}

/** The current status of an onboarding session. */
export enum OnboardingStatus {
  Completed = "Completed",
  Created = "Created",
  Expired = "Expired",
  Integrated = "Integrated",
  Started = "Started",
}

export enum OnboardingTasks {
  AutoMappingCompleted = "AUTO_MAPPING_COMPLETED",
  DashboardsCreated = "DASHBOARDS_CREATED",
  InitialTransformCompleted = "INITIAL_TRANSFORM_COMPLETED",
  OpacctFormulaSet = "OPACCT_FORMULA_SET",
}

/** Message */
export type OpenAiThreadMessage = {
  __typename?: "OpenAIThreadMessage";
  content: Array<Scalars["String"]["output"]>;
};

/** ThreadId + Messages */
export type OpenAiThreadMessages = {
  __typename?: "OpenAIThreadMessages";
  messages: Array<OpenAiThreadMessage>;
  threadId: Scalars["String"]["output"];
};

export type Organization = {
  __typename?: "Organization";
  companyCount: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type OutputTable = {
  __typename?: "OutputTable";
  company: Company;
  companyId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lineItems: Array<OutputTableLineItem>;
  name: Scalars["String"]["output"];
  outputTableUnitTableDimensionCompanyState: Array<OutputTableUnitTableDimensionCompanyState>;
  outputTableViewOption?: Maybe<OutputTableViewOption>;
  permissions: OutputTablePermission;
  scenarioId: Scalars["String"]["output"];
};

/** An entire output tables grouping rules */
export type OutputTableGrouping = {
  outputTableId: Scalars["ID"]["input"];
  unitTableDimensionGroupings: Array<UnitTableDimensionGrouping>;
};

/** Insights data for rendering output table charts */
export type OutputTableInsights = {
  __typename?: "OutputTableInsights";
  figureType: FigureType;
  floatValues: Array<Array<Maybe<Scalars["Float"]["output"]>>>;
  id: Scalars["ID"]["output"];
  keys: Array<OutputTableInsightsYLabels>;
  name: Scalars["String"]["output"];
  yLabels: Array<Scalars["String"]["output"]>;
};

/** Input for describing a line item selection */
export type OutputTableInsightsInput = {
  companyId: Scalars["ID"]["input"];
  groups?: InputMaybe<Array<OutputTableInsightsInputGroup>>;
  lineItemId?: InputMaybe<Scalars["ID"]["input"]>;
  options: OutputTableInsightsInputOptions;
  scenarioId: Scalars["ID"]["input"];
  selection: OutputTableInsightsInputSelection;
  unitTableId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** An ordered list of dimensions + values used to filter and aggregate a virtual line item */
export type OutputTableInsightsInputGroup = {
  dimensionId: Scalars["ID"]["input"];
  dimensionValue: Scalars["String"]["input"];
};

export type OutputTableInsightsInputOptions = {
  /** ISO date string for the start of the end range */
  dateRangeEnd?: InputMaybe<Scalars["String"]["input"]>;
  /** ISO date string for the start of the date range */
  dateRangeStart?: InputMaybe<Scalars["String"]["input"]>;
};

export enum OutputTableInsightsInputSelection {
  Aggregate = "Aggregate",
  LineItem = "LineItem",
}

export enum OutputTableInsightsYLabels {
  Actual = "Actual",
  FutureProjection = "FutureProjection",
  HistoricalProjection = "HistoricalProjection",
  Variance = "Variance",
  VariancePercent = "VariancePercent",
}

export type OutputTableLineItem = {
  __typename?: "OutputTableLineItem";
  companyId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  order: Scalars["String"]["output"];
  outputTable: OutputTable;
  permissions: OutputTableLineItemPermission;
  scenarioId: Scalars["String"]["output"];
  /** @deprecated Use outputTableRows */
  unitTable?: Maybe<UnitTable>;
  unitTableId?: Maybe<Scalars["String"]["output"]>;
  unitTableLineItem?: Maybe<Account>;
  unitTableLineItemId?: Maybe<Scalars["String"]["output"]>;
};

/** Permissions on Output Table Line Items. */
export type OutputTableLineItemPermission = {
  __typename?: "OutputTableLineItemPermission";
  canRead: Scalars["Boolean"]["output"];
  canRedirect: Scalars["Boolean"]["output"];
  canWrite: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
};

/** Permissions on Output Tables. */
export type OutputTablePermission = {
  __typename?: "OutputTablePermission";
  canRead: Scalars["Boolean"]["output"];
  canWrite: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
};

export type OutputTableUnitTableDimensionCompanyState = {
  __typename?: "OutputTableUnitTableDimensionCompanyState";
  companyId: Scalars["String"]["output"];
  dimension: Dimension;
  groupByOrder: Scalars["Decimal"]["output"];
  id: Scalars["ID"]["output"];
  outputTable: OutputTable;
  scenarioId: Scalars["String"]["output"];
  unitTable: UnitTable;
};

export type OutputTableViewOption = {
  __typename?: "OutputTableViewOption";
  analyses: Array<OutputTableViewOptionAnalysis>;
  companyId: Scalars["String"]["output"];
  dimensionGroupings: Array<OutputTableUnitTableDimensionCompanyState>;
  id: Scalars["ID"]["output"];
  includeHistoricalForecasts: Scalars["Boolean"]["output"];
  outputTableId: Scalars["String"]["output"];
  runningTotalDisplayColumns: Array<OutputTableViewOptionRunningTotalDisplayColumn>;
  scenarioComparisons: Array<OutputTableViewOptionScenarioComparison>;
  scenarioId: Scalars["String"]["output"];
  showCurrentActuals: Scalars["Boolean"]["output"];
};

export type OutputTableViewOptionAnalysis = {
  __typename?: "OutputTableViewOptionAnalysis";
  companyId: Scalars["String"]["output"];
  displayColumns: Array<OutputTableViewOptionDisplayColumn>;
  id: Scalars["ID"]["output"];
  leftComparisonType: OutputTableViewOptionComparisonType;
  leftScenarioComparisonId: Scalars["String"]["output"];
  outputTableViewOptionId: Scalars["String"]["output"];
  position: Scalars["Int"]["output"];
  rightComparisonType: OutputTableViewOptionComparisonType;
  rightScenarioComparisonId: Scalars["String"]["output"];
  styles: Array<OutputTableViewOptionComparisonStyle>;
};

export enum OutputTableViewOptionComparisonStyle {
  SplitAmount = "SPLIT_AMOUNT",
  SplitPercent = "SPLIT_PERCENT",
  Stacked = "STACKED",
}

export enum OutputTableViewOptionComparisonType {
  CurrentPeriod = "CURRENT_PERIOD",
  LastPeriod = "LAST_PERIOD",
  LastYear = "LAST_YEAR",
}

export enum OutputTableViewOptionDisplayColumn {
  Month = "MONTH",
  Quarter = "QUARTER",
  Year = "YEAR",
}

/** An entire output tables grouping rules */
export type OutputTableViewOptionInput = {
  analyses: Array<ScenarioAnalysisInput>;
  companyId: Scalars["ID"]["input"];
  includeHistoricalForecasts: Scalars["Boolean"]["input"];
  outputTableId: Scalars["ID"]["input"];
  runningTotalDisplayColumns: Array<OutputTableViewOptionRunningTotalDisplayColumn>;
  scenarioComparisons: Array<ScenarioComparisonInput>;
  scenarioId: Scalars["ID"]["input"];
  showCurrentActuals: Scalars["Boolean"]["input"];
  unitTableDimensionGroupings: Array<DimensionGroupingInput>;
};

export enum OutputTableViewOptionRunningTotalDisplayColumn {
  Quarter = "QUARTER",
  Year = "YEAR",
}

export type OutputTableViewOptionScenarioComparison = {
  __typename?: "OutputTableViewOptionScenarioComparison";
  bookmark?: Maybe<ScenarioComparisonBookmark>;
  companyId: Scalars["String"]["output"];
  displayColumns: Array<OutputTableViewOptionDisplayColumn>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  outputTableViewOptionId: Scalars["String"]["output"];
  position: Scalars["Int"]["output"];
  scenarioId: Scalars["String"]["output"];
  targetScenario?: Maybe<Scenario>;
  targetScenarioId?: Maybe<Scalars["String"]["output"]>;
};

/** Plan date range for a Company. */
export type PlanDateRange = {
  __typename?: "PlanDateRange";
  endDate: Scalars["String"]["output"];
  startDate: Scalars["String"]["output"];
};

export type Query = {
  __typename?: "Query";
  accountById: Account;
  /** Get all companies accessible by admins. */
  adminGetAllCompanies: Array<Company>;
  /** Get all organizations accessible by admins. */
  adminGetAllOrganizations: Array<Organization>;
  basisUserCompany: BasisUserCompany;
  basisUserScenario: BasisUserScenario;
  /** Get a chart by id. */
  chart: Chart;
  /** Get a Chart Doc by id. */
  chartDoc: ChartDoc;
  /** Get all companies accessible by the user. */
  companies: Array<Company>;
  /** Get a company by id. */
  companyById: Company;
  companyIntegrationById: CompanyIntegration;
  currentUser?: Maybe<BasisUser>;
  dashboard: Dashboard;
  /** Get a Dashboard Doc by id. */
  dashboardDoc: DashboardDoc;
  dimensionLinkCandidates: Array<DimensionCandidateLink>;
  dimensionLinks: Array<DimensionScenarioLinksForUnitTable>;
  getManyDimensionsById: Array<Dimension>;
  integrationProviders: Array<IntegrationProvider>;
  /** Get a random guide article and the latest news article from Intercom. */
  intercomHomepageArticles: IntercomHomepageArticles;
  lineItemLinkCandidates: Array<LineItemCandidateLink>;
  lineItemLinks: Array<LineItemScenarioLinksForUnitTable>;
  missingLinkCounts: MissingLinkCounts;
  onboarding: Onboarding;
  onboardingBySlug: Onboarding;
  onboardingByUser?: Maybe<Onboarding>;
  onboardings: Array<Onboarding>;
  /** Get candidate accounts to serve as main operating cash account */
  operatingAccountCandidates: Array<Maybe<Account>>;
  /** Get linked accounts for an output table */
  outputTableAccountRowsV2: V2_1_OutputTableRows;
  outputTableById: OutputTable;
  /** Get output table column definitions */
  outputTableColDefsV2: Array<V2_1_OutputTableColGroupDef>;
  /** Get insights data for an output table */
  outputTableInsights: OutputTableInsights;
  outputTableLineItem: OutputTableLineItem;
  /** Get output tables rows for a unit table */
  outputTableRowsV2: V2_1_OutputTableRows;
  /** Get a scenario by id. */
  scenario: Scenario;
  unitTableById: UnitTable;
  unitTableLinkCandidates: Array<UnitTableCandidateLink>;
  unitTableLinks: Array<UnitTableScenarioLink>;
  /** Get unit table rows */
  unitTableRowsV2: Array<V2_UnitTableRows>;
  workbook: Workbook;
};

export type QueryAccountByIdArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type QueryBasisUserCompanyArgs = {
  companyId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type QueryBasisUserScenarioArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  userId: Scalars["ID"]["input"];
};

export type QueryChartArgs = {
  chartId: Scalars["ID"]["input"];
  companyId: Scalars["ID"]["input"];
  dashboardId?: InputMaybe<Scalars["ID"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
};

export type QueryChartDocArgs = {
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type QueryCompanyByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryCompanyIntegrationByIdArgs = {
  companyId: Scalars["ID"]["input"];
  integrationId: Scalars["ID"]["input"];
};

export type QueryDashboardArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type QueryDashboardDocArgs = {
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type QueryDimensionLinkCandidatesArgs = {
  input: GetLinkCandidatesInput;
};

export type QueryDimensionLinksArgs = {
  input: GetLinksInput;
};

export type QueryGetManyDimensionsByIdArgs = {
  companyId: Scalars["ID"]["input"];
  ids: Array<Scalars["ID"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
};

export type QueryLineItemLinkCandidatesArgs = {
  input: GetLinkCandidatesInput;
};

export type QueryLineItemLinksArgs = {
  input: GetLinksInput;
};

export type QueryMissingLinkCountsArgs = {
  input: GetLinksInput;
};

export type QueryOnboardingArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryOnboardingBySlugArgs = {
  slug: Scalars["String"]["input"];
};

export type QueryOperatingAccountCandidatesArgs = {
  onboardingId: Scalars["String"]["input"];
};

export type QueryOutputTableAccountRowsV2Args = {
  input: V2_OutputTableAccountRowsInput;
};

export type QueryOutputTableByIdArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type QueryOutputTableColDefsV2Args = {
  input: V2_OutputTableColDefsInput;
};

export type QueryOutputTableInsightsArgs = {
  input: OutputTableInsightsInput;
};

export type QueryOutputTableLineItemArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type QueryOutputTableRowsV2Args = {
  input: V2_OutputTableRowsInput;
};

export type QueryScenarioArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
};

export type QueryUnitTableByIdArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type QueryUnitTableLinkCandidatesArgs = {
  input: GetUnitTableLinkCandidatesInput;
};

export type QueryUnitTableLinksArgs = {
  input: GetLinksInput;
};

export type QueryUnitTableRowsV2Args = {
  input: V2_UnitTableRowsInput;
};

export type QueryWorkbookArgs = {
  companyId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SaveIntegrationModalConfigHierarchiesInput = {
  depth: Scalars["Int"]["input"];
  trackingCategoryId: Scalars["ID"]["input"];
};

export type SaveIntegrationModalConfigInput = {
  codatConfigAccountingId: Scalars["String"]["input"];
  hierarchies: Array<SaveIntegrationModalConfigHierarchiesInput>;
  isClassBased: Scalars["Boolean"]["input"];
};

export type Scenario = {
  __typename?: "Scenario";
  accounts: Array<Account>;
  balanceSheetWorkbookId?: Maybe<Scalars["String"]["output"]>;
  budgetMonthStatuses: Array<BudgetMonthStatus>;
  color: Scalars["String"]["output"];
  dashboards: Array<Dashboard>;
  description: Scalars["String"]["output"];
  driversOutputTableId?: Maybe<Scalars["String"]["output"]>;
  firstProjection?: Maybe<Scalars["DateFor"]["output"]>;
  id: Scalars["ID"]["output"];
  isBusted: Scalars["Boolean"]["output"];
  isReady: Scalars["Boolean"]["output"];
  isStarred: Scalars["Boolean"]["output"];
  lastActual?: Maybe<Scalars["DateFor"]["output"]>;
  lineItemsCount: Scalars["Int"]["output"];
  lineItemsWithInvalidFigures: Array<Account>;
  name: Scalars["String"]["output"];
  origin?: Maybe<Scenario>;
  outputTables: Array<OutputTable>;
  permissions: ScenarioPermission;
  profitAndLossWorkbookId?: Maybe<Scalars["String"]["output"]>;
  rollupWorkbookId?: Maybe<Scalars["String"]["output"]>;
  scheduledForDeletion: Scalars["Boolean"]["output"];
  unitTables: Array<UnitTable>;
  variant: ScenarioVariant;
  workbooks: Array<Workbook>;
};

/** Analyze two scenario comparisons */
export type ScenarioAnalysisInput = {
  displayColumns: Array<OutputTableViewOptionDisplayColumn>;
  leftComparisonType: OutputTableViewOptionComparisonType;
  leftScenarioComparisonId: Scalars["ID"]["input"];
  rightComparisonType: OutputTableViewOptionComparisonType;
  rightScenarioComparisonId: Scalars["ID"]["input"];
  styles: Array<OutputTableViewOptionComparisonStyle>;
};

export enum ScenarioComparisonBookmark {
  Actuals = "ACTUALS",
  Source = "SOURCE",
}

/** Comparison settings with a nullable scenarioId */
export type ScenarioComparisonInput = {
  displayColumns: Array<OutputTableViewOptionDisplayColumn>;
  id: Scalars["ID"]["input"];
  scenarioId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Permissions on Scenarios. */
export type ScenarioPermission = {
  __typename?: "ScenarioPermission";
  canCreateDashboards: Scalars["Boolean"]["output"];
  canCreateUnitTableCompanyIntegration: Scalars["Boolean"]["output"];
  canCreateWorkbooks: Scalars["Boolean"]["output"];
  canManageDriversTable: Scalars["Boolean"]["output"];
  canRead: Scalars["Boolean"]["output"];
  canReadPage: Scalars["Boolean"]["output"];
  canSaveScenarioView: Scalars["Boolean"]["output"];
  canScheduleForDeletion: Scalars["Boolean"]["output"];
  canUseAICopilot: Scalars["Boolean"]["output"];
  canViewDiagnoseSection: Scalars["Boolean"]["output"];
  canWrite: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
};

export type ScenarioShouldReloadPayload = {
  __typename?: "ScenarioShouldReloadPayload";
  companyId: Scalars["ID"]["output"];
  scenarioId: Scalars["ID"]["output"];
  /** The output of Date.now() */
  triggeredAt: Scalars["Float"]["output"];
};

export enum ScenarioVariant {
  Budget = "Budget",
  ReportingPackage = "ReportingPackage",
  Scenario = "Scenario",
}

export type SetScenarioLinksInput = {
  companyId: Scalars["ID"]["input"];
  dimensionLinks: Array<DimensionLinkInput>;
  lineItemLinks: Array<LineItemLinkInput>;
  srcScenarioId: Scalars["ID"]["input"];
  targetScenarioIds: Array<Scalars["ID"]["input"]>;
  unitTableLinks: Array<UnitTableLinkInput>;
};

export type Subscription = {
  __typename?: "Subscription";
  accountCreatedSubscription: Account;
  accountDeletedSubscription: Account;
  accountUpdatedForDashboard: Account;
  accountUpdatedRealTimeSubscription: Account;
  accountUpdatedSubscription: Account;
  adminCompanyCreatedSubscription: Company;
  adminCompanyUpdatedSubscription: Company;
  basisUserCompanyCreatedSubscription: BasisUserCompany;
  basisUserCompanyDeletedSubscription: BasisUserCompany;
  basisUserCompanyUpdatedSubscription: BasisUserCompany;
  basisUserScenarioCreated: BasisUserScenario;
  basisUserScenarioDeleted: BasisUserScenario;
  basisUserScenarioUpdated: BasisUserScenario;
  /** @deprecated Use scenario should reload instead */
  budgetMonthStatusUpdatedSubscription: BudgetMonthStatus;
  chartCreatedSubscription: Chart;
  chartDeletedSubscription: Chart;
  chartDocUpdatedSubscription: ChartDoc;
  chartUpdatedSubscription: Chart;
  cloudModelingJobCreatedSubscription: CloudModelingJob;
  cloudModelingJobUpdatedSubscription: CloudModelingJob;
  cloudModelingJobsFinishedSubscription?: Maybe<Scalars["Void"]["output"]>;
  companyCreatedSubscription: Company;
  companyIntegrationCreatedSubscription: CompanyIntegration;
  companyIntegrationDeletedSubscription: CompanyIntegration;
  companyIntegrationUpdatedSubscription: CompanyIntegration;
  companyUpdatedSubscription: Company;
  dashboardCreatedSubscription: Dashboard;
  dashboardDeletedSubscription: Dashboard;
  dashboardDocUpdatedSubscription: DashboardDoc;
  dashboardScenarioComparisonsUpdatedSubscription: Dashboard;
  dashboardUpdatedSubscription: Dashboard;
  dimensionCreatedSubscription: Dimension;
  dimensionDeletedSubscription: Dimension;
  dimensionUpdatedSubscription: Dimension;
  onboardingUpdatedSubscription: Onboarding;
  outputTableDimensionChangedSubscription: Dimension;
  outputTableDimensionGroupingsUpdatedSubscription: OutputTable;
  outputTableInsightsChanged: OutputTableInsights;
  outputTableLineItemCreatedSubscription: OutputTableLineItem;
  outputTableLineItemDeletedSubscription: OutputTableLineItem;
  outputTableLineItemUpdatedSubscription: OutputTableLineItem;
  outputTableRowChangesV2: V2_1_OutputTableRowChanges;
  outputTableUpdatedSubscription: OutputTable;
  outputTableViewOptionsUpdatedSubscription: OutputTableViewOption;
  scenarioCreated: Scenario;
  scenarioShouldReload?: Maybe<ScenarioShouldReloadPayload>;
  scenarioUpdated: Scenario;
  unitTableCompanyIntegrationCreatedSubscription: UnitTableCompanyIntegration;
  unitTableCompanyIntegrationDeletedSubscription: UnitTableCompanyIntegration;
  unitTableCompanyIntegrationUpdatedSubscription: UnitTableCompanyIntegration;
  unitTableCreatedSubscription: UnitTable;
  unitTableDeletedSubscription: UnitTable;
  unitTableLineItemDimensionCreatedSubscription: UnitTableLineItemDimension;
  unitTableLineItemDimensionUpdatedSubscription: UnitTableLineItemDimension;
  unitTableLineItemIntegrationAccountCreatedSubscription: UnitTableLineItemIntegrationAccount;
  unitTableLineItemIntegrationAccountDeletedSubscription: UnitTableLineItemIntegrationAccount;
  unitTableLineItemIntegrationAccountUpdatedSubscription: UnitTableLineItemIntegrationAccount;
  unitTableUpdatedSubscription: UnitTable;
  workbookCreatedSubscription: Workbook;
  workbookDeletedSubscription: Workbook;
  workbookUpdatedSubscription: Workbook;
};

export type SubscriptionAccountCreatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type SubscriptionAccountDeletedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type SubscriptionAccountUpdatedForDashboardArgs = {
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionAccountUpdatedRealTimeSubscriptionArgs = {
  changedFields?: InputMaybe<Array<AccountUpdatedChangedField>>;
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type SubscriptionAccountUpdatedSubscriptionArgs = {
  changedFields?: InputMaybe<Array<AccountUpdatedChangedField>>;
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type SubscriptionBasisUserCompanyCreatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
};

export type SubscriptionBasisUserCompanyDeletedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
};

export type SubscriptionBasisUserCompanyUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
};

export type SubscriptionBasisUserScenarioCreatedArgs = {
  basisUserId: Scalars["ID"]["input"];
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionBasisUserScenarioDeletedArgs = {
  basisUserId: Scalars["ID"]["input"];
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionBasisUserScenarioUpdatedArgs = {
  basisUserId: Scalars["ID"]["input"];
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionBudgetMonthStatusUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionChartCreatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
};

export type SubscriptionChartDeletedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
};

export type SubscriptionChartDocUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionChartUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
};

export type SubscriptionCloudModelingJobCreatedSubscriptionArgs = {
  input: CloudModelingJobsInput;
};

export type SubscriptionCloudModelingJobUpdatedSubscriptionArgs = {
  input: CloudModelingJobsInput;
};

export type SubscriptionCloudModelingJobsFinishedSubscriptionArgs = {
  input: CloudModelingJobsInput;
};

export type SubscriptionCompanyIntegrationCreatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
};

export type SubscriptionCompanyIntegrationDeletedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
};

export type SubscriptionCompanyIntegrationUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
};

export type SubscriptionDashboardCreatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionDashboardDeletedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionDashboardDocUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionDashboardScenarioComparisonsUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionDashboardUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionDimensionCreatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type SubscriptionDimensionDeletedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type SubscriptionDimensionUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type SubscriptionOnboardingUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
};

export type SubscriptionOutputTableDimensionChangedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  outputTableId: Scalars["ID"]["input"];
};

export type SubscriptionOutputTableDimensionGroupingsUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  outputTableId: Scalars["ID"]["input"];
};

export type SubscriptionOutputTableInsightsChangedArgs = {
  input: OutputTableInsightsInput;
};

export type SubscriptionOutputTableLineItemCreatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  outputTableId?: InputMaybe<Scalars["ID"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionOutputTableLineItemDeletedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  outputTableId?: InputMaybe<Scalars["ID"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionOutputTableLineItemUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  outputTableId?: InputMaybe<Scalars["ID"]["input"]>;
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionOutputTableRowChangesV2Args = {
  companyId: Scalars["ID"]["input"];
  options: V2_OutputTableInputOptions;
  outputTableId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableOptions: Array<V2_OutputTableRowChangeUnitTableOptions>;
};

export type SubscriptionOutputTableUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionOutputTableViewOptionsUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  outputTableId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionScenarioCreatedArgs = {
  companyId: Scalars["ID"]["input"];
};

export type SubscriptionScenarioShouldReloadArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionScenarioUpdatedArgs = {
  companyId: Scalars["ID"]["input"];
};

export type SubscriptionUnitTableCompanyIntegrationCreatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
};

export type SubscriptionUnitTableCompanyIntegrationDeletedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
};

export type SubscriptionUnitTableCompanyIntegrationUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type SubscriptionUnitTableCreatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionUnitTableDeletedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionUnitTableLineItemDimensionCreatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type SubscriptionUnitTableLineItemDimensionUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type SubscriptionUnitTableLineItemIntegrationAccountCreatedSubscriptionArgs =
  {
    companyId: Scalars["ID"]["input"];
    unitTableId: Scalars["ID"]["input"];
  };

export type SubscriptionUnitTableLineItemIntegrationAccountDeletedSubscriptionArgs =
  {
    companyId: Scalars["ID"]["input"];
    unitTableId: Scalars["ID"]["input"];
  };

export type SubscriptionUnitTableLineItemIntegrationAccountUpdatedSubscriptionArgs =
  {
    companyId: Scalars["ID"]["input"];
    unitTableId: Scalars["ID"]["input"];
  };

export type SubscriptionUnitTableUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionWorkbookCreatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionWorkbookDeletedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type SubscriptionWorkbookUpdatedSubscriptionArgs = {
  companyId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

/** Represents a sync error from the upstream integration. */
export type SyncError = {
  __typename?: "SyncError";
  currentStatus: Scalars["String"]["output"];
  dataType: Scalars["String"]["output"];
  lastSuccessfulSync?: Maybe<Scalars["DateTime"]["output"]>;
  latestSuccessfulSyncId?: Maybe<Scalars["ID"]["output"]>;
  latestSyncId?: Maybe<Scalars["ID"]["output"]>;
};

export enum TimeSeriesGroupingType {
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Yearly = "Yearly",
}

/** Accounting Tracking Categories */
export type TrackingCategory = {
  __typename?: "TrackingCategory";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  parentId?: Maybe<Scalars["ID"]["output"]>;
};

export type UnitTable = {
  __typename?: "UnitTable";
  allowedIntegrationTypes: Array<IntegrationType>;
  childUnitTables: Array<UnitTable>;
  company: Company;
  companyId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  defaultFormatType: FigureType;
  defaultPeriodValueType: AccountPeriodValueType;
  defaultVarianceDirection: VarianceDirection;
  derivedUnitTables: Array<UnitTable>;
  dimensions: Array<Dimension>;
  id: Scalars["ID"]["output"];
  isCompanyRollup: Scalars["Boolean"]["output"];
  lineItems: Array<Account>;
  name: Scalars["String"]["output"];
  parentUnitTable?: Maybe<UnitTable>;
  permissions: UnitTablePermission;
  position: Scalars["Decimal"]["output"];
  primaryUnitTable?: Maybe<UnitTable>;
  primaryUnitTableId?: Maybe<Scalars["String"]["output"]>;
  rollupLineItems: Array<Account>;
  scenarioId: Scalars["String"]["output"];
  templateName?: Maybe<UnitTableTemplateName>;
  totalLineItems: Scalars["Int"]["output"];
  type: UnitTableType;
  /** @deprecated Use dimensions instead */
  unitTableDimensions: Array<UnitTableDimension>;
  unitTableLineItemIntegrationAccountMappings: Array<UnitTableLineItemIntegrationAccount>;
  workbook: Workbook;
  workbookId: Scalars["String"]["output"];
};

export type UnitTableLineItemsArgs = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Unit Table access rights */
export type UnitTableAccessRight = {
  __typename?: "UnitTableAccessRight";
  accessRights: BasisUserAccessRightType;
  unitTableId: Scalars["ID"]["output"];
};

export type UnitTableCandidateLink = {
  __typename?: "UnitTableCandidateLink";
  candidate: UnitTable;
  link?: Maybe<UnitTable>;
};

export type UnitTableCompanyIntegration = {
  __typename?: "UnitTableCompanyIntegration";
  companyId: Scalars["String"]["output"];
  companyIntegrationId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  permissions: UnitTableCompanyIntegrationPermission;
  scenarioId: Scalars["String"]["output"];
  unitTableId: Scalars["String"]["output"];
};

/** Permissions on Unit Table Company Integration. */
export type UnitTableCompanyIntegrationPermission = {
  __typename?: "UnitTableCompanyIntegrationPermission";
  canDelete: Scalars["Boolean"]["output"];
  canRead: Scalars["Boolean"]["output"];
  canUpdate: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
};

export type UnitTableDimension = {
  __typename?: "UnitTableDimension";
  companyId: Scalars["String"]["output"];
  dimension: Dimension;
  dimensionId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  position: Scalars["Decimal"]["output"];
  unitTableDimensionUserState: Array<UnitTableDimensionUserState>;
  unitTableId: Scalars["String"]["output"];
};

/** An entire output tables grouping rules */
export type UnitTableDimensionGrouping = {
  dimensionId: Scalars["ID"]["input"];
  groupByOrder: Scalars["Int"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type UnitTableDimensionUserState = {
  __typename?: "UnitTableDimensionUserState";
  basisUser: BasisUser;
  groupByOrder?: Maybe<Scalars["Decimal"]["output"]>;
  id: Scalars["ID"]["output"];
  isGroupedBy: Scalars["Boolean"]["output"];
  isVisible: Scalars["Boolean"]["output"];
  unitTableDimension: UnitTableDimension;
};

export type UnitTableDimensionValueUpdate = {
  dimensionId: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
  unitTableLineItemId: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type UnitTableLineItemDimension = {
  __typename?: "UnitTableLineItemDimension";
  companyId: Scalars["String"]["output"];
  dimension: Dimension;
  dimensionId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  permissions: UnitTableLineItemDimensionPermission;
  scenarioId: Scalars["String"]["output"];
  unitTableLineItemId: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

/** Permissions on UnitTableLineItemDimension. */
export type UnitTableLineItemDimensionPermission = {
  __typename?: "UnitTableLineItemDimensionPermission";
  canRead: Scalars["Boolean"]["output"];
  canWrite: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
};

export type UnitTableLineItemIntegrationAccount = {
  __typename?: "UnitTableLineItemIntegrationAccount";
  companyId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  integrationAccount: IntegrationAccount;
  unitTableLineItem: Account;
  unitTableLineItemId: Scalars["String"]["output"];
};

export type UnitTableLineItemIntegrationAccountMapping = {
  integrationAccountId: Scalars["ID"]["input"];
  unitTableLineItemId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UnitTableLinkInput = {
  isHidden: Scalars["Boolean"]["input"];
  srcId: Scalars["ID"]["input"];
  targetIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

/** Permissions on Unit Tables. */
export type UnitTablePermission = {
  __typename?: "UnitTablePermission";
  canRead: Scalars["Boolean"]["output"];
  canReadPage: Scalars["Boolean"]["output"];
  canWrite: Scalars["Boolean"]["output"];
  canWriteDimensions: Scalars["Boolean"]["output"];
  canWriteFigures: Scalars["Boolean"]["output"];
  canWriteIntegrations: Scalars["Boolean"]["output"];
  canWriteLineItems: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
};

export type UnitTableScenarioLink = {
  __typename?: "UnitTableScenarioLink";
  id: Scalars["ID"]["output"];
  isHidden: Scalars["Boolean"]["output"];
  src: UnitTable;
  targets: Array<Maybe<UnitTable>>;
};

export enum UnitTableTemplateName {
  CategoriesStatement = "categories_statement",
  Contract = "contract",
  Contractor = "contractor",
  DeferredRevenue = "deferred_revenue",
  Employee = "employee",
  FixedAsset = "fixed_asset",
  PrePaidExpense = "pre_paid_expense",
  SalesCapacity = "sales_capacity",
  Schedule = "schedule",
}

export enum UnitTableType {
  Assumptions = "Assumptions",
  Outputs = "Outputs",
  Primary = "Primary",
}

export type UpdateBasisUserDashboardStateArgs = {
  dashboardEndDate?: InputMaybe<Scalars["String"]["input"]>;
  dashboardFocusMonth?: InputMaybe<Scalars["String"]["input"]>;
  dashboardIsFocusMonthRelative?: InputMaybe<Scalars["Boolean"]["input"]>;
  dashboardStartDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateDashboardAccessRightsArgs = {
  accessRights: BasisUserAccessRightType;
  dashboardId: Scalars["String"]["input"];
};

/** Config for dashboard scenario comparisons */
export type UpdateDashboardScenarioComparisonInputObject = {
  companyId: Scalars["ID"]["input"];
  dashboardId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  targetScenarioIds: Array<Scalars["ID"]["input"]>;
};

/** Config for output table scenario comparisons */
export type UpdateOutputTableScenarioComparisonInputObject = {
  companyId: Scalars["ID"]["input"];
  outputTableId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  targetScenarioIds: Array<Scalars["ID"]["input"]>;
};

export type UpdateUnitTableAccessRightsArgs = {
  accessRights: BasisUserAccessRightType;
  unitTableId: Scalars["String"]["input"];
};

export type UpdateWorkbookAccessRightsArgs = {
  accessRights: BasisUserAccessRightType;
  workbookId: Scalars["String"]["input"];
};

export enum V2_1_OutputTableCellTooltip {
  C = "c",
  D = "d",
}

/** Output table row cells */
export type V2_1_OutputTableCells = {
  __typename?: "V2_1_OutputTableCells";
  highlight: Array<Maybe<V2_1_OutputTableHighlight>>;
  isFresh: Array<Scalars["Boolean"]["output"]>;
  tooltip: Array<Maybe<V2_1_OutputTableCellTooltip>>;
  value: Array<Maybe<Scalars["String"]["output"]>>;
  value2: Array<Maybe<Scalars["String"]["output"]>>;
};

/** An output table column definition */
export type V2_1_OutputTableColDef = {
  __typename?: "V2_1_OutputTableColDef";
  field: Scalars["String"]["output"];
  headerName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  variant: V2_1_OutputTableColDefVariant;
};

export enum V2_1_OutputTableColDefVariant {
  Actual = "actual",
  Analysis = "analysis",
  AnalysisPercent = "analysisPercent",
  LegacySummary = "legacySummary",
  Projection = "projection",
  Stacked = "stacked",
}

/** An output table column group definition */
export type V2_1_OutputTableColGroupDef = {
  __typename?: "V2_1_OutputTableColGroupDef";
  children: Array<V2_1_OutputTableColDef>;
  headerName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  style: V2_1_OutputTableColGroupDefStyle;
};

export enum V2_1_OutputTableColGroupDefStyle {
  NonRolledOver = "nonRolledOver",
  QuarterlySummary = "quarterlySummary",
  RolledOver = "rolledOver",
  YearlySummary = "yearlySummary",
}

export enum V2_1_OutputTableHighlight {
  G = "g",
  R = "r",
}

export enum V2_1_OutputTableOperation {
  C = "c",
  D = "d",
  U = "u",
}

/** A collection of rows for an output table */
export type V2_1_OutputTableRowChanges = V2_1_OutputTableRowsInterface & {
  __typename?: "V2_1_OutputTableRowChanges";
  canRedirect: Array<Scalars["Boolean"]["output"]>;
  cellKeys: Array<Scalars["String"]["output"]>;
  cells: Array<Maybe<V2_1_OutputTableCells>>;
  companyId: Scalars["ID"]["output"];
  displayName: Array<Scalars["String"]["output"]>;
  figureType: Array<FigureType>;
  hasChildren: Array<Scalars["Boolean"]["output"]>;
  iconUrl: Array<Maybe<Scalars["String"]["output"]>>;
  id: Array<Scalars["ID"]["output"]>;
  isDisjoint: Array<Scalars["Boolean"]["output"]>;
  isMetric: Array<Scalars["Boolean"]["output"]>;
  operation: Array<Maybe<V2_1_OutputTableOperation>>;
  parentRowId: Array<Maybe<Scalars["ID"]["output"]>>;
  primaryUnitTableId: Array<Scalars["ID"]["output"]>;
  scenarioId: Array<Scalars["ID"]["output"]>;
  templateName: Array<Maybe<UnitTableTemplateName>>;
  unitTableId: Array<Scalars["ID"]["output"]>;
};

/** A collection of rows for an output table */
export type V2_1_OutputTableRows = V2_1_OutputTableRowsInterface & {
  __typename?: "V2_1_OutputTableRows";
  canRedirect: Array<Scalars["Boolean"]["output"]>;
  cellKeys: Array<Scalars["String"]["output"]>;
  cells: Array<Maybe<V2_1_OutputTableCells>>;
  companyId: Scalars["ID"]["output"];
  displayName: Array<Scalars["String"]["output"]>;
  figureType: Array<FigureType>;
  hasChildren: Array<Scalars["Boolean"]["output"]>;
  iconUrl: Array<Maybe<Scalars["String"]["output"]>>;
  id: Array<Scalars["ID"]["output"]>;
  isDisjoint: Array<Scalars["Boolean"]["output"]>;
  isMetric: Array<Scalars["Boolean"]["output"]>;
  parentRowId: Array<Maybe<Scalars["ID"]["output"]>>;
  primaryUnitTableId: Array<Scalars["ID"]["output"]>;
  scenarioId: Array<Scalars["ID"]["output"]>;
  templateName: Array<Maybe<UnitTableTemplateName>>;
  unitTableId: Array<Scalars["ID"]["output"]>;
};

export type V2_1_OutputTableRowsInterface = {
  canRedirect: Array<Scalars["Boolean"]["output"]>;
  cellKeys: Array<Scalars["String"]["output"]>;
  cells: Array<Maybe<V2_1_OutputTableCells>>;
  companyId: Scalars["ID"]["output"];
  displayName: Array<Scalars["String"]["output"]>;
  figureType: Array<FigureType>;
  hasChildren: Array<Scalars["Boolean"]["output"]>;
  iconUrl: Array<Maybe<Scalars["String"]["output"]>>;
  id: Array<Scalars["ID"]["output"]>;
  isDisjoint: Array<Scalars["Boolean"]["output"]>;
  isMetric: Array<Scalars["Boolean"]["output"]>;
  parentRowId: Array<Maybe<Scalars["ID"]["output"]>>;
  primaryUnitTableId: Array<Scalars["ID"]["output"]>;
  scenarioId: Array<Scalars["ID"]["output"]>;
  templateName: Array<Maybe<UnitTableTemplateName>>;
  unitTableId: Array<Scalars["ID"]["output"]>;
};

/** AccountId/DateFor coordinate for figure update */
export type V2_FigureFormulaUpdate = {
  accountId: Scalars["ID"]["input"];
  dateFor: Scalars["DateFor"]["input"];
};

/** Config for output table account rows */
export type V2_OutputTableAccountRowsInput = {
  companyId: Scalars["ID"]["input"];
  options: V2_OutputTableInputOptions;
  outputTableId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

/** Output table analysis between scenario comparisons */
export type V2_OutputTableAnalysisInput = {
  displayColumns: Array<OutputTableViewOptionDisplayColumn>;
  leftComparisonType: OutputTableViewOptionComparisonType;
  leftScenarioComparisonId: Scalars["ID"]["input"];
  rightComparisonType: OutputTableViewOptionComparisonType;
  rightScenarioComparisonId: Scalars["ID"]["input"];
  styles: Array<OutputTableViewOptionComparisonStyle>;
};

/** Config for output table column definitions */
export type V2_OutputTableColDefsInput = {
  companyId: Scalars["ID"]["input"];
  options: V2_OutputTableInputOptions;
  outputTableId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
};

export type V2_OutputTableInputOptions = {
  /** List of analyses between scenario comparisons */
  analyses: Array<V2_OutputTableAnalysisInput>;
  /** ISO date string for the start of the end range */
  dateRangeEnd: Scalars["String"]["input"];
  /** ISO date string for the start of the date range */
  dateRangeStart: Scalars["String"]["input"];
  /** Include column definitions for projections before the rollover date */
  includeHistoricalProjections: Scalars["Boolean"]["input"];
  runningTotalColumns: Array<OutputTableViewOptionRunningTotalDisplayColumn>;
  /** List of scenarios that should be compared with which display columns */
  scenarioComparisons: Array<V2_OutputTableScenarioComparisonInput>;
  /** Include column definitions for actuals after the rollover date up to the current date */
  useCurrentActuals: Scalars["Boolean"]["input"];
};

/** Describes how a unit table is grouped */
export type V2_OutputTableRowChangeUnitTableOptions = {
  groupKeys: Array<Scalars["ID"]["input"]>;
  unitTableId: Scalars["ID"]["input"];
};

/** Config for output table rows */
export type V2_OutputTableRowsInput = {
  companyId: Scalars["ID"]["input"];
  groupKeys: Array<Scalars["ID"]["input"]>;
  options: V2_OutputTableInputOptions;
  outputTableId: Scalars["ID"]["input"];
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

/** Output table scenario comparison */
export type V2_OutputTableScenarioComparisonInput = {
  bookmark?: InputMaybe<V2_OutputTableScenarioComparisonInputBookmark>;
  displayColumns: Array<OutputTableViewOptionDisplayColumn>;
  id: Scalars["ID"]["input"];
  targetScenarioId: Scalars["ID"]["input"];
};

export enum V2_OutputTableScenarioComparisonInputBookmark {
  Actuals = "ACTUALS",
  Source = "SOURCE",
}

/** Permissions on Unit Table Row Dimension Values */
export type V2_UnitTableRowDimensionValuePermissions = {
  __typename?: "V2_UnitTableRowDimensionValuePermissions";
  canWrite: Scalars["Boolean"]["output"];
};

/** Permissions on Unit Table Row Line Items */
export type V2_UnitTableRowLineItemPermissions = {
  __typename?: "V2_UnitTableRowLineItemPermissions";
  canWrite: Scalars["Boolean"]["output"];
};

export type V2_UnitTableRows = {
  __typename?: "V2_UnitTableRows";
  desiredVarianceDirection: VarianceDirection;
  displayName: Scalars["String"]["output"];
  figureType: FigureType;
  figures: Array<V2_UnitTableRowsFigures>;
  iconUrl?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isIntegration: Scalars["Boolean"]["output"];
  isMetric: Scalars["Boolean"]["output"];
  order: Scalars["String"]["output"];
  periodValueType: AccountPeriodValueType;
  permissions: V2_UnitTableRowLineItemPermissions;
  unitTableLineItemDimensions: Array<V2_UnitTableRowsDimensionValue>;
};

/** Unit Table Row Dimension Value */
export type V2_UnitTableRowsDimensionValue = {
  __typename?: "V2_UnitTableRowsDimensionValue";
  dimensionId: Scalars["ID"]["output"];
  id: Scalars["ID"]["output"];
  permissions: V2_UnitTableRowDimensionValuePermissions;
  value: Scalars["String"]["output"];
};

/** Permissions on Figures. */
export type V2_UnitTableRowsFigurePermissions = {
  __typename?: "V2_UnitTableRowsFigurePermissions";
  canWrite: Scalars["Boolean"]["output"];
};

/** Unit Table Row Figures */
export type V2_UnitTableRowsFigures = {
  __typename?: "V2_UnitTableRowsFigures";
  formula: Scalars["String"]["output"];
  formulaError?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isFresh: Scalars["Boolean"]["output"];
  permissions: V2_UnitTableRowsFigurePermissions;
  value?: Maybe<Scalars["String"]["output"]>;
};

/** Config for unit table rows */
export type V2_UnitTableRowsInput = {
  companyId: Scalars["ID"]["input"];
  options: V2_UnitTableRowsInputOptions;
  scenarioId: Scalars["ID"]["input"];
  unitTableId: Scalars["ID"]["input"];
};

export type V2_UnitTableRowsInputOptions = {
  /** ISO date string for the end of the end range */
  dateRangeEnd?: InputMaybe<Scalars["String"]["input"]>;
  /** ISO date string for the start of the date range */
  dateRangeStart?: InputMaybe<Scalars["String"]["input"]>;
  /** The number of line items to skip. I.E your current page */
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  /** How many line items to query per page */
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ValidationError = {
  __typename?: "ValidationError";
  errorMessage: Scalars["String"]["output"];
  errorType: Scalars["String"]["output"];
};

export enum VarianceDirection {
  Negative = "negative",
  Positive = "positive",
}

export type Workbook = {
  __typename?: "Workbook";
  company: Company;
  companyId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  isReady: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  outputTable: OutputTable;
  permissions: WorkbookPermissions;
  position: Scalars["Decimal"]["output"];
  scenarioId: Scalars["String"]["output"];
  template: WorkbookTemplate;
  unitTables: Array<UnitTable>;
};

/** Workbook access rights */
export type WorkbookAccessRight = {
  __typename?: "WorkbookAccessRight";
  accessRights: BasisUserAccessRightType;
  workbookId: Scalars["ID"]["output"];
};

/** Permissions on WorkbookPermissions */
export type WorkbookPermissions = {
  __typename?: "WorkbookPermissions";
  canCreateUnitTables: Scalars["Boolean"]["output"];
  canRead: Scalars["Boolean"]["output"];
  canReadPage: Scalars["Boolean"]["output"];
  canReadUnitTables: Scalars["Boolean"]["output"];
  canWrite: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
};

export enum WorkbookTemplate {
  BalanceSheet = "Balance_Sheet",
  Custom = "Custom",
  People = "People",
  ProfitAndLoss = "Profit_and_Loss",
}

export enum BasisAiCopilotStatus {
  Basic = "basic",
  Premium = "premium",
  PremiumWaitlist = "premium_waitlist",
}

export enum Direction {
  Backward = "backward",
  Forward = "forward",
}
