import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import { GetCouponsDocument, GetCouponsQuery, GetCouponsQueryVariables } from '@stigg/api-client-js/src/generated/sdk';

class CouponsApi {
  constructor(private readonly client: ApolloClient<NormalizedCacheObject>) {}

  getCoupons() {
    return this.client.query<GetCouponsQuery, GetCouponsQueryVariables>({
      query: GetCouponsDocument,
      fetchPolicy: 'no-cache',
    });
  }
}

export default CouponsApi;
