import { getAuth, User } from "firebase/auth";

export const waitForUser = (): Promise<User | undefined> =>
  new Promise((resolve) => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      unsubscribe();

      resolve(user || undefined);
    });
  });
