import { InMemoryCache } from "@apollo/client";

export const cache = new InMemoryCache({
  typePolicies: {
    Subscription: {
      fields: {
        basisUserCompanyUpdatedSubscription: {
          merge: true,
        },
      },
    },
    Workbook: {
      fields: {
        unitTables: {
          merge: false,
        },
      },
    },
    UnitTable: {
      fields: {
        childUnitTables: {
          merge: false,
        },
        lineItems: {
          merge: false,
        },
        unitTableDimensions: {
          merge: false,
        },
      },
    },
    OutputTable: {
      fields: {
        lineItems: {
          merge: false,
        },
      },
    },
    Company: {
      fields: {
        companyIntegrations: {
          merge: false,
        },
        unitTables: {
          merge: false,
        },
        workbooks: {
          merge: false,
        },
        accounts: {
          merge: false,
        },
        users: {
          merge: false,
        },
        dashboards: {
          merge: false,
        },
      },
    },
    CodatAccountingConfig: {
      fields: {
        codatConfigClassAccountingHierarchies: {
          merge: false,
        },
      },
    },
    Account: {
      fields: {
        integrationAccount: {
          merge: false,
        },
      },
    },
    Scenario: {
      fields: {
        accounts: {
          merge: false,
        },
        dashboards: {
          merge: false,
        },
      },
    },
    BasisUserCompany: {
      fields: {
        basisUserScenarios: {
          merge: false,
        },
      },
    },
  },
});
