import { lazy } from "react";

export type { SelfOnboardingSteps } from "./types";

export const SelfOnboardingSignInPage = lazy(
  () => import("./pages/self-onboarding-sign-in-page"),
);

export const SelfOnboardingLoggedInPage = lazy(
  () => import("./pages/self-onboarding-logged-in-page"),
);

export const SelfOnboardingAlreadyUserPage = lazy(
  () => import("./pages/self-onboarding-already-a-user-page"),
);

export const SelfOnboardingCreateCompanyPage = lazy(
  () => import("./pages/self-onboarding-create-company-page"),
);

export const SelfOnboardingConnectIntegrationPage = lazy(
  () => import("./pages/self-onboarding-connect-integration-page"),
);

export const SelfOnboardingExpiredPage = lazy(
  () => import("./pages/self-onboarding-expired-page"),
);

export const SelfOnboardingIntegrationCompanyReadyPage = lazy(
  () => import("./pages/self-onboarding-integration-company-ready-page"),
);
