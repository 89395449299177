import flagsmith from "flagsmith";

import { config } from "./config";

export const init = async (): Promise<void> => {
  await flagsmith.init({
    api: "https://flagsmith-api.basis.so/api/v1/",
    cacheFlags: true,
    enableAnalytics: true,
    environmentID: config.flagsmithKey,
  });
};

export const hasFeature = (key: keyof typeof featureFlags): boolean =>
  flagsmith.hasFeature(featureFlags[key]);

export const getFeatureValue = (
  key: keyof typeof featureFlags,
): string | number | boolean => flagsmith.getValue(featureFlags[key]);

export const setUser = async (userEmail: string): Promise<void> => {
  await flagsmith.identify(userEmail);
};

export const clearUser = async (): Promise<void> => {
  await flagsmith.logout();
};

const featureFlags = {
  SELF_SERVE_API_KEY: "8-17-2021-permanent-self-serve-api-key",
  SHOW_BASIS_ROLLUPS_IN_NAV_BAR: "03-24-2022-show-basis-rollups-in-nav-bar",
  ENABLE_PERMANENT_DEV_ONLY_UPDATE_PERMISSIONS:
    "07-26-2022-permanent-development-permissions-settings-tab",
  MAX_ACCOUNTING_CLASS_DEPTH_ALLOWED:
    "01-12-2023-accounting-integration-classes-max-depth-allowed",
  ENABLE_SCENARIOS: "01-17-2023-enable-scenarios",
  ENABLE_CREATE_NEW_SCENARIO: "06-22-2023-enable-create-new-scenario",
  ENABLE_STACKED_COLUMNS: "07-28-2023-enable-stacked-columns",
  ENABLE_DASHBOARD_RICH_EDITOR: "08-1-2023-enable-dashboard-rich-editor",
  ENABLE_ONE_CLICK_ROLLUPS: "09-18-2023-enable-one-click-rollups",
  ENABLE_AI_COPILOT: "02-22-2024-enable-ai-copilot",
  ENABLE_SELF_ONBOARDING_INTEGRATION_STEP:
    "03-26-2024-enable-self-onboarding-integration-step",
  ENABLE_BILLINGS: "4-13-2024-enable-billings",
} as const;
