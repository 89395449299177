import React, { FC, ReactNode, FormEventHandler } from "react";
import styled, { css } from "styled-components";

import ListItemButton from "@mui/material/ListItemButton";

import { toRem } from "@/utils/styled-components";

export type GroupButtonProps = {
  ariaLabel: string;
  startComponent?: ReactNode;
  endComponent?: ReactNode;
  className?: string;
  disableRipple?: boolean;
  disabled?: boolean;
  compact?: boolean;
  children?: React.ReactNode;
  onClick?: FormEventHandler<HTMLDivElement>;
  onSubmit?: FormEventHandler<HTMLDivElement>;
};

const StyledListItemButton = styled(ListItemButton)`
  align-items: stretch !important;
  border: 1px solid ${(props) => props.theme.palette.divider} !important;
  border-radius: ${toRem(4)} !important;
  color: ${(props) => props.theme.palette.text.primary} !important;
  font-family: ${(props) => props.theme.typography.fontFamilies.neuehaas};
  font-size: ${(props) => props.theme.typography.body1.fontSize};
  min-height: ${toRem(40)} !important;
  padding: 0 !important;
  text-transform: inherit !important;

  ${(props) =>
    props.disabled &&
    css`
      background-color: rgb(250, 250, 250) !important;
    `}

  :hover, :focus {
    background-color: transparent !important;
    border-color: ${(props) => props.theme.palette.primary.main} !important;
  }
`;

const PrimaryComponent = styled.div<{ compact?: boolean }>`
  align-items: center;
  display: flex;
  flex: 1 1;
  font-family: ${(props) => props.theme.typography.fontFamilies.neuehaas};
  min-height: 100%;
  overflow: hidden;
  padding: ${toRem(6)} ${toRem(12)};
  padding-left: ${(props) => (props.compact ? toRem(0) : toRem(6))};
`;

const StartComponent = styled.div<{ compact?: boolean }>`
  align-items: center;
  border-right: ${(props) =>
    props.compact ? "none" : `1px solid ${props.theme.palette.divider}`};
  display: flex;
  justify-content: center;
  min-height: 100%;
  padding: ${toRem(6)} ${toRem(12)};
`;

const EndComponent = styled.div`
  align-items: center;
  display: flex;
  min-height: 100%;
  padding: ${toRem(6)} ${toRem(12)};
`;

const GroupButton: FC<GroupButtonProps> = (props): JSX.Element => (
  // Span wrapper enables tooltips on disabled MUI buttons https://mui.com/material-ui/react-tooltip/#disabled-elements
  <span>
    <StyledListItemButton
      aria-label={props.ariaLabel}
      className={props.className}
      disabled={props.disabled}
      disableRipple={props.disableRipple}
      onClick={props.onClick}
      onSubmit={props.onSubmit}
    >
      {props.startComponent && (
        <StartComponent compact={props.compact}>
          {props.startComponent}
        </StartComponent>
      )}
      <PrimaryComponent compact={props.compact}>
        {props.children}
      </PrimaryComponent>
      {props.endComponent && <EndComponent>{props.endComponent}</EndComponent>}
    </StyledListItemButton>
  </span>
);

export { GroupButton };
