import {
  GetCheckoutStateDocument,
  GetCheckoutStateQuery,
  GetCheckoutStateQueryVariables,
  ApplySubscriptionDocument,
  ApplySubscriptionMutation,
  ApplySubscriptionMutationVariables,
} from '@stigg/api-client-js/src/generated/sdk';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import { ApplySubscription, GetCheckoutState, SubscriptionBillingInfo } from '../models';

export type ApplySubscriptionParams = ApplySubscription & { customerId: string };

export type GetCheckoutStateParams = GetCheckoutState & { customerId: string };

class SubscriptionsApi {
  constructor(private readonly client: ApolloClient<NormalizedCacheObject>) {}

  applySubscription({
    customerId,
    planId,
    paymentMethodId,
    addons,
    billableFeatures,
    billingInformation,
    billingCountryCode,
    billingId,
    billingPeriod,
    metadata,
    promotionCode,
    resourceId,
    skipTrial,
    startDate,
    unitQuantity,
    scheduleStrategy,
  }: ApplySubscriptionParams) {
    return this.client.mutate<ApplySubscriptionMutation, ApplySubscriptionMutationVariables>({
      mutation: ApplySubscriptionDocument,
      variables: {
        input: {
          customerId,
          planId,
          paymentMethodId,
          startDate,
          addons,
          billingId,
          billingPeriod,
          unitQuantity,
          billableFeatures,
          additionalMetaData: metadata,
          resourceId,
          skipTrial,
          promotionCode,
          billingInformation: this.mapBillingInformation(billingInformation),
          billingCountryCode,
          scheduleStrategy,
        },
      },
    });
  }

  checkoutState({ customerId, resourceId, planId, billingCountryCode }: GetCheckoutStateParams) {
    return this.client.query<GetCheckoutStateQuery, GetCheckoutStateQueryVariables>({
      query: GetCheckoutStateDocument,
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          planId,
          customerId,
          ...(resourceId ? { resourceId } : {}),
          ...(billingCountryCode ? { billingCountryCode } : {}),
        },
      },
    });
  }

  private mapBillingInformation(
    billingInformation: SubscriptionBillingInfo | undefined,
  ): ApplySubscriptionMutationVariables['input']['billingInformation'] {
    if (!billingInformation) {
      return undefined;
    }
    return {
      taxRateIds: billingInformation?.taxRateIds,
      billingAddress: billingInformation?.billingAddress,
    };
  }
}

export default SubscriptionsApi;
