import "@/utils/init-ag-grid";

import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { excludeGraphQLFetch } from "apollo-link-sentry";
import { enableAllPlugins } from "immer";
import React, { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { IntercomProvider } from "react-use-intercom";

import { StiggProvider } from "@stigg/react-sdk";

import GlobalStyle from "@/components/global-style";
import { ThemeProvider } from "@/components/theme-provider";
import { Init } from "@/init";
import { config } from "@/utils/config";
import { createApolloClient } from "@/utils/create-apollo-client";
import * as features from "@/utils/features";
import * as firebase from "@/utils/firebase";

import { AuthProvider } from "./hooks/use-auth";

Sentry.init({
  dsn: config.sentry.dsn,
  environment: config.appEnv,
  normalizeDepth: 10,
  release: config.sentry.release,
  beforeBreadcrumb: excludeGraphQLFetch,
});

void features.init();
firebase.initialize();
enableAllPlugins();

const renderSuspenseFallback = () => <div />;

export const App = (): JSX.Element => (
  <Sentry.ErrorBoundary>
    <React.StrictMode>
      <GlobalStyle />

      <ThemeProvider>
        <ApolloProvider client={createApolloClient()}>
          <AuthProvider>
            <HelmetProvider>
              <IntercomProvider
                appId="nugv1m38"
                autoBoot
                autoBootProps={{ hideDefaultLauncher: true }}
                shouldInitialize={config.enableIntercom}
              >
                <StiggProvider apiKey={config.stiggClientKey}>
                  <Suspense fallback={renderSuspenseFallback()}>
                    <Init />
                  </Suspense>
                </StiggProvider>
              </IntercomProvider>
            </HelmetProvider>
          </AuthProvider>
        </ApolloProvider>
      </ThemeProvider>
    </React.StrictMode>
  </Sentry.ErrorBoundary>
);
