import { getAuth, User } from "firebase/auth";

import * as analytics from "@/utils/analytics";
import * as apiAuth from "@/utils/api/auth";
import * as firebase from "@/utils/firebase";
import { ApiUser } from "@/utils/api/apiSchema";

export const authExisting = async (): Promise<ApiUser> => {
  const firebaseUser = await firebase.waitForUser();

  if (!firebaseUser) throw new Error("No existing user");

  const firebaseIdToken = await firebaseUser.getIdToken();
  const apiAuthLoginResult = await apiAuth.login(firebaseIdToken);

  analytics.setUser({ user: apiAuthLoginResult.user });
  return apiAuthLoginResult.user;
};

export const signUpWithAnalytics = async (): Promise<ApiUser> => {
  const firebaseUser = await firebase.waitForUser();

  if (!firebaseUser) throw new Error("User does not exist in Firebase");

  const firebaseIdToken = await firebaseUser.getIdToken();
  const apiAuthSignUpResult = await apiAuth.signUp(firebaseIdToken);

  analytics.setUser({ user: apiAuthSignUpResult.user });

  return apiAuthSignUpResult.user;
};

export const signOut = async (): Promise<void> => {
  analytics.clearUser();
  await firebase.signOut();
};

export const ensureAuthed = (): User => {
  const { currentUser } = getAuth();

  if (!currentUser) {
    throw new Error("No existing user");
  }

  return currentUser;
};
