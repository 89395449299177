const enableIntercom = () => {
  switch (window.BASIS_APP_ENV) {
    case "production":
      return true;
    case "staging":
      return false;
    default:
      return !process.env["REACT_APP_DISABLE_INTERCOM"];
  }
};

/*
  When adding a new environment variable, make sure to also add it to the following files:

  - clients/deployment/k8s/web/overlays/staging/staging.properties
  - clients/deployment/k8s/web/overlays/production/production.properties
*/

export const config = {
  agGridKey: window.BASIS_AG_GRID_KEY,
  apiUrl: process.env["REACT_APP_API_URL"] || window.BASIS_API_URL,
  appEnv: window.BASIS_APP_ENV,
  firebase: {
    apiKey: window.BASIS_FIREBASE_API_KEY,
    appId: window.BASIS_FIREBASE_APP_ID,
    authDomain: window.BASIS_FIREBASE_AUTH_DOMAIN,
    databaseUrl: window.BASIS_FIREBASE_DATABASE_URL,
    messagingSenderId: window.BASIS_FIREBASE_MESSAGING_SENDER_ID,
    projectId: window.BASIS_FIREBASE_PROJECT_ID,
    storageBucket: window.BASIS_FIREBASE_STORAGE_BUCKET,
  },
  flagsmithKey: window.BASIS_FLAGSMITH_KEY,
  graphqlHttp:
    process.env["REACT_APP_GRAPHQL_HTTP"] || window.BASIS_GRAPHQL_HTTP,
  graphqlWs: process.env["REACT_APP_GRAPHQL_WS"] || window.BASIS_GRAPHQL_WS,
  whyDidYouRender:
    process.env.NODE_ENV === "development" &&
    Boolean(process.env["REACT_APP_WHY_DID_YOU_RENDER"]),
  sentry: {
    dsn: process.env["REACT_APP_SENTRY_DSN"] || window.BASIS_SENTRY_DSN,
    release: process.env["REACT_APP_VERSION"],
  },
  tracingLogs: Boolean(process.env["REACT_APP_TRACING_LOGS"]) || false,
  enableIntercom: enableIntercom(),
  stiggClientKey: window.BASIS_STIGG_CLIENT_API_KEY,
  stiggHighlightedPlanId: window.BASIS_STIGG_HIGHLIGHTED_PLAN_ID,
};
