import { SDK_SOURCE_TYPE } from '../configuration';
import { ApolloQueryResult } from '@apollo/client/core';
import {
  GetEntitlementsQuery,
  GetPaywallQuery,
  GetSdkConfigurationQuery,
} from '@stigg/api-client-js/src/generated/sdk';
import { ObservablePoller } from '../utils/ObservablePoller';
import { fetchWithRetry } from '../utils/fetch';

type EdgeApiClientConfiguration = {
  apiKey: string;
  baseEdgeUri: string;
  enableEdge: boolean;
};

export class EdgeApiClient {
  private constructor(
    private readonly config: EdgeApiClientConfiguration,
    private readonly dynamicData: { customerToken?: string | null },
  ) {}

  static create(
    config: EdgeApiClientConfiguration,
    dynamicData: { customerToken?: string | null },
  ): EdgeApiClient | null {
    return config.enableEdge ? new EdgeApiClient(config, dynamicData) : null;
  }

  getPaywall(productId?: string, billingCountryCode?: string, includeHiddenPlans?: boolean) {
    const prefix = productId ? `/p/${productId}` : '';
    const billingCountryCodeQueryParam = billingCountryCode ? `billingCountryCode=${billingCountryCode}` : '';
    const includeHiddenPlansQueryParam = includeHiddenPlans ? `&includeHiddenPlans=${includeHiddenPlans}` : '';

    return this.get<GetPaywallQuery>(
      `/v1${prefix}/paywall.json?${billingCountryCodeQueryParam}${includeHiddenPlansQueryParam}`,
    );
  }

  getEntitlements(customerId: string, resourceId: string | undefined) {
    const optionalResourceQueryParam = resourceId ? `?resourceId=${resourceId}` : '';
    return this.get<GetEntitlementsQuery>(`/v1/c/${customerId}/entitlements.json${optionalResourceQueryParam}`);
  }

  getSdkConfiguration() {
    return this.get<GetSdkConfigurationQuery>(`/v1/config/client-sdk-configuration.json`);
  }

  watchEntitlements(customerId: string, interval: number, resourceId: string | undefined) {
    return new ObservablePoller<GetEntitlementsQuery>(() => this.getEntitlements(customerId, resourceId), interval);
  }

  private async get<T>(url: string) {
    const headers = new Headers();
    headers.append('X-API-KEY', this.config.apiKey);
    headers.append('X-API-VERSION', '1');
    headers.append('source', SDK_SOURCE_TYPE);

    // customer may be changed overtime, so fetching it before every query
    const { customerToken } = this.dynamicData;
    if (customerToken) {
      headers.append('X-CUSTOMER-KEY', customerToken);
    }

    const resp = await fetchWithRetry(`${this.config.baseEdgeUri}${url}`, { headers, method: 'GET' });
    if (!resp.ok) {
      const error = new Error(`Failed to fetch data from Edge. Reason: ${resp.statusText}`);
      (error as Error & { response: Response }).response = resp;
      throw error;
    }

    const data = await resp.json();
    return data as ApolloQueryResult<T>;
  }
}
