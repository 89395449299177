import * as Sentry from "@sentry/react";

export const reportError = (
  message?: unknown,
  ...optionalParams: unknown[]
): void => {
  // eslint-disable-next-line no-console
  console.error(message, ...optionalParams);

  if (
    window.BASIS_APP_ENV === "production" ||
    window.BASIS_APP_ENV === "staging"
  ) {
    Sentry.captureException(message);
  }
};
