import includes from 'lodash/includes';

export interface Logger {
  log: (message: string, object?: Record<string, string>) => void;
  debug: (message: string, object?: Record<string, string>) => void;
  error: (message: string, error?: Record<string, string>) => void;
}

export interface LogConfiguration {
  logger: Logger;
  logLevel: LogLevel;
}

export enum LogLevel {
  ALL = 'ALL',
  NONE = 'NONE',
  LOG = 'LOG',
  ERROR = 'ERROR',
}

export class LoggerService implements Logger {
  constructor(private readonly logger: Logger, private readonly logLevel: LogLevel) {}
  error(message: string, error?: Record<string, string>): void {
    if (this.logLevel !== LogLevel.NONE) {
      this.logger.error(`[Stigg] ${message}`, error || {});
    }
  }

  log(message: string, object?: Record<string, string>): void {
    if (!includes([LogLevel.ERROR, LogLevel.NONE], this.logLevel)) {
      this.logger.log(`[Stigg] ${message}`, object || {});
    }
  }

  debug(message: string, object?: Record<string, string> | undefined): void {
    if (includes([LogLevel.LOG, LogLevel.ALL], this.logLevel)) {
      this.logger.debug(`[Stigg] ${message}`, object || {});
    }
  }
}
