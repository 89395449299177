import { OrganizationType } from "../types";

type CompanySizeData = {
  segment: "accounting_firm" | "direct_company" | "other";
  account_size: string;
};

export const getCompanySizeDataFromType = (
  orgType: OrganizationType,
): CompanySizeData => {
  switch (orgType) {
    case OrganizationType.SmallCompany:
      return { segment: "direct_company", account_size: "1-50" };
    case OrganizationType.MiddleCompany:
      return { segment: "direct_company", account_size: "50+" };
    case OrganizationType.SmallAccountingFirm:
      return { segment: "accounting_firm", account_size: "1-5" };
    case OrganizationType.MiddleAccountingFirm:
      return { segment: "accounting_firm", account_size: "5-20" };
    case OrganizationType.LargeAccountingFirm:
      return { segment: "accounting_firm", account_size: "20-100" };
    case OrganizationType.ExtraLargeAccountingFirm:
      return { segment: "accounting_firm", account_size: "100+" };
    case OrganizationType.Other:
      return { segment: "other", account_size: "-1" };
  }
};
