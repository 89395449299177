import React, { FC } from "react";
import styled from "styled-components";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export type SpinnerProps = {
  size?: "small" | "medium" | "large";
  color?:
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "inherit";
};

const StyledBox = styled(Box)`
  display: flex;
`;

const spinnerSizes = {
  small: 12,
  medium: 24,
  large: 48,
};

const defaultSize = "medium";
const defaultColor = "primary";

const Spinner: FC<SpinnerProps> = (props) => (
  <StyledBox>
    <CircularProgress
      color={props.color || defaultColor}
      size={spinnerSizes[props.size ?? defaultSize]}
      thickness={5}
    />
  </StyledBox>
);

export { Spinner };
