type Rgb = readonly [number, number, number];

type Rgba = readonly [number, number, number, number];

const getCssColorUsing = (rgbOrRgba: Rgb | Rgba, keyword: string) =>
  `${keyword}(${rgbOrRgba.join(", ")})`;

export const getCssColor = (rgbOrRgba: Rgb | Rgba): string =>
  rgbOrRgba.length === 4
    ? getCssColorUsing(rgbOrRgba, "rgba")
    : getCssColorUsing(rgbOrRgba, "rgb");
