import {
  ToggleButton as MuiToggleButton,
  ToggleButtonProps as MuiToggleButtonProps,
} from "@mui/material";
import { FC, forwardRef } from "react";
import styled from "styled-components";

import { toRem } from "@/utils/styled-components";

export type ToggleButtonProps = MuiToggleButtonProps;

const StyledMuiToggleButton = styled.span`
  .MuiButtonBase-root {
    border-radius: ${toRem(4)} !important;
    border: none;
    :hover {
      background-color: ${(props) =>
        props.theme.palette.primary.light} !important;
      color: ${(props) => props.theme.palette.text.primary} !important;
    }
  }
  .MuiToggleButton-sizeSmall {
    padding: ${toRem(9)};
  }
  .Mui-selected {
    background-color: ${(props) => props.theme.palette.primary.ultraLight};
  }
`;

const ToggleButton: FC<ToggleButtonProps> = forwardRef(
  (props, ref): JSX.Element => {
    const { children, ...other } = props;
    return (
      <StyledMuiToggleButton>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <MuiToggleButton ref={ref} {...other}>
          {children}
        </MuiToggleButton>
      </StyledMuiToggleButton>
    );
  },
);

export { ToggleButton };
