import {
  GetPaywallDocument,
  GetPaywallQuery,
  GetPaywallQueryVariables,
  WidgetType,
} from '@stigg/api-client-js/src/generated/sdk';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import { EdgeApiClient } from './EdgeApiClient';

export type GetPaywallProps = {
  productId?: string;
  customerId?: string | null;
  resourceId?: string | null;
  context?: WidgetType;
  billingCountryCode?: string;
  includeHiddenPlans?: boolean;
};

class PaywallApi {
  constructor(
    private readonly client: ApolloClient<NormalizedCacheObject>,
    private readonly edgeApiClient: EdgeApiClient | null,
  ) {}

  getPaywall({ productId, customerId, resourceId, context, billingCountryCode, includeHiddenPlans }: GetPaywallProps) {
    // fetching paywall for public paywall from edge-api instead of graphql-api
    if (!customerId && this.edgeApiClient) {
      return this.edgeApiClient.getPaywall(productId, billingCountryCode, includeHiddenPlans);
    }

    return this.client.query<GetPaywallQuery, GetPaywallQueryVariables>({
      query: GetPaywallDocument,
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          ...(productId ? { productId } : {}),
          ...(customerId ? { customerId } : {}),
          ...(resourceId ? { resourceId } : {}),
          ...(context ? { context } : {}),
          ...(billingCountryCode ? { billingCountryCode } : {}),
          ...(includeHiddenPlans ? { includeHiddenPlans } : {}),
        },
      },
    });
  }
}

export default PaywallApi;
