import {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";

export type ColorPaletteType = (props: { theme: DefaultTheme }) => {
  default: string;
  hover: string;
  active: string;
  disabled: string;
  textColor: string;
  disabledTextColor: string;
  hoverTextColor: string;
  hoverTextBackgroundColor: string;
  activeTextBackgroundColor: string;
};

export type VariantsType = keyof typeof variants;

export type StyledBaseButtonProps = {
  disabled?: boolean;
  $variant?: VariantsType;
};

const variants = {
  contained: "contained",
  outlined: "outlined",
  text: "text",
} as const;

const defaultVariant = variants.contained;

const getVariantCss = (
  colorPalette: ColorPaletteType,
): readonly [
  (
    props: StyledBaseButtonProps,
  ) => FlattenInterpolation<ThemeProps<DefaultTheme>> | undefined,
  (
    props: StyledBaseButtonProps,
  ) => FlattenInterpolation<ThemeProps<DefaultTheme>> | undefined,
] => {
  const containedButtonCss = css`
    background-color: ${(props) => colorPalette(props).default} !important;
    color: rgb(255, 255, 255) !important;

    &:hover {
      background-color: ${(props) => colorPalette(props).hover} !important;
    }

    &:active,
    &:focus {
      background-color: ${(props) => colorPalette(props).active} !important;
    }
  `;

  const outlinedButtonCss = css`
    background-color: transparent !important;
    border-color: ${(props) => colorPalette(props).default} !important;
    color: ${(props) => colorPalette(props).default} !important;

    &:hover {
      border-color: ${(props) => colorPalette(props).hover} !important;
      color: ${(props) => colorPalette(props).hover} !important;
    }

    &:active,
    &:focus {
      border-color: ${(props) => colorPalette(props).active} !important;
      color: ${(props) => colorPalette(props).active} !important;
    }
  `;

  const textButtonCss = css`
    background-color: transparent !important;
    color: ${(props) => colorPalette(props).textColor} !important;

    &:hover {
      background-color: ${(props) =>
        colorPalette(props).hoverTextBackgroundColor} !important;
      color: ${(props) => colorPalette(props).hoverTextColor} !important;
    }

    &:active,
    &:focus {
      background-color: ${(props) =>
        colorPalette(props).activeTextBackgroundColor} !important;
      color: ${(props) => colorPalette(props).default} !important;
    }
  `;

  const disabledContainedButtonCss = css`
    background-color: ${(props) => colorPalette(props).disabled} !important;
    color: rgb(255, 255, 255) !important;
  `;

  const disabledOutlinedButtonCss = css`
    background-color: transparent !important;
    border-color: ${(props) => colorPalette(props).disabled} !important;
    color: ${(props) => colorPalette(props).disabled} !important;
  `;

  const disabledTextButtonCss = css`
    background-color: transparent !important;
    color: ${(props) => colorPalette(props).disabledTextColor} !important;
  `;

  const buttonVariants = {
    contained: containedButtonCss,
    outlined: outlinedButtonCss,
    text: textButtonCss,
  };

  const disabledButtonVariants = {
    contained: disabledContainedButtonCss,
    outlined: disabledOutlinedButtonCss,
    text: disabledTextButtonCss,
  };

  const getEnabledButtonCss = (props: StyledBaseButtonProps) =>
    props.disabled
      ? undefined
      : buttonVariants[props.$variant || defaultVariant];

  const getDisabledButtonCss = (props: StyledBaseButtonProps) =>
    props.disabled
      ? disabledButtonVariants[props.$variant || defaultVariant]
      : undefined;

  return [getEnabledButtonCss, getDisabledButtonCss] as const;
};

export { getVariantCss };
