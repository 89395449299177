import {
  Hub,
  BrowserClient,
  Integrations,
  defaultStackParser,
  makeFetchTransport,
  makeXHRTransport,
} from '@sentry/browser';
import { BrowserClientOptions } from '@sentry/browser/types/client';
import { supportsFetch } from '@sentry/utils';
import { SDK_NAME } from '../configuration';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageJson: { version: string } = require('../../package.json');

const integrations = [
  new Integrations.InboundFilters(),
  new Integrations.FunctionToString(),
  new Integrations.TryCatch(),
  new Integrations.Breadcrumbs(),
  new Integrations.LinkedErrors(),
  new Integrations.Dedupe(),
  new Integrations.HttpContext(),
];

export const Sentry = new Hub();

export const initSentry = async (dsn: string) => {
  const options: BrowserClientOptions = {
    dsn,
    normalizeDepth: 6,
    release: `${SDK_NAME}@${packageJson.version}`,
    integrations,
    stackParser: defaultStackParser,
    transport: supportsFetch() ? makeFetchTransport : makeXHRTransport,
  };

  const nodeClient = new BrowserClient(options);
  Sentry.bindClient(nodeClient);
};
