import MuiIconButton from "@mui/material/IconButton";
import styled, { css } from "styled-components";

import { toRem } from "@/utils/styled-components";

export const IconButton = styled(MuiIconButton)<{ $isDisabled?: boolean }>`
  border-radius: ${toRem(4)} !important;
  ${(props) =>
    props.$isDisabled
      ? ``
      : css`
          :hover {
            background-color: ${props.theme.palette.primary.light} !important;
            color: ${props.theme.palette.text.primary} !important;
          }
        `}
`;
